import * as Sentry from '@sentry/react';

// how many rows should we display before cutting off with an Expand button
// sync this with the CSS
const rowThreshold = 2;

export function isExpandable(value: string): boolean {
	return value.split('\n').length > rowThreshold;
}

export function formatValue(val: unknown): string {
	if (val === undefined) {
		return 'null';
	}

	if (typeof val === 'string' || typeof val === 'number' || typeof val === 'boolean') {
		return String(val);
	}

	try {
		return JSON.stringify(val, null, 2);
	} catch (err) {
		Sentry.captureException({ err, reason: 'formatting audit log diff value failed' });
		return 'error presenting value';
	}
}
