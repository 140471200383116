import { PauseCircleMajor } from '@sixriver/lighthouse-icons';
import { Banner, LegacyStack, Spinner, Text } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface OrderManuallyPausedProps {
	isManuallyPaused: boolean;
	onUnpause: () => void;
	pausing: boolean;
}

export function OrderManuallyPaused({
	isManuallyPaused,
	onUnpause,
	pausing,
}: OrderManuallyPausedProps) {
	const { messages } = useLocalization();
	if (!isManuallyPaused && pausing) {
		return (
			<Banner title={messages.manualPause.pausingBanner.title}>
				<LegacyStack spacing="loose">
					<Spinner size="small" />
					<Text as="span">{messages.manualPause.pausingBanner.description}</Text>
				</LegacyStack>
			</Banner>
		);
	}

	if (!isManuallyPaused) {
		return null;
	}

	return (
		<Banner
			status="critical"
			icon={PauseCircleMajor}
			title={messages.manualPause.pausedBanner.title}
			action={{
				content: messages.manualPause.pausedBanner.action,
				loading: pausing,
				onAction: onUnpause,
			}}
		>
			{messages.manualPause.pausedBanner.description}
		</Banner>
	);
}
