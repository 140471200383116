import { LegacyCard, EmptyState } from '@sixriver/lighthouse-web-community';

import { ErrorDetails } from './ErrorDetails';
import { useLocalization } from '../../hooks/useLocalization';
import offlineImage from '../../images/offline.svg';

export function Offline() {
	const { messages } = useLocalization();

	return (
		<>
			<LegacyCard>
				<EmptyState
					heading={messages.connectionInterrupted}
					action={{
						content: messages.reloadPage,
						onAction: () => {
							window.location.reload();
						},
					}}
					image={offlineImage}
				>
					{messages.checkNetwork}
				</EmptyState>
			</LegacyCard>
			<ErrorDetails />
		</>
	);
}
