import './assignLicense.css';

import { Button, Form, Modal, LegacyStack, Text } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { MfaSelector } from './MfaSelector';
import { ItemStatus, Progress } from './Progress';
import { ResourceSelector } from './ResourceSelector';
import { Summary } from './Summary';
import { useLocalization } from '../../../hooks/useLocalization';
import { useMfas } from '../providers/Mfas';
import { useReservations } from '../providers/Reservations';
import { useResources } from '../providers/Resources';

interface AssignLicenseModalProps {
	open: boolean;
	onDismiss: () => void;
	resourceId?: string;
	mfaId?: string;
}

export function AssignLicenseModal({
	open,
	onDismiss,
	resourceId: initialResourceId = '',
	mfaId: initialMfaId = '',
}: AssignLicenseModalProps) {
	const [selectedResourceId, setSelectedResourceId] = useState(initialResourceId);
	const [selectedMfaId, setSelectedMfaId] = useState(initialMfaId);

	const [deleteOldReservationStatus, setDeleteOldReservationStatus] =
		useState<ItemStatus>('ignore');
	const [freeNewMfaStatus, setFreeNewMfaStatus] = useState<ItemStatus>('ignore');
	const [createReservationStatus, setCreateReservationStatus] =
		useState<Exclude<ItemStatus, 'ignore'>>('unstarted');

	const { expireReservation, createReservation, reservations, fetchActiveReservations } =
		useReservations();
	const { resources } = useResources();
	const { mfas } = useMfas();
	const { messages } = useLocalization();

	/** True if any api actions are in progress */
	const loading = useMemo(() => {
		return [deleteOldReservationStatus, freeNewMfaStatus, createReservationStatus].some(
			(s) => s === 'started',
		);
	}, [deleteOldReservationStatus, freeNewMfaStatus, createReservationStatus]);

	const canSubmit = selectedResourceId && selectedMfaId;

	const selectedMfa = mfas?.find((m) => m.deviceId === selectedMfaId);

	// Reservations for those entities, if any
	const reservationForMfa = reservations?.find((res) => res.deviceId === selectedMfaId);
	const reservationForResource = reservations?.find(
		(res) => res.resource.id === selectedResourceId,
	);

	const resetState = useCallback(() => {
		setDeleteOldReservationStatus('ignore');
		setFreeNewMfaStatus('ignore');
		setCreateReservationStatus('unstarted');
		setSelectedMfaId('');
		setSelectedResourceId('');
	}, []);

	const assignLicense = useCallback(async () => {
		// If a reservation exists for the current mfa, move it to 'expired'
		if (reservationForResource) {
			setDeleteOldReservationStatus('started');
			await expireReservation(reservationForResource);
			setDeleteOldReservationStatus('completed');
		}

		// If a reservation exists for the selected mfa, move it to 'expired'
		if (reservationForMfa) {
			setFreeNewMfaStatus('started');
			await expireReservation(reservationForMfa);
			setFreeNewMfaStatus('completed');
		}

		setCreateReservationStatus('started');
		await createReservation(selectedMfaId, selectedResourceId);
		setCreateReservationStatus('completed');

		// Do not wait for fetchActiveReservations to complete, let the data fetch in the background
		fetchActiveReservations();
		resetState();
		onDismiss();
	}, [
		createReservation,
		expireReservation,
		fetchActiveReservations,
		onDismiss,
		reservationForMfa,
		reservationForResource,
		resetState,
		selectedMfaId,
		selectedResourceId,
	]);

	useEffect(() => {
		if (reservationForMfa && freeNewMfaStatus === 'ignore') {
			setFreeNewMfaStatus('unstarted');
		} else if (!reservationForMfa && freeNewMfaStatus !== 'ignore') {
			setFreeNewMfaStatus('ignore');
		}
	}, [freeNewMfaStatus, reservationForMfa]);

	useEffect(() => {
		if (reservationForResource && deleteOldReservationStatus === 'ignore') {
			setDeleteOldReservationStatus('unstarted');
		} else if (!reservationForResource && deleteOldReservationStatus !== 'ignore') {
			setDeleteOldReservationStatus('ignore');
		}
	}, [deleteOldReservationStatus, reservationForResource]);

	if (!resources || !mfas || !reservations) {
		return null;
	}

	return (
		<Modal open={open} onClose={onDismiss} title={messages.mfas.assignLicenseModal.title}>
			<Form onSubmit={assignLicense}>
				<Modal.Section>
					<Text as="p" variant="bodyMd">
						{messages.mfas.assignLicenseModal.description}
					</Text>
				</Modal.Section>
				<Modal.Section>
					<LegacyStack vertical>
						{!initialResourceId && (
							<ResourceSelector resource={selectedResourceId} setResource={setSelectedResourceId} />
						)}
						{!initialMfaId && <MfaSelector mfa={selectedMfa} setMfa={setSelectedMfaId} />}
					</LegacyStack>
				</Modal.Section>
				<Modal.Section>
					<LegacyStack vertical>
						{canSubmit && <Summary resourceId={selectedResourceId} mfaId={selectedMfaId} />}
						<Button submit disabled={!canSubmit || loading} primary loading={loading}>
							{messages.mfas.assignLicenseModal.submit}
						</Button>
						<Progress
							deleteOldReservation={deleteOldReservationStatus}
							freeNewMfa={freeNewMfaStatus}
							createReservation={createReservationStatus}
						/>
					</LegacyStack>
				</Modal.Section>
			</Form>
		</Modal>
	);
}
