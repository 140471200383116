import { AlertMinor } from '@sixriver/lighthouse-icons';
import {
	LegacyCard,
	Button,
	Link,
	Icon,
	Tooltip,
	LegacyStack,
} from '@sixriver/lighthouse-web-community';

import * as routes from '../../routes';
import { GetShuttleAvailableContainerQuery } from './graphql/GetShuttleAvailableContainer.w-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { getMfpUrl } from '../../helpers/mfp';
import { useLocalization } from '../../hooks/useLocalization';
import { StorageAddressLink } from '../ShuttleJobs/ShuttleJobsTable';

export function Details({ shuttleAvailableContainer }: GetShuttleAvailableContainerQuery) {
	const { messages } = useLocalization();

	const device = shuttleAvailableContainer?.currentActor?.device;

	const deviceInfo: { deviceId: string; deviceType: string } | undefined =
		device?.type === 'Mfp'
			? {
					deviceId: device.id,
					deviceType: 'mfp',
			  }
			: undefined;

	const uniqueChuck = deviceInfo?.deviceId;
	const address = shuttleAvailableContainer?.inputs.storageLocationAddress;
	const locationId = shuttleAvailableContainer?.inputs.storageLocationId;

	return (
		<LegacyCard title={messages.details}>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: <StorageAddressLink address={address} locationId={locationId} />,
							label: messages.sourceLocation,
						},
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={shuttleAvailableContainer?.createdAt} />
									<RelativeDateTime date={shuttleAvailableContainer?.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: <DateTime date={shuttleAvailableContainer?.lastEventPersistedAt} />,
							label: messages.updatedAt,
						},
						{
							content: <DateTime date={shuttleAvailableContainer?.endedAt} />,
							label: messages.completedAt,
						},
					]}
				/>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: uniqueChuck || <NoData />,
							label: messages.chuck,
						},
					]}
				/>
				<br />
				{/* TODO: account for finding multiple devices */}
				<Button
					plain
					url={getMfpUrl(uniqueChuck && uniqueChuck[0])}
					disabled={!uniqueChuck?.length}
				>
					{messages.findThisChuck}
				</Button>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: shuttleAvailableContainer?.id ? (
								<CopyButton text={shuttleAvailableContainer.id} />
							) : null,
							label: messages.uniqueJobId,
						},
					]}
				/>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							// TODO: add badge for pickup status?
							content: shuttleAvailableContainer?.outputs.activeTask ? (
								<LegacyStack spacing="extraTight" wrap={false}>
									<Link url={routes.shuttleJob(shuttleAvailableContainer.outputs.activeTask)}>
										{shuttleAvailableContainer.outputs.activeTask}
									</Link>
									{shuttleAvailableContainer?.outputs.hasUnresolvedExceptions ? (
										<Tooltip content={messages.shuttle.hasExceptions} dismissOnMouseOut>
											<Icon source={AlertMinor} color="warning" />
										</Tooltip>
									) : null}
								</LegacyStack>
							) : (
								<NoData />
							),
							label: messages.shuttleJob,
						},
						// TODO: find paired destination and link to that?
					]}
				/>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
