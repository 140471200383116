import { Badge, LegacyStack } from '@sixriver/lighthouse-web-community';

import { PickWaveFieldsFragment } from './graphql/PickWaveFields.f-api-graphql';
import { PickWaveStatus } from '../../api/fulfillment-api/types';

interface PickWaveStatusBadgeProps {
	pickWave: PickWaveFieldsFragment;
}

export function PickWaveStatusBadge({ pickWave }: PickWaveStatusBadgeProps) {
	let badge = <Badge>Unknown</Badge>;

	if (pickWave.status === PickWaveStatus.Failure) {
		if (pickWave.resolvedAt) {
			badge = (
				<LegacyStack spacing="extraTight" wrap={false}>
					<Badge>Failure</Badge>
					<Badge status="success">Resolved</Badge>
				</LegacyStack>
			);
		} else {
			badge = <Badge status="critical">Failure</Badge>;
		}
	}

	if (pickWave.status === PickWaveStatus.PartialSuccess) {
		if (pickWave.resolvedAt) {
			badge = (
				<LegacyStack spacing="extraTight" wrap={false}>
					<Badge>Partial Success</Badge>
					<Badge status="success">Resolved</Badge>
				</LegacyStack>
			);
		} else {
			badge = <Badge status="warning">Partial Success</Badge>;
		}
	}

	if (pickWave.status === PickWaveStatus.Success) {
		badge = <Badge status="success">Success</Badge>;
	}

	return badge;
}
