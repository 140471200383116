import { ComplexAction, Page } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';
import { CombinedError } from 'urql';

import { EmptyError } from './EmptyError';
import styles from './ErrorPage.module.css';
import { GraphQLErrors } from './GraphQLErrors';
import { NetworkError } from './NetworkError';
import { Offline } from './Offline';
import { UnknownCombinedError } from './UnknownCombinedError';

interface ErrorPageProps {
	combinedError?: CombinedError;
	action?: ComplexAction;
}

export function ErrorPage({ combinedError, action }: ErrorPageProps) {
	const message = useMemo(() => {
		if (!navigator.onLine) {
			return <Offline />;
		}

		if (combinedError) {
			if (combinedError.networkError) {
				return <NetworkError error={combinedError.networkError} />;
			}

			if (combinedError.graphQLErrors) {
				return <GraphQLErrors graphQLErrors={combinedError.graphQLErrors} action={action} />;
			}

			return <UnknownCombinedError combinedError={combinedError} action={action} />;
		}

		return <EmptyError action={action} />;
	}, [action, combinedError]);

	return (
		<div className={styles.container}>
			<Page>
				<div data-testid="error-page" />
				{message}
			</Page>
		</div>
	);
}
