import { NotFoundPage } from '../../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../../hooks/useLocalization';

interface Mfa404Props {
	id: string;
}

export function Mfa404({ id }: Mfa404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.mfa404.title}
			message={translate(messages.mfa404.message, { id })}
			action={{
				content: messages.mfa404.action,
				url: '/mfas',
			}}
		/>
	);
}
