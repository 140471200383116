import { ServiceConfig } from '@sixriver/config-schema';

export type JobFlowRuleFilter = {
	key: string;
	label: string;
	type: 'string' | 'integer' | 'float' | 'date' | 'datetime' | 'dropdown';
	values: (string | boolean | number)[];
};

export function getJobFlowRuleFilters(serviceConfig: ServiceConfig | null): JobFlowRuleFilter[] {
	const jobFlowRuleFilters = serviceConfig?.jobAllocation?.jobFlowRuleFilters ?? [];
	return jobFlowRuleFilters.map((filter) => {
		return {
			key: filter.key,
			label: filter.label,
			type: filter.type,
			values: filter.values ?? [],
		};
	});
}
