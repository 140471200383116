import {
	LegacyCard,
	Checkbox,
	ContextualSaveBar,
	Form,
	Layout,
	Select,
	LegacyStack,
	TextField,
	Loading,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useMemo } from 'react';

import { useEmployeeTags } from './Employees.hooks';
import { AutocompleteTags } from '../../components/AutocompleteTags';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { FormFeedback } from '../../components/FormFeedback';
import { FormProps, useForm } from '../../hooks/useForm';
import { LocaleType, SUPPORTED_LOCALES, useLocalization } from '../../hooks/useLocalization';
import { uuidv4 } from '../../utils';

type Props = FormProps<EmployeeFormData>;

export interface EmployeeFormData {
	name: string;
	email: string;
	phoneNumber?: string;
	badgeBarcode: string;
	tags: string[];
	locale: LocaleType;
	isDeviceAccessAllowed: boolean;
	isBridgeAccessAllowed: boolean;
}

export function EmployeeForm<T extends Props>({
	data,
	error: formError,
	onSubmit,
}: T): JSX.Element {
	const { messages } = useLocalization();

	// Form
	const defaultValues: EmployeeFormData = useMemo(
		() => ({
			badgeBarcode: data?.badgeBarcode || '',
			email: data?.email || '',
			isBridgeAccessAllowed: data?.isBridgeAccessAllowed || false,
			isDeviceAccessAllowed: data?.isDeviceAccessAllowed || false,
			locale: data?.locale || 'en-US',
			name: data?.name || '',
			phoneNumber: data?.phoneNumber || '',
			tags: data?.tags || [],
		}),
		[data],
	);

	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<EmployeeFormData>(
		defaultValues,
		formError,
	);

	// Employee Tags
	const {
		addNewTagFetching,
		addNewTagMutationError,
		onNewTag,
		selectedTags,
		setSelectedTags,
		allTagOptions,
		fetchingTags,
		popularTags,
		tagsError,
	} = useEmployeeTags({ defaultSelectedTags: defaultValues.tags });

	// Methods
	const discardEdits = useCallback(() => {
		setSelectedTags([]);
		discardForm();
	}, [discardForm, setSelectedTags]);

	const submitForm = useCallback(() => {
		onSubmit(input);
	}, [input, onSubmit]);

	const generateBadgeBarcode = () => {
		const badgeBarcode = uuidv4();
		editForm({ badgeBarcode });
	};

	// Effects
	useEffect(
		// sync tags with form values
		() => {
			if (input.tags.length !== selectedTags.length) {
				editForm({ tags: selectedTags });
			}
		},
		[editForm, selectedTags, input],
	);

	// Render
	if (fetchingTags) {
		return <Loading />;
	}

	if (addNewTagMutationError || tagsError) {
		return <ErrorPage combinedError={addNewTagMutationError || tagsError} />;
	}

	return (
		<Form onSubmit={submitForm} noValidate preventDefault>
			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						disabled: false,
						loading: false,
						onAction: submitForm,
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardEdits,
					}}
				/>
			) : null}

			<FormFeedback feedback={feedback} />

			<Layout>
				<div style={{ paddingBottom: 100 }}>
					<Layout.Section>
						{/* Employee Details */}
						<Layout.AnnotatedSection title={messages.employeeDetails}>
							<LegacyCard sectioned>
								<div style={{ paddingBottom: 15, paddingTop: 15 }}>
									<LegacyCard.Section fullWidth flush>
										<LegacyStack vertical>
											<TextField
												autoComplete="off"
												name="name"
												label={messages.fullName}
												error={validations.name}
												value={input.name}
												onChange={(name) => editForm({ name })}
											/>
											<Select
												label={messages.language}
												options={SUPPORTED_LOCALES.map((locale) => ({
													label: messages.supportedLocales[locale] || locale,
													value: locale,
												}))}
												value={input.locale}
												onChange={(locale: LocaleType) => editForm({ locale })}
											/>
										</LegacyStack>
									</LegacyCard.Section>
								</div>
							</LegacyCard>
						</Layout.AnnotatedSection>

						{/* Device Access */}
						<Layout.AnnotatedSection
							title={messages.deviceAccess}
							description={messages.deviceAccessDescription}
						>
							<LegacyCard
								sectioned
								title={
									<>
										<Checkbox
											label={messages.allowAccessToDevices}
											name="allowDeviceAccess"
											checked={input.isDeviceAccessAllowed}
											onChange={(isChecked) => {
												editForm({
													badgeBarcode: isChecked ? input.badgeBarcode : '',
													isDeviceAccessAllowed: isChecked,
												});
											}}
										/>
									</>
								}
							>
								<LegacyCard.Section fullWidth>
									<TextField
										autoComplete="off"
										name="badge"
										labelAction={
											input.isDeviceAccessAllowed
												? {
														content: messages.generateBarcode,
														onAction: generateBadgeBarcode,
												  }
												: undefined
										}
										disabled={!input.isDeviceAccessAllowed}
										label={messages.badgeBarcode}
										error={validations.badgeBarcode}
										value={input.badgeBarcode}
										onChange={(badge) => editForm({ badgeBarcode: badge })}
									/>
								</LegacyCard.Section>
							</LegacyCard>
						</Layout.AnnotatedSection>

						{/* Bridge Access */}
						<Layout.AnnotatedSection
							title={messages.bridgeAccess}
							description={messages.bridgeAccessDescription}
						>
							<LegacyCard
								sectioned
								title={
									<Checkbox
										label={messages.allowAccessToTheBridge}
										name="allowBridgeAccess"
										checked={input.isBridgeAccessAllowed}
										onChange={(isChecked) => {
											editForm({
												isBridgeAccessAllowed: isChecked,
											});
										}}
									/>
								}
							>
								<LegacyCard.Section fullWidth>
									<LegacyStack vertical spacing="loose">
										<TextField
											name="email"
											disabled={!input.isBridgeAccessAllowed}
											label={messages.email}
											autoComplete="off"
											ariaAutocomplete="off"
											error={validations.email}
											value={input.email}
											onChange={(email) => editForm({ email })}
										/>
									</LegacyStack>
								</LegacyCard.Section>
							</LegacyCard>
						</Layout.AnnotatedSection>

						{/* Tags */}
						<Layout.AnnotatedSection
							title={messages.tags}
							description={messages.employeeTagsDescription}
						>
							<LegacyCard sectioned>
								<div style={{ paddingBottom: 15, paddingTop: 15 }}>
									<LegacyCard.Section flush fullWidth>
										<AutocompleteTags
											allowNew
											onNewTag={onNewTag}
											availableOptions={allTagOptions}
											popularTags={popularTags}
											selectedOptions={selectedTags}
											onSelectedOptionsChange={setSelectedTags}
											isLoading={addNewTagFetching}
											selectedTagsPlacement="above"
											placeholder={messages.createNewTag}
										/>
									</LegacyCard.Section>
								</div>
							</LegacyCard>
						</Layout.AnnotatedSection>
					</Layout.Section>
				</div>
			</Layout>
		</Form>
	);
}
