import { Button, LegacyStack, TextField } from '@sixriver/lighthouse-web-community';
import { ShuttlePoint } from '@sixriver/task-coordinator-oas';

import styles from './Shuttle.module.css';
import { Toggle } from '../../../components/Toggle';
import { useLocalization } from '../../../hooks/useLocalization';

interface Props {
	shuttlePoint: ShuttlePoint;
	onDelete: () => void;
	onChange: (shuttlePoint: ShuttlePoint) => void;
	workflowEligible: boolean;
}

export function ShuttlePointOptions({ shuttlePoint, onDelete, onChange, workflowEligible }: Props) {
	const { messages } = useLocalization();

	const setShuttlePointProp = <T extends keyof ShuttlePoint>(prop: T, value: ShuttlePoint[T]) => {
		onChange({ ...shuttlePoint, [prop]: value });
	};

	return (
		<div>
			<div className={styles.cardInner}>
				<LegacyStack alignment="fill" vertical>
					<Toggle
						checked={shuttlePoint.enabled}
						label={
							shuttlePoint.enabled ? messages.shuttlePointEnabled : messages.shuttlePointDisabled
						}
						onCheck={(value: boolean) => setShuttlePointProp('enabled', value)}
					/>

					<Toggle
						disabled={!shuttlePoint.enabled}
						checked={shuttlePoint.reserve}
						label={
							shuttlePoint.reserve
								? messages.shuttlePointReserveEnabled
								: messages.shuttlePointReserveDisabled
						}
						onCheck={(value: boolean) => setShuttlePointProp('reserve', value)}
					/>

					<Toggle
						disabled={!shuttlePoint.enabled}
						checked={shuttlePoint.skipToDestination}
						label={
							shuttlePoint.skipToDestination
								? messages.shuttlePointSkipToEnabled
								: messages.shuttlePointSkipToDisabled
						}
						onCheck={(value: boolean) => setShuttlePointProp('skipToDestination', value)}
					/>

					<Toggle
						disabled={!shuttlePoint.enabled}
						checked={shuttlePoint.confirm}
						label={
							shuttlePoint.confirm ? messages.shuttlePointConfirm : messages.shuttlePointNoConfirm
						}
						onCheck={(value: boolean) => setShuttlePointProp('confirm', value)}
					/>

					{workflowEligible && (
						<Toggle
							disabled={!shuttlePoint.enabled}
							checked={shuttlePoint.doWorkflow}
							label={
								shuttlePoint.doWorkflow
									? messages.shuttlePointWorkflowEnabled
									: messages.shuttlePointWorkflowDisabled
							}
							onCheck={(value: boolean) => setShuttlePointProp('doWorkflow', value)}
						/>
					)}

					<TextField
						autoComplete="off"
						disabled={!shuttlePoint.enabled || shuttlePoint.confirm}
						label={messages.shuttlePointDelay}
						value={shuttlePoint.delay?.toString() || '0'}
						type="number"
						maxLength={3}
						min={0}
						onChange={(value: string) => setShuttlePointProp('delay', parseInt(value) || 0)}
					/>

					<TextField
						autoComplete="off"
						disabled={!shuttlePoint.enabled}
						label={messages.shuttlePointInstructions}
						value={shuttlePoint.instructions}
						maxLength={100}
						onChange={(value: string) => setShuttlePointProp('instructions', value)}
					/>

					<div className={styles.fakeFooterButton}>
						<Button plain onClick={onDelete} destructive>
							{messages.delete}
						</Button>
					</div>
				</LegacyStack>
			</div>
		</div>
	);
}
