import { MarkFulfilledMinor } from '@sixriver/lighthouse-icons';
import { Layout, MenuActionDescriptor, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Details } from './Details';
import { Exception404 } from './Exception404';
import {
	GetExceptionOrderDocument,
	GetExceptionOrderQuery,
	GetExceptionOrderQueryVariables,
} from './graphql/GetExceptionOrder.w-api-graphql';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { CancelOrderModal } from '../../components/CancelOrderModal';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { Lines } from '../../components/Lines';
import { LinesTableColumn } from '../../components/Lines/LinesTable';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useCancelOrder } from '../../hooks/useCancelOrder';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useApplyManualPickHealPlan } from '../../hooks/useHealPlan';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function Exception() {
	const { exceptionId = '' } = useParams<{
		exceptionId: string;
	}>();
	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.exceptions,
		url: routes.exceptions(),
	};
	const [getExceptionOrderQuery] = usePollingQuery<
		GetExceptionOrderQuery,
		GetExceptionOrderQueryVariables
	>({
		query: GetExceptionOrderDocument,
		variables: {
			id: exceptionId,
		},
	});
	const { job: order } = getExceptionOrderQuery.data || {};

	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({ orderId: exceptionId });
	const actions: MenuActionDescriptor[] = [
		{
			content: messages.cancel,
			// LOL-3316, just enable it all the time and hope
			// disabled: !order?.isCancelable,
			onAction: openCancelModal,
		},
	];

	const { fetching: applyManualHealPlanFetching, applyManualHealPlan } =
		useApplyManualPickHealPlan();
	if ((order as any)?.isHealable) {
		actions.push({
			content: messages.manuallyResolved,
			icon: MarkFulfilledMinor,
			loading: applyManualHealPlanFetching,
			onAction: () => {
				if (order) {
					applyManualHealPlan(order.id);
				}
			},
		});
	}

	if (getExceptionOrderQuery.fetching) {
		return <LoadingPage title={exceptionId} backAction={backAction} />;
	}

	if (!order) {
		if (hasMissingEntityError(getExceptionOrderQuery.error)) {
			return <Exception404 id={exceptionId} />;
		}

		return (
			<ErrorPage
				combinedError={getExceptionOrderQuery.error}
				action={{
					content: messages.exception404.action,
					url: routes.exceptions(),
				}}
			/>
		);
	}

	return (
		<AutoRefreshPage
			queries={[getExceptionOrderQuery]}
			title={exceptionId}
			backAction={backAction}
			secondaryActions={actions}
		>
			<Layout>
				<Layout.Section>
					<Details isWorkAreaEnabled={isWorkAreaEnabled} order={order} />
				</Layout.Section>
				<Layout.Section>
					<Lines
						job={order}
						columns={[
							LinesTableColumn.id,
							LinesTableColumn.name,
							LinesTableColumn.pickingUnits,
							LinesTableColumn.exceptions,
							LinesTableColumn.reason,
							LinesTableColumn.location,
						]}
					/>
				</Layout.Section>
			</Layout>

			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
		</AutoRefreshPage>
	);
}
