import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Product404Props {
	id: string;
}

export function Product404({ id }: Product404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.product404.title}
			message={translate(messages.product404.message, { id })}
			action={{
				content: messages.product404.action,
				url: routes.products(),
			}}
		/>
	);
}
