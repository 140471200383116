import { DescriptionList, DescriptionListProps } from '@sixriver/lighthouse-web-community';
import { CombinedError } from 'urql';

import styles from './ErrorPage.module.css';
import { useLocalization } from '../../hooks/useLocalization';

interface ErrorDetailsProps {
	error?: Error;
	sentryId?: string;
	graphQLErrors?: CombinedError['graphQLErrors'];
}

// Everything here is intentionally English for 6RS staff to debug, with the
// exception of the `messages.errorRecorded` string which is localized.
export function ErrorDetails({ error, sentryId, graphQLErrors }: ErrorDetailsProps) {
	const items: DescriptionListProps['items'] = [];

	const { messages } = useLocalization();

	if (sentryId) {
		const description = (
			<>
				{sentryId}
				<br />
				{messages.errorRecorded}
			</>
		);
		items.push({
			description,
			term: 'Sentry ID',
		});
	} else {
		items.push({
			description: 'No sentry event captured',
			term: 'Sentry ID',
		});
	}

	if (error) {
		items.push({
			description: error.message ?? 'No additional message available ',
			term: 'Error message',
		});
	}

	if (graphQLErrors) {
		for (const graphQLError of graphQLErrors) {
			items.push({
				description: graphQLError.message,
				term: 'GraphQL error',
			});
		}
	}

	return (
		<div className={styles.errorDetails}>
			<DescriptionList items={items} />
		</div>
	);
}
