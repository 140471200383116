import { OutgoingMajor } from '@sixriver/lighthouse-icons';
import { LegacyCard } from '@sixriver/lighthouse-web-community';
import { CardDetails, DateTime } from '@sixriver/react-support';
import { useState } from 'react';

import { RemoveMfaLicenseModal } from './RemoveMfaLicense';
import { useLocalization } from '../../../hooks/useLocalization';
import { AssignLicenseModal } from '../AssignLicense/AssignLicense';
import { Reservation } from '../providers/Reservations';

interface LicenseDetailProps {
	reservation: Reservation;
}

export function LicenseDetail({ reservation }: LicenseDetailProps) {
	const { messages } = useLocalization();

	const [removeModalOpen, setRemoveModalOpen] = useState(false);
	const [assignLicenseOpen, setAssignLicenseOpen] = useState(false);

	return (
		<>
			<RemoveMfaLicenseModal
				open={removeModalOpen}
				reservation={reservation}
				onDismiss={() => setRemoveModalOpen(false)}
			/>
			<AssignLicenseModal
				open={assignLicenseOpen}
				resourceId={reservation.resource.id}
				onDismiss={() => setAssignLicenseOpen(false)}
			/>
			<LegacyCard
				title={messages.mfa.license.title}
				primaryFooterAction={{
					content: messages.mfa.license.remove.action,
					destructive: true,
					onAction: () => setRemoveModalOpen(true),
					outline: true,
				}}
				secondaryFooterActions={[
					{
						content: messages.mfa.license.reassign,
						icon: OutgoingMajor,
						onAction: () => setAssignLicenseOpen(true),
						plain: true,
					},
				]}
			>
				<LegacyCard.Section>
					<CardDetails
						primary={[
							{
								content: reservation.resource.name,
								label: messages.mfa.license.list.name,
							},
							{
								content: (
									<DateTime
										key={`reservedUntil-${reservation.id}`}
										date={new Date(reservation.validUntil * 1000)}
									/>
								),
								label: messages.mfa.license.list.expiration,
							},
						]}
					/>
				</LegacyCard.Section>
			</LegacyCard>
		</>
	);
}
