import { NavigateFunction, Location } from 'react-router-dom';

import * as routes from '../../routes';

export const redirectUserAfterLogin = (navigate: NavigateFunction, location: Location) => {
	const params = new URLSearchParams(location.search);
	const from = location.state?.from ?? { pathname: '/' };

	const originalUri = params.get('original-uri');

	if (from.pathname?.includes(routes.logout())) {
		navigate('/');
	} else if (originalUri) {
		const redirectUrl = new URL(originalUri);

		// Ensure the redirect URL is within the same origin. If so, redirect to
		// the URL. Otherwise, ignore it. Make an exception for localhost in dev
		const skipOriginCheck =
			process.env.NODE_ENV === 'development' && redirectUrl.hostname === 'localhost';
		if (skipOriginCheck || redirectUrl.origin === window.location.origin) {
			window.location.href = redirectUrl.href;
		} else {
			navigate('/');
		}
	} else {
		navigate(from);
	}
};
