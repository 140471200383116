import { Badge } from '@sixriver/lighthouse-web-community';

import { GoalState } from '../../../api/warehouse-api/types';
import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';

const statusToBadgeStatus: Record<GoalState, 'info' | 'critical' | 'warning' | undefined> = {
	[GoalState.CancelRequested]: 'critical',
	[GoalState.Cancelled]: 'critical',
	[GoalState.Cancelling]: 'critical',
	[GoalState.Complete]: undefined,
	[GoalState.Created]: 'warning',
	[GoalState.Creating]: 'warning',
	[GoalState.Failed]: 'critical',
	[GoalState.Hold]: undefined,
	[GoalState.Paused]: 'warning',
	[GoalState.Ready]: 'warning',
	[GoalState.Running]: 'info',
	[GoalState.TerminateRequested]: 'critical',
	[GoalState.Terminated]: 'critical',
	[GoalState.Terminating]: 'critical',
};

export function PutawayJobStatus({ status }: { status?: GoalState }) {
	const { messages } = useLocalization();

	const labels: Record<GoalState, string> = {
		[GoalState.CancelRequested]: messages.goalStates.cancelRequested,
		[GoalState.Cancelled]: messages.canceled,
		[GoalState.Cancelling]: messages.goalStates.cancelling,
		[GoalState.Complete]: messages.completed,
		[GoalState.Created]: messages.unassigned,
		[GoalState.Creating]: messages.unassigned,
		[GoalState.Failed]: messages.goalStates.failed,
		[GoalState.Hold]: messages.queued,
		[GoalState.Paused]: messages.interrupted,
		[GoalState.Ready]: messages.unassigned,
		[GoalState.Running]: messages.inProgress,
		[GoalState.TerminateRequested]: messages.goalStates.terminateRequested,
		[GoalState.Terminated]: messages.canceled,
		[GoalState.Terminating]: messages.goalStates.terminating,
	};

	return status ? <Badge status={statusToBadgeStatus[status]}>{labels[status]}</Badge> : <NoData />;
}
