import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Order404Props {
	id: string;
}

export function Order404({ id }: Order404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.order404.title}
			message={translate(messages.order404.message, { id })}
			action={{
				content: messages.order404.action,
				url: routes.orders(),
			}}
		/>
	);
}
