import { Page } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContainerForm } from './Container.form';
import { useCreateContainerMutation } from './graphql/CreateContainer.f-api-graphql';
import {
	GetContainerDocument,
	GetContainerQuery,
	GetContainerQueryVariables,
} from './graphql/GetContainer.w-api-graphql';
import { AddContainerInput, ContainerType } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import * as routes from '../../routes';

export function ContainerAdd(): JSX.Element {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const [submitting, setSubmitting] = useState(false);

	const formDefaults: AddContainerInput = {
		// strings
		barcode: '',

		// ints
		columns: 1,
		description: '',

		// bools
		enabled: true,
		externalId: '',
		height: 1,
		imageURL: '',
		instructions: '',
		inventory: false,

		// floats
		length: 1,
		materialType: '',
		name: '',
		rows: 1,

		// enums
		type: ContainerType.Picking,
		weight: 0,
		width: 1,
	};

	// Mutations
	const [createContainerMutation, executeCreateContainerMutation] = useCreateContainerMutation();

	// Routing
	const navigate = useNavigate();
	useWaitForWapiIngestion<GetContainerQuery, GetContainerQueryVariables>({
		enabled: !!createContainerMutation.data?.createContainer?.success,
		onResponse: (updatedData) => {
			const wapiId = updatedData.getContainer?.id;
			if (wapiId) {
				navigate(routes.container(wapiId));
			}
		},
		onTimeout: () => {
			showToast(messages.addContainerWapiIngestionError, true);
		},
		query: GetContainerDocument,
		variables: {
			id: createContainerMutation.data?.createContainer?.referenceId ?? '',
		},
	});

	// Methods
	const onSubmit = useCallback(
		async (input: AddContainerInput) => {
			setSubmitting(true);
			await executeCreateContainerMutation({ input });
		},
		[executeCreateContainerMutation],
	);

	// Render
	return (
		<Page
			title={messages.newContainer}
			backAction={{ content: messages.containers, url: routes.containers() }}
			fullWidth
		>
			<ContainerForm
				mode="add"
				data={formDefaults}
				onSubmit={onSubmit}
				error={createContainerMutation.error}
				submitting={submitting}
			/>
		</Page>
	);
}
