import {
	LegacyCard,
	ContextualSaveBar,
	Form,
	FormLayout,
	Layout,
	Select,
	LegacyStack,
	TextField,
} from '@sixriver/lighthouse-web-community';

import { useGetAislesQuery } from './graphql/GetAisles.f-api-graphql';
import { useGetCapacityTypesQuery } from './graphql/GetCapacityTypes.f-api-graphql';
import { StorageLocationInput, StorageLocationType } from '../../api/fulfillment-api/types';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { FormFeedback } from '../../components/FormFeedback';
import { FormProps, useForm } from '../../hooks/useForm';
import { useLocalization } from '../../hooks/useLocalization';

export function StorageLocationForm({
	data,
	onSubmit,
	submitting,
	error,
}: FormProps<StorageLocationInput>): JSX.Element {
	// Localization
	const { messages } = useLocalization();

	// Queries
	const [getCapacityTypesQuery] = useGetCapacityTypesQuery();
	const [getAislesQuery] = useGetAislesQuery();

	const { dirty, discardForm, editForm, feedback, input, validations } =
		useForm<StorageLocationInput>(data, error);

	const locationTypeOptions = [
		{
			label: messages.locationTypes.bin,
			value: StorageLocationType.Bin,
		},
		{
			label: messages.locationTypes.cart,
			value: StorageLocationType.Cart,
		},
		{
			label: messages.locationTypes.damaged,
			value: StorageLocationType.Damaged,
		},
		{
			label: messages.locationTypes.dock,
			value: StorageLocationType.Dock,
		},
		{
			label: messages.locationTypes.external,
			value: StorageLocationType.External,
		},
		{
			label: messages.locationTypes.pallet,
			value: StorageLocationType.Pallet,
		},
		{
			label: messages.locationTypes.rack,
			value: StorageLocationType.Rack,
		},
		{
			label: messages.locationTypes.reserve,
			value: StorageLocationType.Reserve,
		},
		{
			label: messages.locationTypes.slot,
			value: StorageLocationType.Slot,
		},
		{
			label: messages.locationTypes.staging,
			value: StorageLocationType.Staging,
		},
		{
			label: messages.locationTypes.warehouse,
			value: StorageLocationType.Warehouse,
		},
	];

	const aisleIdOptions = (getAislesQuery.data?.aisles || [])
		.map((aisle) => {
			return {
				label: aisle.externalId,
				value: aisle.externalId,
			};
		})
		.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});

	// add default none option - a string is required, empty string will be
	// converted to null when saving. This empty string mapping is also used in
	// EditStorageLocation.tsx.
	aisleIdOptions.unshift({ label: messages.none, value: '' });

	const capacityTypeOptions = (getCapacityTypesQuery.data?.containers?.results || [])
		.filter((container) => container.type === 'storage')
		.filter((container) => !!container.name && !!container.id)
		.map((container) => {
			return {
				label: container.name || '',
				value: container.id || '',
			};
		})
		.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});

	if (getCapacityTypesQuery.fetching || getAislesQuery.fetching) {
		return <></>;
	}

	if (getCapacityTypesQuery.error || getAislesQuery.error) {
		return <ErrorPage combinedError={getCapacityTypesQuery.error || getAislesQuery.error} />;
	}

	return (
		<>
			{/* Error Banner */}
			<FormFeedback feedback={feedback} />

			{/* Form */}
			<Form onSubmit={() => void onSubmit(input)}>
				<Layout.AnnotatedSection title={messages.locationDetails}>
					<LegacyCard sectioned>
						<LegacyStack vertical>
							<FormLayout>
								<FormLayout.Group condensed>
									<TextField
										autoComplete="off"
										name="address"
										label={messages.address}
										error={validations.address}
										onChange={(address) => editForm({ address })}
										value={input.address}
										requiredIndicator
									/>
									<Select
										name="externalAisleId"
										label={messages.aisleId}
										options={aisleIdOptions}
										error={validations.externalAisleId}
										onChange={(externalAisleId) => editForm({ externalAisleId })}
										// replace `null` with the empty string so it aligns with
										// the visual "None" option setup above.
										value={input.externalAisleId ?? ''}
										requiredIndicator
									/>
								</FormLayout.Group>
							</FormLayout>
							<TextField
								autoComplete="off"
								name="description"
								label={messages.description}
								error={validations.description}
								onChange={(description) => editForm({ description })}
								value={input.description}
								requiredIndicator
							/>
							<Select
								name="type"
								label={messages.locationType}
								options={locationTypeOptions}
								error={validations.type}
								onChange={(type: StorageLocationType) => editForm({ type })}
								value={`${input.type}`}
								requiredIndicator
							/>
							<Select
								name="containerTypeId"
								label={messages.capacityType}
								placeholder={messages.select}
								options={capacityTypeOptions}
								error={validations.containerTypeId}
								onChange={(containerTypeId) => editForm({ containerTypeId })}
								value={input.containerTypeId || undefined}
							/>
						</LegacyStack>
					</LegacyCard>
				</Layout.AnnotatedSection>

				<Layout.AnnotatedSection title={messages.coordinates}>
					<LegacyCard sectioned>
						<FormLayout>
							<FormLayout.Group condensed>
								<TextField
									autoComplete="off"
									name="x"
									label="X"
									type="number"
									inputMode="numeric"
									suffix="mm"
									error={validations.x}
									onChange={(x) => editForm({ x: parseInt(x) || 0 })}
									value={(input.x || 0).toString()}
									requiredIndicator
								/>
								<TextField
									autoComplete="off"
									name="y"
									label="Y"
									type="number"
									inputMode="numeric"
									suffix="mm"
									error={validations.y}
									onChange={(y) => editForm({ y: parseInt(y) || 0 })}
									value={(input.y || 0).toString()}
									requiredIndicator
								/>
								<TextField
									autoComplete="off"
									name="z"
									label="Z"
									type="number"
									inputMode="numeric"
									error={validations.z}
									onChange={(z) => editForm({ z: parseInt(z) || 0 })}
									value={(input.z || 0).toString()}
									requiredIndicator
								/>
							</FormLayout.Group>
						</FormLayout>
					</LegacyCard>
				</Layout.AnnotatedSection>
			</Form>

			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						loading: submitting,
						onAction: () => void onSubmit(input),
					}}
					discardAction={{
						content: messages.discard,
						disabled: submitting,
						onAction: () => {
							discardForm();
						},
					}}
				/>
			) : null}
		</>
	);
}
