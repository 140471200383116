import type { FC } from 'react';
import { createContext, useContext, useState } from 'react';

import { Resource } from './Resource';
import { logger } from '../../../../utils';

interface ResourceContextValue {
	resources: Resource[] | null;
	fetchResources: () => Promise<void>;
}

const ResourceContext = createContext<ResourceContextValue>({
	fetchResources: async () => {
		return;
	},
	resources: [],
});

export const useResources = () => useContext(ResourceContext);

export const ResourceContextProvider: FC = ({ children }) => {
	const [resources, setResources] = useState<Resource[] | null>(null);

	const fetchResources = async () => {
		try {
			const url = `${import.meta.env.VITE_MFP_RESERVE_URL}/api/v2/resources?page=1&limit=200`;
			const response = await fetch(url);

			if (!response.ok) {
				throw new Error(`GET ${url}: ${response.status} ${response.statusText}`);
			}

			setResources((await response.json()).data);
		} catch (err) {
			logger.error({ err }, 'failed fetching mfa resources');
		}
	};

	return (
		<ResourceContext.Provider value={{ fetchResources, resources }}>
			{children}
		</ResourceContext.Provider>
	);
};
