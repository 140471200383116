import { IndexTable, Link } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';
import * as routes from '../../../routes';

interface Props {
	address?: string | null;
	locationId?: string | null;
	url?: string | null;
}

export function StorageAddressCell(props: Props) {
	return (
		<IndexTable.Cell>
			<StorageAddressLink {...props} />
		</IndexTable.Cell>
	);
}

export function StorageAddressLink({ address, locationId, url }: Props) {
	let linkURL;
	if (url) {
		linkURL = url;
	} else if (locationId) {
		linkURL = routes.location(locationId);
	} else if (address) {
		linkURL = routes.locationByAddress(address);
	} else {
		return <NoData />;
	}
	return (
		<Link url={linkURL} key={address}>
			{address}
		</Link>
	);
}
