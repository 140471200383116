import {
	Loading,
	Page,
	PageProps,
	SkeletonDisplayText,
	Spinner,
} from '@sixriver/lighthouse-web-community';

export function LoadingPage({
	// Polaris accepts a React Node here, however this version has it typed as a string for whatever reason.
	// Future versions are typed correctly
	title = (<SkeletonDisplayText size="extraLarge" />) as unknown as string,
	children,
	...pageProps
}: PageProps) {
	return (
		<Page title={title} {...pageProps} secondaryActions={<Spinner size="small" />}>
			<Loading />
			{children}
		</Page>
	);
}
