import { LegacyCard } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTime } from '../../components/DateTime';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { GetJobQueryJob } from '../../graphql/derived';
import { isExceptionActionableV2 } from '../../helpers/exception';
import { useLocalization } from '../../hooks/useLocalization';
import { useShowExceptionPage } from '../../hooks/useShowExceptionPage';
import * as routes from '../../routes';

interface Props {
	loading?: boolean;
	job?: GetJobQueryJob;
	relatedJobs?: GetJobQueryJob['orders'];
	cutoff?: string;
}

const getParams = (orderIds: string[]) => {
	const searchParams = new URLSearchParams();

	orderIds.forEach((id) => {
		searchParams.append('ids', id);
	});

	return '?' + searchParams.toString();
};

export function PackingDetails({ loading, job, cutoff }: Props) {
	const { messages, translate } = useLocalization();
	const showExceptionPage = useShowExceptionPage();

	const orders = job?.orders ?? [];

	const lines = job?.lines.edges ?? [];

	const orderIds = orders.map((order) => order.id);

	const packouts: any = (job as any)?.packouts.edges;
	const packout = packouts.length > 0 ? packouts[0].node : undefined;

	const exceptionCount = lines.filter(({ node }) => node.isShorted).length;

	const exceptionOrders = orders?.filter((order) => order.isShorted) || [];

	const exceptionOrderIds = exceptionOrders.map((order) => order.id);

	return (
		<LegacyCard
			title={messages.packingDetails}
			primaryFooterAction={{
				content: translate(messages.viewOrders, { count: orderIds.length }) as string,
				disabled: orderIds.length === 0,
				url:
					orderIds.length === 1
						? routes.order(orderIds[0])
						: `${routes.orders()}${getParams(orderIds)}`,
			}}
			secondaryFooterActions={
				showExceptionPage
					? [
							{
								content: messages.manageExceptions,
								disabled: exceptionCount === 0 || !isExceptionActionableV2(job),
								url:
									exceptionOrderIds.length === 1
										? routes.exception(exceptionOrderIds[0])
										: `${routes.exceptions()}${getParams(exceptionOrderIds)}`,
							},
					  ]
					: undefined
			}
			footerActionAlignment="left"
		>
			<LegacyCard.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: packout?.mfpId,
							label: messages.packStation,
						},
						{
							content: packout?.userId,
							label: messages.packedBy,
						},
						// {
						// 	content: [...Array.from(new Set(packout?.containerTypes))].join(', '),
						// 	label: messages.packageType,
						// },
						{
							content: (
								<>
									<DateTime date={cutoff} />
									<RelativeDateTime date={cutoff} mode="deadline" />
								</>
							),
							label: messages.earliestCutoff,
						},
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={packout?.createdAt} />
									<RelativeDateTime date={packout?.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: <DateTime date={packout?.updatedAt} />,
							label: messages.updatedAt,
						},
						{
							content: <DateTime date={packout?.startedAt} />,
							label: messages.packingStartedAt,
						},
						{
							content: <DateTime date={packout?.completedAt} />,
							label: messages.packingEndedAt,
						},
					]}
				/>
				{/* <div className={styles.button}>
					<RelatedJobsButton relatedJobs={relatedJobs || []} label={messages.viewRelatedJobs} />
				</div> */}
			</LegacyCard.Section>
			<LegacyCard.Section title={messages.breakdown}>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: translate(messages.countUnits, {
								count: lines.reduce((a, { node: b }) => a + b.quantity, 0),
							}),
							label: messages.totalUnits,
						},
						{
							content: translate(messages.countLines, { count: lines.length }),
							label: messages.totalLines,
						},
					]}
					secondary={[
						{
							content:
								orderIds.length === 0 ? null : orderIds.length === 1 ? (
									<CopyButton text={orders?.[0]?.externalId || '?'} />
								) : (
									messages.multiple
								),
							label: messages.orderId,
						},
						{
							content: translate(messages.countOrders, { count: orderIds.length }),
							label: messages.totalOrders,
						},
					]}
				/>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							content: packout?.id ? <CopyButton text={packout?.id} /> : null,
							label: messages.packoutRequestId,
						},
					]}
				/>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
