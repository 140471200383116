import { GoalState } from '../../api/warehouse-api/types';

export enum ShuttleAvailableContainersViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	Queued = 'Queued',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const ShuttleAvailableContainersViewsViewStateMap: Record<
	ShuttleAvailableContainersViews,
	GoalState[]
> = {
	[ShuttleAvailableContainersViews.All]: [],
	[ShuttleAvailableContainersViews.Unassigned]: [
		GoalState.Creating,
		GoalState.Created,
		GoalState.Ready,
	],
	[ShuttleAvailableContainersViews.Queued]: [GoalState.Hold],
	[ShuttleAvailableContainersViews.InProgress]: [
		GoalState.Running,
		GoalState.TerminateRequested,
		GoalState.Terminating,
		GoalState.CancelRequested,
		GoalState.Cancelling,
	],
	[ShuttleAvailableContainersViews.Completed]: [GoalState.Complete],
	[ShuttleAvailableContainersViews.Cancelled]: [GoalState.Cancelled, GoalState.Terminated],
};
