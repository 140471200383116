import { Modal, Page, PageProps, TextStyle } from '@sixriver/lighthouse-web-community';
import { useAuth, UserRole, useSiteName } from '@sixriver/react-support';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
	DeviceEditFormData,
	DeviceEditFormInfo,
	validateDeviceName,
	renameDevice,
} from './Device.utils';
import { Device404 } from './Device404';
import { DeviceEditForm } from './DeviceEditForm';
import { DeviceState } from '../../api';
import { GlobalErrorType } from '../../api/client';
import { Error as ShowError } from '../../components/Error';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import {
	getDeviceType,
	useAvailableMfp,
	getDeviceState,
	isVirtualDeviceName,
} from '../../hooks/useAvailableMfps';
import { useExperimentalFlags, useMfpConfigForDevice, useSsid } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { useConfigServiceClient } from '../../providers';
import * as routes from '../../routes';
import { logger } from '../../utils';

export function DeviceEdit() {
	const configServiceClient = useConfigServiceClient();
	const experimentalFlags = useExperimentalFlags();
	const { isUserAllowed } = useAuth();

	const navigate = useNavigate();
	// Getting Data
	const params = useParams<{ deviceId: string }>();
	const { messages, translate } = useLocalization();
	const siteName = useSiteName();

	const deviceId = params?.deviceId ?? '';
	const backAction: PageProps['backAction'] = {
		content: messages.device,
		url: routes.device(params?.deviceId),
	};
	const { data: device, error: fetchingDeviceError } = useAvailableMfp(deviceId);

	const { data: mfpConfig, error: fetchingMfpConfigError } = useMfpConfigForDevice(device);
	const ssid = useSsid();

	// Form related
	const { showToast } = useToast();
	const [newName, setNewName] = useState('');
	const [newModelConfig, setNewModelConfig] = useState('');
	const [errors, setErrors] = useState<{ name?: string }>({});
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isExecutingAction, setIsExecutingAction] = useState(false);
	const [isDeviceCannotBeRestartedModalOpen, setIsDeviceCannotBeRestartedModalOpen] =
		useState(false);
	const [isDeviceRenamedModalOpen, setIsDeviceRenamedModalOpen] = useState(false);

	const closeConfirmModal = useCallback(
		() => setIsConfirmModalOpen(false),
		[setIsConfirmModalOpen],
	);
	const closeDeviceCannotBeRestartedModal = useCallback(
		() => setIsDeviceCannotBeRestartedModalOpen(false),
		[setIsDeviceCannotBeRestartedModalOpen],
	);

	const changeModelConfigId = useCallback(
		async (deviceId: string, newModelConfigId: string) => {
			const finalPayload = {
				changes: [`devices.${deviceId}.modelConfigId`],
				config: {
					devices: {
						[deviceId]: {
							modelConfigId: newModelConfigId,
						},
					},
				},
			};

			await configServiceClient.saveConfig(JSON.stringify(finalPayload));
		},
		[configServiceClient],
	);

	const save = useCallback(async () => {
		if (!device || getDeviceState(device) !== DeviceState.NotInUse) {
			closeConfirmModal();
			setIsDeviceCannotBeRestartedModalOpen(true);
			return;
		}
		setIsExecutingAction(true);
		try {
			if (newName !== '') {
				await renameDevice(siteName, mfpConfig!.name, newName);
			}

			if (newModelConfig !== '' && mfpConfig!.modelConfigId !== newModelConfig) {
				await changeModelConfigId(mfpConfig!.name, newModelConfig);
			}

			setIsExecutingAction(false);
			closeConfirmModal();
			setIsDeviceRenamedModalOpen(true);
		} catch (err) {
			logger.error({ err }, 'failed editing device name or model config id');

			setIsExecutingAction(false);
			closeConfirmModal();
			showToast(messages.errorToast, true);
		}
	}, [
		closeConfirmModal,
		setIsDeviceCannotBeRestartedModalOpen,
		siteName,
		device,
		mfpConfig,
		setIsExecutingAction,
		newName,
		showToast,
		messages.errorToast,
		changeModelConfigId,
		newModelConfig,
	]);

	const submitForm = useCallback(
		({ name, modelConfigId }: DeviceEditFormData) => {
			if (name === '') {
				setErrors({ name: translate(messages.fieldRequired, { field: messages.name }) as string });
				return;
			}
			if (name !== '' && name !== mfpConfig?.name) {
				const nameValidationError = validateDeviceName(name, siteName);
				if (nameValidationError !== undefined) {
					let message = '';
					switch (nameValidationError) {
						case 'charset':
							message = messages.invalidDeviceName.charsetError;
							break;
						case 'length':
							message = messages.invalidDeviceName.lengthError;
							break;
						case 'siteName':
							message = translate(messages.invalidDeviceName.siteNameError, { siteName }) as string;
							break;
					}
					setErrors({ name: message });
					return;
				}

				setNewName(name);
			}
			if (modelConfigId !== '' && modelConfigId !== mfpConfig?.modelConfigId) {
				setNewModelConfig(modelConfigId);
			}
			setIsConfirmModalOpen(true);
		},
		[setErrors, setIsConfirmModalOpen, setNewName, messages, translate, siteName, mfpConfig],
	);

	const gotoDevicePage = useCallback(() => {
		navigate(routes.device(deviceId));
	}, [navigate, deviceId]);

	// Rendering

	if (fetchingDeviceError) {
		let fetchError = fetchingDeviceError;
		if (Object.hasOwn(fetchingDeviceError, 'error')) {
			fetchError = (fetchingDeviceError as object as { error: GlobalErrorType }).error;
		}
		if (fetchError.status === 404) {
			return <Device404 variant="device" id={deviceId} />;
		}
		return <ShowError message={fetchError.message} />;
	}

	if (fetchingMfpConfigError) {
		let fetchError = fetchingMfpConfigError;
		if (Object.hasOwn(fetchingMfpConfigError, 'error')) {
			fetchError = (fetchingMfpConfigError as object as { error: GlobalErrorType }).error;
		}
		if (fetchError.status === 404) {
			return <Device404 variant="mfpConfig" id={deviceId} />;
		}
		return <ShowError message={fetchError.message} />;
	}

	if (!device || !mfpConfig) {
		return <LoadingPage backAction={backAction} />;
	}

	// At this point we have both the device data and it's mfpConfig

	// We check if the device can really be edited and if not show an error
	const cannotEdit = getDeviceState(device) !== DeviceState.NotInUse;
	const isVirtualDevice = isVirtualDeviceName(mfpConfig.name, siteName);

	let cannotEditMessage = '';
	if (cannotEdit) {
		cannotEditMessage = messages.cannotEditDevice.status;
	}
	if (isVirtualDevice) {
		cannotEditMessage = messages.cannotEditDevice.virtualDevice;
	}
	if (
		!experimentalFlags.includes('EDIT_DEVICE') ||
		!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager])
	) {
		cannotEditMessage = messages.cannotEditDevice.access;
	}
	if (cannotEditMessage) {
		return <ShowError message={cannotEditMessage} />;
	}

	// At this point we know that the device can be edited so we show the edit form
	const info: DeviceEditFormInfo = {
		device,
		lastCalibratedAt: mfpConfig.calibrationData.calibratedAt,
		model: getDeviceType(mfpConfig),
		network: ssid ?? '-',
	};

	const data: DeviceEditFormData = {
		modelConfigId: mfpConfig.modelConfigId || '',
		name: mfpConfig.name,
	};

	return (
		<Page title={messages.editDevice} backAction={backAction}>
			<>
				<DeviceEditForm mode="edit" onSubmit={submitForm} data={data} info={info} errors={errors} />

				<Modal
					title={messages.modalRestartRequired.title}
					open={isConfirmModalOpen}
					onClose={closeConfirmModal}
					primaryAction={{
						content: isExecutingAction
							? messages.modalRestartRequired.acting
							: messages.yesContinue,
						destructive: true,
						loading: isExecutingAction,
						onAction: save,
					}}
					secondaryActions={[
						{
							content: messages.noCancel,
							destructive: false,
							onAction: closeConfirmModal,
						},
					]}
				>
					<Modal.Section>
						{mfpConfig.modelConfigId !== newModelConfig ? (
							<>
								<p>{messages.modalRestartRequired.activeWorkAreaChanged}</p>
							</>
						) : (
							<></>
						)}
						{mfpConfig.name !== newName ? (
							<>
								<p>
									{translate(messages.modalRestartRequired.nameChanged, {
										newName,
										oldName: mfpConfig.name,
									})}
								</p>
								<p>{messages.modalRestartRequired.inAddition}</p>
								<ul>
									<li>{messages.modalRestartRequired.deviceNamePlateNeedsToChange}</li>
									<li>
										{translate(messages.modalRestartRequired.qrCodesWarning, {
											name: mfpConfig.name,
										})}
									</li>
								</ul>
							</>
						) : (
							<></>
						)}
						<p>{messages.wouldYouLikeToContinue}</p>
					</Modal.Section>
				</Modal>

				<Modal
					title={translate(messages.modalCannotRestart.title, { name: mfpConfig.name })}
					open={isDeviceCannotBeRestartedModalOpen}
					onClose={closeDeviceCannotBeRestartedModal}
					primaryAction={{
						content: messages.okay,
						onAction: closeDeviceCannotBeRestartedModal,
					}}
				>
					<Modal.Section>
						<TextStyle>{messages.modalCannotRestart.content}</TextStyle>
					</Modal.Section>
				</Modal>

				<Modal
					title={messages.success}
					open={isDeviceRenamedModalOpen}
					onClose={gotoDevicePage}
					primaryAction={{
						content: messages.okay,
						onAction: gotoDevicePage,
					}}
				>
					<Modal.Section>
						<TextStyle>{messages.deviceEditSuccess}</TextStyle>
					</Modal.Section>
				</Modal>
			</>
		</Page>
	);
}
