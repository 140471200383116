import { LegacyStack } from '@sixriver/lighthouse-web-community';
import clsx from 'clsx';
import { useLayoutEffect, useRef } from 'react';

import { Chuck } from './Chuck.type';
import { ChuckHighPriorityBadge } from './ChuckHighPriorityBadge';
import { ChuckIndications } from './ChuckIndications';
import { ChuckJobsProgressBar } from './ChuckJobsProgressBar';
import { ChuckTitle } from './ChuckTitle';
import {
	isDwellingIdle,
	isDwellingPaused,
	isFaulty,
	isHighPriority,
	isLowBattery,
	isPicking,
	isUpgrading,
} from './ChuckUtils';
import { useUserQuery } from './FloorView.hooks';
import floorViewStyles from './FloorView.module.css';
import sidePaneStyles from './SidePane.module.css';
import { getMidnight } from '../../helpers/time';
import { useJobsCounts } from '../../hooks/useJobCounts/useJobsCounts';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Props {
	chuck: Chuck;
	selected?: boolean;
	onSelect(id?: string): void;
}
const LAST_7_DAYS = getMidnight(-7);

/**
 * This component displays Chuck details including motion state (e.g. "Idle") and
 * workflow phase (e.g. "Induct"). When the component is selected (expanded), two
 * things happen: a single request is made for the name of the logged-in user, and
 * a polling request is made for the number of jobs in progress.
 */
export function ChuckCard({ chuck, selected, onSelect }: Props) {
	type PhaseMsg = keyof typeof messages.chuckPhases;
	type FaultMsg = keyof typeof messages.chuckFaults;
	type ResolutionMsg = keyof typeof messages.chuckResolutions;

	const { messages, translate, formatDateDiff } = useLocalization();
	const ref = useRef<HTMLLIElement>(null);
	const telemetry = chuck.telemetry;
	const updatedAt = telemetry?.idleAt || telemetry?.pausedAt || chuck.updatedAt;
	const motionState = telemetry?.motionState || 'idle';
	const motionLabel = messages.motionStates[motionState];
	const phaseLabel = messages.chuckPhases[chuck.phase as PhaseMsg] || chuck.phase;
	const userQuery = useUserQuery(chuck.badge ?? '', selected || false);
	const userName = isPicking(chuck) ? userQuery.result?.name || '...' : null;
	const counts = useJobsCounts(
		{ createdAtFrom: LAST_7_DAYS, isOnChuck: true, mfpIds: [chuck.id] },
		selected || false,
	);

	const onClick = () => onSelect(selected ? undefined : chuck.id);

	let statusMessage = null;

	if (!chuck.online) {
		statusMessage = translate(messages.diffLastSeen, { dateDiff: formatDateDiff(updatedAt) });
	} else if (telemetry?.idleAt || telemetry?.pausedAt) {
		statusMessage = translate(messages.diffLastMoved, { dateDiff: formatDateDiff(updatedAt) });
	}

	if (isUpgrading(chuck)) {
		statusMessage = translate(messages.upgradingToVersion, {
			version: chuck.telemetry?.stageUpgradeInfo?.nextVersion,
		});
	}

	useLayoutEffect(() => {
		const overlaps = (a?: DOMRect, b?: DOMRect) => {
			return a && b ? Math.max(a.top, b.top) <= Math.min(a.bottom, b.bottom) : false;
		};

		// if selected AND not in view, scroll into view
		if (selected) {
			const elem = ref.current;
			const rect1 = elem?.getBoundingClientRect();
			const rect2 = elem?.parentElement?.getBoundingClientRect();

			if (!overlaps(rect1, rect2)) {
				elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [selected]);

	const borderLeftColor = isHighPriority(chuck) ? chuck.telemetry?.highPriorityColor : undefined;

	return (
		<li
			ref={ref}
			className={clsx(sidePaneStyles.listItem, {
				[sidePaneStyles.listItemHightPriority]: isHighPriority(chuck),
			})}
			aria-selected={selected}
			data-testid="chuck-card"
			onClick={onClick}
			role="button"
			style={{
				borderLeftColor,
			}}
		>
			<LegacyStack vertical spacing="tight">
				<LegacyStack distribution="equalSpacing" alignment="center" spacing="tight">
					<ChuckTitle
						chuckId={chuck.id}
						motionState={motionState}
						isDwellingIdle={isDwellingIdle(chuck)}
						isDwellingPaused={isDwellingPaused(chuck)}
						isFaulty={isFaulty(chuck)}
						isLowBattery={isLowBattery(chuck)}
						isUpgrading={isUpgrading(chuck)}
						isOffline={!chuck.online}
						isPriority={isHighPriority(chuck)}
					/>
					<ChuckIndications
						isPicking={isPicking(chuck)}
						isOffline={!chuck.online}
						telemetry={telemetry}
					/>
				</LegacyStack>
				{selected ? (
					<LegacyStack vertical>
						<LegacyStack distribution="equalSpacing" alignment="trailing" wrap={false}>
							<div>
								{isHighPriority(chuck) ? (
									<div className={floorViewStyles.badge}>
										<ChuckHighPriorityBadge
											highPriorityReasons={chuck.telemetry?.highPriorityReasons}
										/>
									</div>
								) : null}
								<div>
									{chuck.online ? motionLabel + ' / ' + phaseLabel : messages.chuckStates.offline}
								</div>
								<div>{statusMessage}</div>
								<div>{userName}</div>
							</div>
							<div>
								{counts && counts.totalJobs > 0 ? (
									<a
										href={`${routes.outboundJobs()}?query=${
											chuck.name
										}&historyDateLimiter=${LAST_7_DAYS}&isOnChuck=true`}
										target="_blank"
										rel="noreferrer"
										onClick={(e) => e.stopPropagation()}
									>
										{`${translate(messages.countJobs, { count: counts.totalJobs })}`}
									</a>
								) : null}
							</div>
						</LegacyStack>
						<ChuckJobsProgressBar counts={counts} />
						{isFaulty(chuck)
							? telemetry?.faults?.map((fault) => {
									const faultLabel =
										messages.chuckFaults[
											(fault.code.startsWith('NAV') ? 'NAVIGATION_ANY' : fault.code) as FaultMsg
										] || fault.code;

									const resolutionLabel =
										messages.chuckResolutions[fault.resolution as ResolutionMsg] ||
										fault.resolution;

									return (
										<div className={floorViewStyles.fault} key={fault.id}>
											<b>{faultLabel}</b>
											<div>{messages.possibleResolution + ': ' + resolutionLabel}</div>
										</div>
									);
							  })
							: null}
					</LegacyStack>
				) : null}
			</LegacyStack>
		</li>
	);
}
