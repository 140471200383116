import * as Sentry from '@sentry/react';
import { Banner, Modal, Text } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { useForceOfflineMutation } from './graphql/ForceOffline.f-api-graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';

interface ForceOfflineModalProps {
	open: boolean;
	onClose: () => void;
	mfpId: string;
}

export function ForceOfflineModal({ open, onClose, mfpId }: ForceOfflineModalProps) {
	const { messages, translate } = useLocalization();
	const [loading, setLoading] = useState(false);
	const [, executeForceOfflineMutation] = useForceOfflineMutation();
	const [error, setError] = useState<React.ReactNode>(null);
	const { showToast } = useToast();

	// Reset state on dismiss as the component stays mounted
	const onDismiss = () => {
		setError(null);
		setLoading(false);
		onClose();
	};

	const onForceOffline = async () => {
		setLoading(true);
		try {
			const result = await executeForceOfflineMutation({
				mfpName: mfpId,
			});

			setLoading(false);

			if (result.data?.forceDeviceOffline) {
				showToast(messages.forceOffline.forcedOffline);
				onDismiss();
			}

			const gqlErrors = result.error?.graphQLErrors;
			const forceOfflineError = gqlErrors && gqlErrors.length > 0 && gqlErrors[0]?.extensions?.code;
			if (forceOfflineError) {
				switch (forceOfflineError) {
					case 'FORCE_OFFLINE_DEVICE_NOT_FOUND':
					case 'FORCE_OFFLINE_PRESENCE_DATA_UNAVAILABLE':
					case 'FORCE_OFFLINE_DEVICE_NEVER_ONLINE':
						return setError(translate(messages.forceOffline.error.notAvailable, { mfpId }));
					case 'FORCE_OFFLINE_DEVICE_NOT_OFFLINE':
						return setError(translate(messages.forceOffline.error.currentlyOnline, { mfpId }));
					case 'FORCE_OFFLINE_DEVICE_RECENTLY_ONLINE':
						return setError(translate(messages.forceOffline.error.recentlyOnline, { mfpId }));
					default:
						Sentry.captureEvent({
							extra: {
								gqlErrors,
							},
							level: 'error',
							message: `Unknown error occurred in fulfillment-api while trying to force ${mfpId} offline`,
						});
						return setError(translate(messages.forceOffline.error.failed, { mfpId }));
				}
			}
		} catch (e: any) {
			Sentry.captureException({
				error: e,
				message: `Unknown error occurred while trying to force ${mfpId} offline`,
			});

			setError(messages.forceOffline.error.failed);
		}
	};

	return (
		<Modal
			open={open}
			onClose={onDismiss}
			title={messages.forceOffline.forceOffline}
			primaryAction={{
				content: messages.forceOffline.forceOffline,
				destructive: true,
				loading,
				onAction: onForceOffline,
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onDismiss,
				},
			]}
		>
			<Modal.Section>
				<Banner status="warning">
					<Text as="span" fontWeight="bold">
						{translate(messages.forceOffline.danger)}
					</Text>
					&nbsp;-&nbsp;
					{translate(messages.forceOffline.warning, { mfpId })}
				</Banner>
			</Modal.Section>
			<Modal.Section>
				<Text as="p">{messages.forceOffline.summary}</Text>
				<br />
				<Text as="p">{messages.forceOffline.description}</Text>
			</Modal.Section>
			{error && (
				<Modal.Section>
					<Banner status="critical">{error}</Banner>
				</Modal.Section>
			)}
		</Modal>
	);
}
