import { LegacyCard } from '@sixriver/lighthouse-web-community';
import { CardDetails } from '@sixriver/react-support';

import { DroppedLines } from './DroppedLines';
import { DroppedOrders } from './DroppedOrders';
import { FailureDetails } from './FailureDetails';
import { Received } from './Received';
import { Resolved } from './Resolved';
import { TotalLines } from './TotalLines';
import { TotalOrders } from './TotalOrders';
import { PickWaveStatus } from '../../../api/fulfillment-api/types';
import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';

interface PickWaveDetailsProps {
	pickWave: PickWaveFieldsFragment;
}

export function PickWaveDetails({ pickWave }: PickWaveDetailsProps) {
	const primary = [
		{
			content: <TotalLines pickWave={pickWave} />,
			label: 'Total lines',
		},
		{
			content: <DroppedLines pickWave={pickWave} />,
			label: 'Dropped lines',
		},
		{
			content: <TotalOrders pickWave={pickWave} />,
			label: 'Total orders',
		},
		{
			content: <DroppedOrders pickWave={pickWave} />,
			label: 'Dropped orders',
		},
	];

	if (
		pickWave.status === PickWaveStatus.PartialSuccess ||
		pickWave.status === PickWaveStatus.Failure
	) {
		primary.push({
			content: <FailureDetails pickWave={pickWave} />,
			label: 'Failure details',
		});
	}

	if (pickWave.resolvedAt) {
		primary.push({
			content: <Resolved pickWave={pickWave} />,
			label: 'Resolved',
		});
	} else {
		primary.push({
			content: <Received pickWave={pickWave} />,
			label: 'Received',
		});
	}

	return (
		<LegacyCard title="Wave Details" sectioned>
			<CardDetails primary={primary} />
		</LegacyCard>
	);
}
