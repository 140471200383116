import { PrintMajor } from '@sixriver/lighthouse-icons';
import {
	Layout,
	Modal,
	LegacyStack,
	TextContainer,
	PageProps,
} from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SortWall404 } from './SortWall404';
import { SortWallBreakdown } from './SortWallBreakdown';
import { SortWallDetails } from './SortWallDetails';
import { useCancelSortWallMutation } from './graphql/CancelSortWall.f-api-graphql';
import {
	GetSortWallDocument,
	GetSortWallQuery,
	GetSortWallQueryVariables,
} from './graphql/GetSortWall.w-api-graphql';
import { useReprintLabelsMutation } from './graphql/ReprintLabels.f-api-graphql';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { ErrorBanner } from '../../components/ErrorBanner';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { SortationStatusBadge } from '../../components/SortationStatusBadge';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { usePrintSortWallLabel } from '../../hooks/usePrintLabelButton';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { logger } from '../../utils';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function SortWall() {
	const { messages, translate } = useLocalization();

	const { sortWallId = '' } = useParams<{ sortWallId: string }>();
	const backAction: PageProps['backAction'] = {
		content: messages.sortWalls,
		url: routes.sortWalls(),
	};
	const navigate = useNavigate();

	const { showToast } = useToast();

	const [getSortWallQuery] = usePollingQuery<GetSortWallQuery, GetSortWallQueryVariables>({
		query: GetSortWallDocument,
		variables: {
			id: sortWallId,
		},
	});

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [cancelSortWallMutation, executeCancelSortWallMutation] = useCancelSortWallMutation();
	const [reprintLabelsMutation, executeReprintLabelsMutation] = useReprintLabelsMutation();

	const sortWall = getSortWallQuery.data?.job;

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const { buttonText: printLabelButtonText, onLabelPrintSuccess } = usePrintSortWallLabel({
		disabled: getSortWallQuery.fetching,
		id: sortWall?.id,
	});

	const printLabels = async () => {
		setIsBannerErrorVisible(false);

		if (!sortWall) {
			return;
		}

		try {
			const { error } = await executeReprintLabelsMutation({ projectionId: sortWall.id });

			if (error) {
				throw error;
			}

			onLabelPrintSuccess();

			showToast(messages.labelsPrinted);
		} catch (err) {
			logger.error({ err }, 'failed printing sortwall labels');

			setIsBannerErrorVisible(true);
		}
	};

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		if (!sortWall) {
			return;
		}

		try {
			const { error } = await executeCancelSortWallMutation({ projectionId: sortWall.id });

			closeCancelModal();

			if (error) {
				throw error;
			}

			showToast(messages.sortWallCanceled);
			navigate(routes.sortWalls());
		} catch (err) {
			logger.error({ err }, 'failed canceling sortwall job');

			setIsBannerErrorVisible(true);
		}
	};

	if (getSortWallQuery.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (!sortWall) {
		if (hasMissingEntityError(getSortWallQuery.error)) {
			return <SortWall404 id={sortWallId} />;
		}
		return (
			<ErrorPage
				combinedError={getSortWallQuery.error}
				action={{
					content: messages.sortWallError,
					url: routes.sortWalls(),
				}}
			/>
		);
	}

	const sortations = sortWall?.sortations.edges ?? [];
	const sortation = sortations[0]?.node ?? {};

	return (
		<>
			<AutoRefreshPage
				queries={[getSortWallQuery]}
				backAction={backAction}
				title={translate(messages.sortWallByName, { name: sortation.wallDisplayId }) as string}
				titleMetadata={
					<LegacyStack spacing="extraTight">
						<SortationStatusBadge sortationStatus={sortWall?.status as any} isEmptyIfNoData />
					</LegacyStack>
				}
				secondaryActions={[
					{
						content: printLabelButtonText,
						disabled: getSortWallQuery.fetching,
						icon: PrintMajor,
						loading: reprintLabelsMutation.fetching,
						onAction: () => void printLabels(),
					},
					{
						content: messages.cancelWall,
						// LOL-3306 - data is not currently available
						disabled: true,
						onAction: openCancelModal,
					},
				]}
			>
				<Layout>
					<Layout.Section>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallDetails loading={getSortWallQuery.fetching} sortWall={sortWall} />
					</Layout.Section>

					<Layout.Section>
						<SortWallBreakdown loading={getSortWallQuery.fetching} sortWall={sortWall} />
					</Layout.Section>

					{/*
					LOL-3365

					<Layout.Section>
						<SortWallLinesTableV2 jobId={sortWallId} loading={getSortWallQuery.fetching} />
					</Layout.Section>

					<Layout.Section>


						Must be uncommented when resolved
						apps/bridge/src/pages/SortWall/SortWallOrdersTable/SortWallOrdersTableViews.ts

						<SortWallOrdersTable
							sortWall={sortWall}
							orders={sortWall?.customerOrdersV2}
							loading={getSortWallQuery.fetching}
						/>
					</Layout.Section>

					<Layout.Section>
						<SortWallPickingJobsTable
							sortWall={sortWall}
							pickingJobs={sortWall?.pickingJobsV2}
							loading={getSortWallQuery.fetching}
						/>
					</Layout.Section> */}

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</AutoRefreshPage>
			<Modal
				open={isCancelModalOpen}
				onClose={closeCancelModal}
				title={messages.cancelSortWall}
				primaryAction={{
					content: messages.cancelSortWall,
					destructive: true,
					loading: cancelSortWallMutation.fetching,
					onAction: () => void cancelJob(),
				}}
				secondaryActions={[
					{
						content: messages.keepSortWall,
						onAction: closeCancelModal,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>{messages.cancelSortWallConfirm}</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</>
	);
}
