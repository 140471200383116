import { LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';

import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';
import { errorCodeToMessage } from '../helpers';

interface FailureDetailsProps {
	pickWave: PickWaveFieldsFragment;
}

export function FailureDetails({ pickWave }: FailureDetailsProps) {
	const messages = pickWave.errorCodes.map((errorCode, index) => {
		const message = errorCodeToMessage(errorCode);
		return (
			<TextStyle key={index} variation="negative">
				{message}
			</TextStyle>
		);
	});

	return (
		<LegacyStack vertical spacing="extraTight">
			{messages}
		</LegacyStack>
	);
}
