import { PromoteMinor } from '@sixriver/lighthouse-icons';
import {
	Icon,
	LegacyStack,
	TextStyle,
	DisplayText,
	Heading,
} from '@sixriver/lighthouse-web-community';
import { useNavigate } from 'react-router-dom';

import styles from './BreakdownAndIssues.module.css';
import { JobStatus } from '../../api/fulfillment-api/types';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

const getParams = (jobs?: { id: string }[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		jobs.forEach((job) => {
			searchParams.append('ids', job.id);
		});
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	order: GetOrderQueryJob;
}

export function BreakdownAndIssues({ order }: Props) {
	const { messages, translate } = useLocalization();

	const navigate = useNavigate();
	const unassignedResolutionJobs = order.pickingJobs.filter(
		(job) => job.isHealJob && job.status === JobStatus.Unassigned,
	);
	const relatedJobs = [...unassignedResolutionJobs, ...order.pickingJobs];
	const disableTrackJobs = relatedJobs.length === 0;

	// const numberOfLineExceptions = countLineExceptionsV2(order.lines);
	const numberOfLineExceptions = 0;
	// const numberOfUnitExceptions = countUnitExceptionsV2(order.lines);
	const numberOfUnitExceptions = 0;
	const lines = order.lines ? order.lines.edges : [];

	return (
		<div className={styles.cardSection}>
			<LegacyStack vertical={false} distribution={'fillEvenly'}>
				<>
					<div className={styles.header}>
						<Heading>{messages.breakdown}</Heading>
					</div>
					<LegacyStack vertical={false}>
						<LegacyStack vertical={true}>
							<TextStyle variation="strong">{messages.totalUnits}</TextStyle>
							<DisplayText>
								{lines.reduce((acc, { node }) => (acc += node.quantity), 0)}
							</DisplayText>
						</LegacyStack>
						<LegacyStack vertical={true}>
							<TextStyle variation="strong">{messages.totalLines}</TextStyle>
							<DisplayText>{lines.length.toString()}</DisplayText>
						</LegacyStack>
						<LegacyStack vertical={true}>
							<TextStyle variation="strong">{messages.jobs}</TextStyle>
							<button
								className={styles.jobs}
								onClick={() => navigate(`${routes.outboundJobs()}${getParams(relatedJobs)}`)}
								disabled={disableTrackJobs}
							>
								<LegacyStack vertical={false} alignment="center" spacing="tight">
									<DisplayText size="medium">{relatedJobs.length.toString()}</DisplayText>
									<Icon
										source={PromoteMinor}
										color={disableTrackJobs ? 'subdued' : 'primary'}
									></Icon>
								</LegacyStack>
							</button>
						</LegacyStack>
					</LegacyStack>
				</>
				<>
					<div className={styles.header}>
						<Heading>{messages.issues}</Heading>
					</div>
					<LegacyStack>
						<LegacyStack vertical={true}>
							<TextStyle variation="strong">{messages.activeExceptions}</TextStyle>
							<TextStyle>
								{translate(messages.countUnits, {
									count: <b>{numberOfUnitExceptions}</b>,
								})}
							</TextStyle>
							<TextStyle>
								{translate(messages.countLines, {
									count: <b>{numberOfLineExceptions}</b>,
								})}
							</TextStyle>
						</LegacyStack>
					</LegacyStack>
				</>
			</LegacyStack>
		</div>
	);
}
