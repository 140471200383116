// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleAvailableDestinationsCountQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  states?: Types.InputMaybe<Array<Types.GoalState> | Types.GoalState>;
  orderBy?: Types.InputMaybe<Array<Types.ShuttleAvailableDestinationOrderBy> | Types.ShuttleAvailableDestinationOrderBy>;
}>;


export type GetShuttleAvailableDestinationsCountQuery = { __typename?: 'Query', countShuttleAvailableDestinations: { __typename?: 'ShuttleAvailableDestinationCount', unassigned: number, inProgress: number, interrupted: number, held: number, completed: number, cancelled: number } };


export const GetShuttleAvailableDestinationsCountDocument = gql`
    query wapi_GetShuttleAvailableDestinationsCount($cursor: String, $limit: Float, $searchText: String, $states: [GoalState!], $orderBy: [ShuttleAvailableDestinationOrderBy!]) {
  countShuttleAvailableDestinations(
    cursor: $cursor
    limit: $limit
    searchText: $searchText
    states: $states
    orderBy: $orderBy
  ) {
    unassigned
    inProgress
    interrupted
    held
    completed
    cancelled
  }
}
    `;

export function useGetShuttleAvailableDestinationsCountQuery(options?: Omit<Urql.UseQueryArgs<GetShuttleAvailableDestinationsCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleAvailableDestinationsCountQuery, GetShuttleAvailableDestinationsCountQueryVariables>({ query: GetShuttleAvailableDestinationsCountDocument, ...options });
};