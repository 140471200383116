import { LegacyCard, Layout, Page, PageProps, Button } from '@sixriver/lighthouse-web-community';
import { DateTime, useAuth, UserRole, useSiteName } from '@sixriver/react-support';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Device404 } from './Device404';
import { DeviceActivity } from './DeviceActivity';
import { DeviceBattery } from './DeviceBattery';
import { DeviceState, DeviceType, GlobalErrorType } from '../../api';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { ForceOfflineModal } from '../../components/ForceOfflineModal';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { NoData } from '../../components/NoData';
import {
	getDeviceState,
	getDeviceType,
	isVirtualDeviceName,
	useAvailableMfp,
} from '../../hooks/useAvailableMfps';
import { useExperimentalFlags, useMfpConfigForDevice, useSsid } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function Device(): JSX.Element {
	const experimentalFlags = useExperimentalFlags();
	const { isUserAllowed } = useAuth();
	const { messages } = useLocalization();
	const siteName = useSiteName();
	const [forceOfflineOpen, setForceOfflineOpen] = useState(false);

	const { deviceId = '' } = useParams<{ deviceId: string }>();
	const { data: device, error: fetchingDeviceError } = useAvailableMfp(deviceId);
	const backAction: PageProps['backAction'] = {
		content: messages.devices,
		url: routes.devices(),
	};
	const { data: mfpConfig, error: fetchingMfpConfigError } = useMfpConfigForDevice(device);
	const ssid = useSsid();

	// Rendering

	if (fetchingDeviceError) {
		let fetchError = fetchingDeviceError;
		if (Object.hasOwn(fetchingDeviceError, 'error')) {
			fetchError = (fetchingDeviceError as object as { error: GlobalErrorType }).error;
		}
		const message = fetchError.message;
		if (fetchError.status === 404) {
			return <Device404 variant="device" id={deviceId} />;
		}
		return <Error message={message} />;
	}

	if (fetchingMfpConfigError) {
		let fetchError = fetchingMfpConfigError;
		if (Object.hasOwn(fetchingMfpConfigError, 'error')) {
			fetchError = (fetchingMfpConfigError as object as { error: GlobalErrorType }).error;
		}
		const message = fetchError.message;
		if (fetchError.status === 404) {
			return <Device404 variant="mfpConfig" id={deviceId} />;
		}
		return <Error message={message} />;
	}

	if (!device || !mfpConfig) {
		return <LoadingPage backAction={backAction} />;
	}

	// At this point we have both device and it's mfpConfig so we show the device info
	const deviceType = getDeviceType(mfpConfig);
	const cannotEdit = getDeviceState(device) !== DeviceState.NotInUse;
	const isVirtualDevice = isVirtualDeviceName(mfpConfig.name, siteName);

	let actionContent = messages.edit;
	if (cannotEdit) {
		actionContent = messages.cannotEditDevice.status;
	}
	if (isVirtualDevice) {
		actionContent = messages.cannotEditDevice.virtualDevice;
	}
	const showEditAction =
		experimentalFlags.includes('EDIT_DEVICE') &&
		isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]);

	return (
		<Page title={mfpConfig.name} backAction={backAction}>
			<Layout>
				<Layout.Section>
					<LegacyCard
						title={messages.profile}
						actions={
							showEditAction
								? [
										{
											content: actionContent,
											disabled: cannotEdit || isVirtualDevice,
											url: routes.editDevice(deviceId),
										},
								  ]
								: undefined
						}
					>
						<LegacyCard.Section>
							<CardDetails
								primary={[
									{
										content: mfpConfig.name,
										label: messages.name,
									},
									{
										content: device ? (
											messages.deviceTypes[deviceType as keyof typeof messages.deviceTypes]
										) : (
											<NoData />
										),
										label: messages.model,
									},
									{
										content: ssid,
										label: messages.network,
									},
								]}
							/>
						</LegacyCard.Section>
						{deviceType === DeviceType.TerminalOnWheels ||
						deviceType === DeviceType.Handheld ? null : (
							<LegacyCard.Section>
								<CardDetails
									primary={[
										{
											content: <DeviceBattery device={device} />,
											label: messages.batteryLevel,
										},
										{
											content: <DateTime date={mfpConfig.calibrationData.calibratedAt} />,
											label: messages.lastCalibrated,
										},
									]}
								/>
							</LegacyCard.Section>
						)}
					</LegacyCard>
				</Layout.Section>
				<Layout.Section>
					<DeviceActivity
						info={{ device, model: deviceType }}
						title={messages.active}
						findChuck={true}
					/>
				</Layout.Section>
				<Layout.Section>
					<Button onClick={() => setForceOfflineOpen(true)} destructive plain>
						{messages.forceOffline.forceOffline}
					</Button>
				</Layout.Section>
			</Layout>
			<ForceOfflineModal
				open={forceOfflineOpen}
				onClose={() => setForceOfflineOpen(false)}
				mfpId={device.id}
			/>
		</Page>
	);
}
