import { useDebouncedValue } from '@shopify/react-hooks';
import { LegacyCard, LegacyStack, Layout, Pagination } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';

import { ExceptionsFilters } from './ExceptionsFilters';
import { ExceptionsAction, ExceptionsTable } from './ExceptionsTable';
import { PrintLabelsBanner } from './PrintLabelsBanner';
import {
	GetExceptionOrdersDocument,
	GetExceptionOrdersQuery,
	GetExceptionOrdersQueryVariables,
} from './graphql/GetExceptionOrders.w-api-graphql';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { ErrorBanner } from '../../components/ErrorBanner';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useApplyManualPickHealPlan } from '../../hooks/useHealPlan';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useArrayQueryState, useQueryState } from '../../hooks/useQueryState';

const PAGE_SIZE = getPageSize();
const SEARCH_TEXT_KEY = 'query';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export function Exceptions() {
	const { messages } = useLocalization();
	const [isErrorBannerVisible, setIsErrorBannerVisible] = useState(false);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const debouncedSearchText = useDebouncedValue(searchText);
	const [didPrintLabels, setDidPrintLabels] = useState(false);
	const [totalCount] = useState(0);
	const [selectable] = useState(true);
	const [actions] = useState<ExceptionsAction[]>([ExceptionsAction.ManuallyResolve]);

	/**
	 * Queries
	 */
	const [getExceptionOrdersQuery, refetchOrders] = usePollingQuery<
		GetExceptionOrdersQuery,
		GetExceptionOrdersQueryVariables
	>({
		query: GetExceptionOrdersDocument,
		variables: {
			after: paginationCursors[0],
			first: PAGE_SIZE,
			isHealable: true,
			isShorted: true,
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
		},
	});

	const orders = getExceptionOrdersQuery.data?.getOrders.edges;
	const ordersPageInfo = getExceptionOrdersQuery.data?.getOrders.pageInfo;
	const loading = getExceptionOrdersQuery.fetching;
	const error = getExceptionOrdersQuery.error;

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors([endCursor].concat(paginationCursors));
		}
	}, [ordersPageInfo?.endCursor, paginationCursors, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	/**
	 * Healing mutations
	 */
	const { applyManualHealPlan } = useApplyManualPickHealPlan();
	const handleManuallyResolve = useCallback(
		async (ids: string[]) => {
			await Promise.all(ids.map((id) => applyManualHealPlan(id)));
			setDidPrintLabels(true);
			await refetchOrders();
		},
		[applyManualHealPlan, refetchOrders],
	);

	if (getExceptionOrdersQuery.error && !getExceptionOrdersQuery.data) {
		return <ErrorPage combinedError={error} />;
	}

	return (
		<AutoRefreshPage queries={[getExceptionOrdersQuery]} fullWidth title={messages.exceptions}>
			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isErrorBannerVisible}
						onDismiss={() => {
							setIsErrorBannerVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<LegacyCard>
						<div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem' }}>
							<ExceptionsFilters queryValue={searchText} onQueryChange={setSearchText} />
						</div>

						<PrintLabelsBanner
							isVisible={didPrintLabels}
							onDismiss={() => setDidPrintLabels(false)}
						/>

						<ExceptionsTable
							loading={loading}
							data={orders}
							onManuallyResolve={(selectedIds) => void handleManuallyResolve(selectedIds)}
							selectable={selectable}
							actions={actions}
						/>
						<LegacyCard.Section>
							<LegacyStack distribution="center">
								<Pagination
									label={`${paginationCursors.length + 1} ${messages.of} ${Math.max(
										Math.ceil(totalCount / PAGE_SIZE),
										1,
									)}`}
									hasNext={ordersPageInfo?.hasNextPage}
									hasPrevious={ordersPageInfo?.hasPreviousPage}
									onNext={handleOnNextPage}
									onPrevious={handleOnPreviousPage}
								/>
							</LegacyStack>
						</LegacyCard.Section>
					</LegacyCard>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
