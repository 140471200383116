import { IndexTable } from '@sixriver/lighthouse-web-community';

import { PutawayJobStatus } from './PutawayJobStatus';
import { GoalState } from '../../../api/warehouse-api/types';

export function ProgressCell({ status }: { status?: GoalState }) {
	return (
		<IndexTable.Cell>
			<PutawayJobStatus status={status} />
		</IndexTable.Cell>
	);
}
