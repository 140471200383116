import { Tag, LegacyStack, Tooltip } from '@sixriver/lighthouse-web-community';

import { OrderV2 } from '../../api/fulfillment-api/types';
import { CardDetails } from '../../components/CardDetails';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	order: OrderV2 & { allowedDeviceClasses: string[]; usedDeviceClasses: string[] };
}

export function Equipment({ order }: Props) {
	const { messages } = useLocalization();

	return (
		<>
			<CardDetails
				primary={[
					{
						content:
							order.allowedDeviceClasses.length > 0 ? (
								<LegacyStack spacing="tight">
									{order.allowedDeviceClasses.map((deviceClass, index) => {
										return (
											<Tooltip key={index} content={messages.eligibleToPick}>
												<Tag key={index}>{deviceClass}</Tag>
											</Tooltip>
										);
									})}
								</LegacyStack>
							) : (
								<NoData />
							),
						label: messages.eligibleEquipment,
					},
				]}
				secondary={[
					{
						content:
							order.usedDeviceClasses.length > 0 ? (
								<LegacyStack spacing="tight">
									{order.usedDeviceClasses.map((deviceClass, index) => {
										return (
											<Tooltip key={index} content={messages.equipmentUsedToPick}>
												<Tag key={index}>{deviceClass}</Tag>
											</Tooltip>
										);
									})}
								</LegacyStack>
							) : (
								<NoData />
							),
						label: messages.equipmentUsed,
					},
				]}
			/>
		</>
	);
}
