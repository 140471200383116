import { useFetch } from '@hyper-fetch/react';
import { useMemo } from 'react';

import {
	DeviceType,
	GlobalErrorType,
	MfpConfigExtended,
	getMfpConfigs,
	getMfpConfig,
	AvailableMfp,
} from '../../api';

export function useMfpConfigs(): {
	data: MfpConfigExtended[];
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const { loading, data, error } = useFetch(getMfpConfigs);
	return { data: data ?? [], error, fetching: loading };
}

export function useMfpConfig(filter?: object): {
	data: MfpConfigExtended | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
} {
	const request = useMemo(() => {
		let req = getMfpConfig.clone();
		if (filter) {
			req = req.setQueryParams({ filter: JSON.stringify(filter) });
		}
		return req;
	}, [filter]);

	const { loading, data, error } = useFetch(request);
	return { data, error, fetching: loading || !data };
}

export function useMfpConfigForDevice(device: AvailableMfp | null) {
	const request = useMemo(() => {
		let req = getMfpConfig.clone();
		req = req.setQueryParams({
			filter: JSON.stringify({ where: { name: device?.id } }),
		});
		return req;
	}, [device]);

	const { loading, data, error } = useFetch(request, { disabled: !device });
	return { data, error, fetching: loading || !data };
}

export function useDistinctDeviceTypes(): DeviceType[] {
	const { data: mfpConfigs } = useMfpConfigs();
	const deviceTypes = mfpConfigs.map((mfpConfig) => {
		switch (mfpConfig.config?.deviceClass) {
			case 'handheld':
				return DeviceType.Handheld;
			case 'packoutTerminal':
				return DeviceType.PackoutTerminal;
			case 'sortationKiosk':
				return DeviceType.SortationKiosk;
			case 'terminalOnWheels':
				return DeviceType.TerminalOnWheels;
			default:
				return DeviceType.Chuck;
		}
	});
	return Array.from(new Set(deviceTypes));
}
