import { RiskMinor } from '@sixriver/lighthouse-icons';
import {
	Action,
	CalloutCard,
	Icon,
	LegacyStack,
	TextStyle,
} from '@sixriver/lighthouse-web-community';

import { JobStatus, PickStrategy } from '../../api/fulfillment-api/types';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

const getParams = (jobs?: { id: string }[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		jobs.forEach((job) => {
			searchParams.append('ids', job.id);
		});
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	order: GetOrderQueryJob;
}

export function ResolutionSummaryCalloutCard({ order }: Props): JSX.Element | null {
	const { messages, translate } = useLocalization();

	const unassignedResolutionJobs = order.pickingJobs.filter(
		(job) => job.isHealJob && job.status === JobStatus.Unassigned,
	);
	const relatedJobs = [...unassignedResolutionJobs, ...order.pickingJobs];
	const relatedResolutionJobs = relatedJobs.filter((job) => job.isHealJob);

	const isSystemSlxuResolved =
		order.pickStrategy === PickStrategy.Singles && relatedJobs.length > 1;

	if (order.isShorted && (relatedResolutionJobs.length > 0 || isSystemSlxuResolved)) {
		let messageContent;
		let action: Action;

		if (order.isAwaitingInventory) {
			messageContent = translate(messages.resolutionJobBlockedOrderSummary, { count: 1 });
			action = {
				content: translate(messages.resolutionJobsTrack, {
					count: relatedResolutionJobs.length,
				}) as string,
				url: `${routes.outboundJobs()}${getParams(relatedResolutionJobs)}`,
			};
		} else if (isSystemSlxuResolved) {
			messageContent = messages.resolutionJobOrderSummarySlxu;
			action = {
				content: messages.trackJobs,
				url: `${routes.outboundJobs()}${getParams(relatedJobs)}`,
			};
		} else if (unassignedResolutionJobs.length > 0 || relatedResolutionJobs.length === 1) {
			messageContent = translate(messages.resolutionJobsOrderSummary, { count: 1 });
			action = {
				content: translate(messages.resolutionJobsTrack, {
					count: relatedResolutionJobs.length,
				}) as string,
				url: `${routes.outboundJobs()}${getParams(relatedResolutionJobs)}`,
			};
		} else {
			messageContent = translate(messages.resolutionJobsOrderSummary, {
				count: relatedResolutionJobs.length,
			});
			action = {
				content: translate(messages.resolutionJobsTrack, {
					count: relatedResolutionJobs.length,
				}) as string,
				url: `${routes.outboundJobs()}${getParams(relatedResolutionJobs)}`,
			};
		}

		return (
			<CalloutCard title={''} illustration={''} primaryAction={action}>
				<LegacyStack>
					<Icon source={RiskMinor} color="critical" />
					<TextStyle>{messageContent}</TextStyle>
				</LegacyStack>
			</CalloutCard>
		);
	}

	return null;
}
