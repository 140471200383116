import { UserRole } from '@sixriver/react-support';

import { NewMfa } from './NewMfa';
import { AuthorizedRoute } from '../../../routeGuards/AuthorizedRoute';

export function NewMfaRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<NewMfa />
		</AuthorizedRoute>
	);
}
