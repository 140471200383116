import { DescriptionList } from '@sixriver/lighthouse-web-community';
import { ReactNode } from 'react';

import styles from './CardDetails.module.css';
import { NoData } from '../NoData';
import { PendingText } from '../PendingText';

export interface ListItem {
	label: string;
	content: string | ReactNode;
}

type ListOptions = ListItem | null;
interface Props {
	loading?: boolean;
	primary: Array<ListOptions | ListOptions[]>;
	secondary?: Array<ListOptions | ListOptions[]>;
}

export const listItem = (
	label: string,
	content: any[] | string | number | JSX.Element | null | undefined,
): ListItem => {
	if (!content) {
		// Default content
		content = <NoData />;
	}

	if (Array.isArray(content)) {
		// If multiple options for content are given return the first truthy value.
		// Works like a switch statement (order matters)
		const firstValidContent = content.filter((state) => state)[0];

		if (firstValidContent) {
			return {
				content: firstValidContent,
				label,
			};
		}
	}

	return {
		content,
		label,
	};
};

const mapItems = (items: Array<ListOptions | ListOptions[]>, loading?: boolean) => {
	// Allow null and nested array to be input (cleans up component). Flatten array and filter out null.
	// This ensures the content is correct for the map to label and content
	// const flatItems: Array<ListItem | null> = items.flat();
	const listItems = items.flat().filter((item) => item !== null) as ListItem[];
	return listItems.map(({ label, content }) => {
		return { description: <PendingText loading={loading} value={content} />, term: label + ':' };
	});
};

export function CardDetails({ loading, primary, secondary }: Props) {
	return (
		<div className={styles.cardDetails}>
			<DescriptionList items={mapItems(primary, loading)} />
			{secondary ? <DescriptionList items={mapItems(secondary, loading)} /> : null}
		</div>
	);
}
