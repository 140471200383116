import { CircleInformationMajor } from '@sixriver/lighthouse-icons';
import { Banner, LegacyCard, Page, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { JobFlowRule404 } from './JobFlowRule404';
import { JobFlowRuleCriteria } from './JobFlowRuleCriteria';
import { useEquipmentName, useFilterLabel, useWorkAreaName } from './JobFlowRuleHelpers';
import styles from './JobFlowRules.module.css';
import { CardDetails } from '../../components/CardDetails';
import { Error } from '../../components/Error';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { JobFlowRuleFilter, getJobFlowRuleFilters } from '../../helpers/job-flow-rule-filters';
import { useServiceConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { logger } from '../../utils';
import { useGetJobFlowRuleQuery } from './graphql/GetJobFlowRule.f-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';

export function JobFlowRule() {
	const { jobFlowRuleId = '' } = useParams<{ jobFlowRuleId: string }>();

	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.jobFlowRules,
		url: routes.jobFlowRules(),
	};
	const getWorkAreaName = useWorkAreaName();
	const getFilterLabel = useFilterLabel();

	const {
		serviceConfig,
		fetching: serviceConfigFetching,
		error: serviceConfigError,
	} = useServiceConfig();
	const filters = getJobFlowRuleFilters(serviceConfig);

	const [jobFlowRuleQuery] = useGetJobFlowRuleQuery({
		variables: {
			id: jobFlowRuleId,
		},
	});

	const rule = jobFlowRuleQuery.data?.jobFlowRule;
	const getEquipmentName = useEquipmentName();

	if (jobFlowRuleQuery.fetching || serviceConfigFetching) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (!rule) {
		return <JobFlowRule404 id={jobFlowRuleId} />;
	}

	if (jobFlowRuleQuery.error) {
		return <ErrorPage combinedError={jobFlowRuleQuery.error} />;
	}

	if (serviceConfigError) {
		return <Error message={serviceConfigError.message} />;
	}
	if (filters.length === 0) {
		return <Error message={messages.jfrFiltersMissing} />;
	}

	let editable = true;
	let criteria: JobFlowRuleCriteria | null = null;

	try {
		// Some JFRs cannot be edited in this UI!
		criteria = new JobFlowRuleCriteria(rule, filters as JobFlowRuleFilter[]);
	} catch (err) {
		logger.error({ err }, 'failed creating job flow criteria');

		// ... but we can show it anyway. Just mark it as "not editable"
		editable = false;
	}

	return (
		<Page
			fullWidth
			title={rule.ruleDescription}
			secondaryActions={[
				{
					content: messages.edit,
					disabled: !editable,
					url: routes.editJobFlowRule(rule.id),
				},
			]}
			backAction={backAction}
		>
			{!editable ? (
				// No rule for you! Link to Support page
				<>
					<Banner
						status="info"
						icon={CircleInformationMajor}
						title={messages.jobFlowRuleCannotEdit}
						action={{
							content: messages.goToSupport,
							url: routes.support(),
						}}
					>
						{messages.jobFlowRuleHelp}
					</Banner>
					<br />
				</>
			) : null}

			<LegacyCard title={messages.ruleProperties} sectioned>
				<CardDetails
					primary={[
						{ content: rule?.ruleDescription, label: messages.name },
						{ content: rule?.rule.job ? messages.job : messages.line, label: messages.ruleType },
						{
							content: rule?.ruleSubType.map(getEquipmentName).join(', '),
							label: messages.equipment,
						},
					]}
				/>
			</LegacyCard>

			<LegacyCard title={messages.ruleConditions} sectioned>
				{criteria ? (
					<table className={styles.detailTable}>
						<tbody>
							<tr>
								<td>{messages.jobFlowRuleExpressions.use}</td>
								<td>{rule?.ruleSubType.map(getEquipmentName).join(', ')}</td>
							</tr>
							{criteria.getConditions().map((c, i) => {
								const { suffix } = criteria!.getConditionConstraints(i);
								const join = criteria!.getJoin();

								return (
									<tr key={i}>
										<td>{messages.jobFlowRuleExpressions[i === 0 ? 'when' : join]}</td>
										<td>
											{c.attribute.startsWith('data.')
												? getFilterLabel(c.attribute)
												: messages.jobFlowRuleAttributes[c.attribute]}
										</td>
										<td>{messages.jobFlowRuleExpressions[c.operator]}</td>
										<td>
											{c.attribute === 'sourceLoc.mapChunkId'
												? getWorkAreaName(c.value.toString())
												: c.value.toString()}
											{suffix ? ' (' + suffix + ')' : null}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				) : (
					// If we can't edit the rule, we probably can't format it either. Show the raw data
					<pre>{JSON.stringify(rule.rule, null, 2)}</pre>
				)}
			</LegacyCard>
		</Page>
	);
}
