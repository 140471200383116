import { LegacyCard } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../../components/CardDetails';
import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';

interface Props {
	loading?: boolean;
	sortWall?: any;
}

export function SortWallBreakdown({ loading, sortWall }: Props) {
	const { messages, translate } = useLocalization();

	const totalUnits = sortWall ? sortWall.totalLineQuantities?.totalUnits : 0;
	// const sortationExceptions = sortWall ? sortWall.linesExceptionsCount?.count : 0;
	// const pickingExceptions = sortWall ? sortWall.pickingJobsExceptionsCount?.count : 0;

	return (
		<LegacyCard sectioned title={messages.breakdown}>
			<CardDetails
				loading={loading}
				primary={[
					{
						content: translate(messages.countUnits, {
							count: totalUnits,
						}),
						label: messages.totalUnits,
					},
					{
						content:
							sortWall?.totalLines.count || sortWall?.totalLines.count === 0 ? (
								translate(messages.countLines, {
									count: sortWall?.totalLines.count,
								})
							) : (
								<NoData />
							),
						label: messages.totalLines,
					},
					{
						content:
							sortWall?.servicing?.length || sortWall?.servicing?.length === 0 ? (
								translate(messages.countOrders, {
									count: sortWall?.servicing?.length,
								})
							) : (
								<NoData />
							),
						label: messages.totalOrders,
					},
					{
						content:
							sortWall?.servicedBy?.length || sortWall?.servicedBy?.length === 0 ? (
								sortWall?.servicedBy?.length
							) : (
								<NoData />
							),
						label: messages.pickingJobs,
					},
				]}
				// secondary={[
				// 	{
				// 		content: (
				// 			<Badge status={pickingExceptions > 0 ? 'critical' : undefined}>
				// 				{pickingExceptions.toString()}
				// 			</Badge>
				// 		),
				// 		label: messages.pickingExceptions,
				// 	},
				// 	{
				// 		content: (
				// 			<Badge status={sortationExceptions > 0 ? 'critical' : undefined}>
				// 				{sortationExceptions.toString()}
				// 			</Badge>
				// 		),
				// 		label: messages.sortationExceptions,
				// 	},
				// ]}
			/>
		</LegacyCard>
	);
}
