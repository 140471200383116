import { UserRole } from '@sixriver/react-support';

import { Employee } from './Employee';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function EmployeeRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<Employee />
		</AuthorizedRoute>
	);
}
