import { useFetch } from '@hyper-fetch/react';
import { ServiceConfig } from '@sixriver/config-schema';
import { createContext } from 'react';

import { getServiceConfig, GlobalErrorType } from '../../api';

interface ServiceConfigProviderContext {
	serviceConfig: ServiceConfig | null;
	error?: GlobalErrorType | null;
	fetching: boolean;
	refetch: () => void;
}

export const ServiceConfigContext = createContext<ServiceConfigProviderContext>({
	error: null,
	fetching: false,
	refetch: () => {},
	serviceConfig: null,
});

export function ServiceConfigProvider({ children }: { children: React.ReactNode }) {
	const { loading, data, error, refetch } = useFetch(getServiceConfig);

	const serviceConfig = data?.config ?? null;

	return (
		<ServiceConfigContext.Provider
			value={{
				error,
				fetching: loading,
				refetch,
				serviceConfig,
			}}
		>
			{children}
		</ServiceConfigContext.Provider>
	);
}
