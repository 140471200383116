import { useDebouncedValue } from '@shopify/react-hooks';
import {
	useIndexResourceState,
	IndexTableSelectionType,
	Scrollable,
} from '@sixriver/lighthouse-web-community';
import { useQueryState } from '@sixriver/react-support';
import type { PaginationArguments } from '@sixriver/typescript-support';
import { allSettledAndThrow } from '@sixriver/typescript-support';
import clsx from 'clsx';
import { groupBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { AttemptsLog } from './AttemptsLog';
import styles from './DeliveryLogPage.module.css';
import { DeliveryTable } from './DeliveryTable';
import { useCancelDeliveryMutation } from './graphql/CancelDelivery.edge-graphql';
import { DeliveryFragment, useGetDeliveriesQuery } from './graphql/GetDeliveries.edge-graphql';
import { useReplayMessagesMutation } from './graphql/ReplayMessages.edge-graphql';
import { SvelteJsonEditor } from './shared';
import { MessageDeliveryFilter, SortDirection } from '../../api/edge/types';
import { logger } from '../../utils';
import { useResolveMessagePayloadQuery } from './graphql/ResolveMessagePayload.edge-graphql';
import { useLocalization } from '../../hooks/useLocalization';

// NOTE: this is a hack to get divs to look like Polaris Cards (Cards don't allow setting the style as needed
//       for the layout here, Polaris probably isn't really meant for this type of layout - perhaps we should
//       redesign at some point?)
// NOTE: this works in Polaris 10.5.0
const polarisCardClassName = 'Polaris-Card';

const debounceWaitMs = 300;

const defaultSortDirection = SortDirection.Desc;

const defaultFilter = {};

const defaultPageSize = 20;
const defaultPaginationArgs = {
	first: defaultPageSize,
};

const defaultSearchParams: SearchParams = {
	cursor: defaultPaginationArgs,
	filter: defaultFilter,
	selected: [],
	selectedRow: undefined,
	sortDirection: defaultSortDirection,
};

export function DeliveryLogPage(): JSX.Element {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_replayMessageMutation, executeReplayMessageMutation] = useReplayMessagesMutation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_cancelDeliveryMutation, executeCancelDeliveryMutation] = useCancelDeliveryMutation();

	const [searchParams, setSearchParams] = useQueryState<SearchParams>(
		'search',
		defaultSearchParams,
		parseSearchParams,
		serializeSearchParams,
	);
	const debouncedDeliveryFilter = useDebouncedValue(searchParams.filter, {
		timeoutMs: debounceWaitMs,
	});

	const setDeliveryFilter = useCallback(
		(filter: MessageDeliveryFilter | undefined) => {
			setSearchParams({
				...searchParams,
				cursor: defaultPaginationArgs,
				filter: normalizeFilter(filter) ?? defaultFilter,
				selected: [],
				selectedRow: undefined,
			});
		},
		[searchParams, setSearchParams],
	);

	const setDeliverySortDirection = useCallback(
		(sortDirection: SortDirection) => {
			setSearchParams({
				...searchParams,
				cursor: defaultPaginationArgs,
				sortDirection,
			});
		},
		[searchParams, setSearchParams],
	);

	const setSelectedRowId = useCallback(
		(selectedRow: string | undefined) => {
			setSearchParams({
				...searchParams,
				selectedRow,
			});
		},
		[searchParams, setSearchParams],
	);

	const setPaginationArguments = useCallback(
		(cursor: PaginationArguments) => setSearchParams({ ...searchParams, cursor }),
		[searchParams, setSearchParams],
	);

	const [getDeliveriesQuery, executeGetDeliveriesQuery] = useGetDeliveriesQuery({
		variables: {
			...searchParams.cursor,
			filter: debouncedDeliveryFilter,
			orderDirection: searchParams.sortDirection ?? defaultSortDirection,
		},
	});

	const deliveriesTableData = useMemo(() => {
		const pageData = getDeliveriesQuery?.data?.getDeliveries.edges.map((edge) => {
			return { cursor: edge.cursor, ...edge.node };
		});
		const pageInfo = getDeliveriesQuery?.data?.getDeliveries.pageInfo;
		return {
			data: pageData ?? [],
			pageInfo: {
				endCursor: pageInfo?.endCursor ?? null,
				hasNextPage: pageInfo?.hasNextPage,
				hasPreviousPage: pageInfo?.hasPreviousPage,
				startCursor: pageInfo?.startCursor ?? null,
			},
		};
	}, [
		getDeliveriesQuery?.data?.getDeliveries.edges,
		getDeliveriesQuery?.data?.getDeliveries.pageInfo,
	]);

	const {
		selectedResources: selectedDeliveries,
		allResourcesSelected: allDeliveriesSelected,
		handleSelectionChange: handleDeliverySelectionChange,
		clearSelection: clearDeliverySelection,
		// NOTE: converting the MessageDelivery interface to a type here to workaround the below TS behavior
		// SEE: https://github.com/microsoft/TypeScript/issues/15300
	} = useIndexResourceState(deliveriesTableData.data);

	// NOTE: hacky sync of selected deliveries to URL because Polaris IndexTable is ... awkward
	// TODO: hopefully can remove this if/when we switch to a new framework
	const setSelectedSearchParm = useCallback(
		(selected: string[]) => setSearchParams({ ...searchParams, selected }),
		[searchParams, setSearchParams],
	);
	const [selectedSync, setSelectedSync] = useState<string[]>([]);
	useEffect(() => {
		const selectedQueryParamStr = searchParams.selected.join(',');
		const selectedSyncStr = selectedSync.join(',');
		const polarisSelectedStr = selectedDeliveries.join(',');
		if (new Set([selectedQueryParamStr, selectedSyncStr, polarisSelectedStr]).size === 1) {
			// all match, nothing to do
			return;
		}
		unstable_batchedUpdates(() => {
			if (selectedQueryParamStr !== selectedSyncStr) {
				// sync check failed; need to sync from URL
				setSelectedSync(searchParams.selected);
				clearDeliverySelection();
				for (const id of searchParams.selected) {
					handleDeliverySelectionChange(IndexTableSelectionType.Single, true, id);
				}
			} else {
				// URL is in sync; must be an update from polaris
				setSelectedSearchParm(selectedDeliveries);
				setSelectedSync(selectedDeliveries);
			}
		});
	}, [
		clearDeliverySelection,
		handleDeliverySelectionChange,
		searchParams.selected,
		selectedDeliveries,
		selectedSync,
		setSelectedSearchParm,
	]);

	const singleSelectedDeliveryEntry = useMemo(
		() => deliveriesTableData?.data?.find((d) => d?.id === searchParams.selectedRow),
		[deliveriesTableData?.data, searchParams.selectedRow],
	);

	const [resolvePayloadQuery] = useResolveMessagePayloadQuery({
		pause: !singleSelectedDeliveryEntry,
		variables: {
			messageId: singleSelectedDeliveryEntry?.message.id ?? '',
		},
	});

	const { messages } = useLocalization();

	const payloadContent = useMemo(() => {
		if (!singleSelectedDeliveryEntry || resolvePayloadQuery.fetching) {
			return (
				<div
					className={clsx(
						styles.deliveryLogPageJsonEditor,
						styles.deliveryLogPageJsonEditorNoData,
						polarisCardClassName,
					)}
				>
					{resolvePayloadQuery.fetching ? messages.loading : messages.selectEntryToViewPayload}
				</div>
			);
		}
		return (
			<SvelteJsonEditor
				className={clsx(styles.deliveryLogPageJsonEditor, polarisCardClassName)}
				content={resolvePayloadQuery?.data?.getMessage?.resolvedPayload}
			/>
		);
	}, [
		messages.loading,
		messages.selectEntryToViewPayload,
		resolvePayloadQuery?.data?.getMessage?.resolvedPayload,
		resolvePayloadQuery.fetching,
		singleSelectedDeliveryEntry,
	]);

	// TODO: better error handling
	if (getDeliveriesQuery.error) {
		return <div>{getDeliveriesQuery.error.message || messages.unknownError}</div>;
	}
	if (resolvePayloadQuery.error) {
		return <div>{resolvePayloadQuery.error.message || messages.unknownError}</div>;
	}

	return (
		<div className={styles.deliveryLogPage}>
			<DeliveryTable
				className={clsx(styles.deliveryLogPageDeliveryTable, polarisCardClassName)}
				tableData={deliveriesTableData}
				onNext={() =>
					setPaginationArguments({
						after: deliveriesTableData.pageInfo.endCursor,
						before: undefined,
						first: defaultPageSize,
						last: undefined,
					})
				}
				onPrevious={() =>
					setPaginationArguments({
						after: undefined,
						before: deliveriesTableData.pageInfo.startCursor,
						first: undefined,
						last: defaultPageSize,
					})
				}
				selectedRow={searchParams.selectedRow}
				onSelectedRowChanged={setSelectedRowId}
				selectedResources={[...selectedDeliveries]}
				allResourcesSelected={allDeliveriesSelected}
				onSelectionChange={handleDeliverySelectionChange}
				onCancel={handleCancelDelivery}
				onReplay={handleReplayDelivery}
				filter={searchParams.filter}
				onFilterChanged={setDeliveryFilter}
				sortDirection={searchParams.sortDirection ?? defaultSortDirection}
				onSortDirectionChanged={setDeliverySortDirection}
				loading={getDeliveriesQuery.fetching}
			/>
			<div className={styles.deliveryLogPageRightBar}>
				{payloadContent}
				<Scrollable
					style={{
						gridColumnEnd: 1,
						gridColumnStart: 1,
						gridRowEnd: 2,
						gridRowStart: 2,
					}}
				>
					<AttemptsLog
						className={clsx(styles.deliveryLogPageAttemptsLog, polarisCardClassName)}
						deliveryIds={searchParams.selectedRow ? [searchParams.selectedRow] : []}
						pageSize={defaultPageSize}
						debounceWaitMs={debounceWaitMs}
					/>
				</Scrollable>
			</div>
		</div>
	);

	async function handleCancelDelivery(selectedNodes: 'All' | DeliveryFragment[]) {
		try {
			if (selectedNodes === 'All') {
				// TODO: we'll need to either support a filtered cancel server-side, or fetch all (via pagination) and
				//       cancel one by one
				throw new Error('not implemented yet');
			}
			// TODO: server should support bulk operations
			await allSettledAndThrow(
				selectedNodes.map(async (node) => {
					await executeCancelDeliveryMutation({
						deliveryId: node.id,
						orderingKey: node.message.orderingKey,
					});
				}),
			);

			await executeGetDeliveriesQuery();
		} catch (err) {
			// TODO: better success/error handling...
			logger.error({ err }, 'failed to cancel deliveries');
		}
	}

	async function handleReplayDelivery(selectedNodes: 'All' | DeliveryFragment[]) {
		if (selectedNodes === 'All') {
			// TODO: we'll need to either support a filtered cancel server-side, or fetch all (via pagination) and
			//       replay "group" by "group"
			throw new Error('not implemented yet');
		}
		// TODO: server should support bulk operations
		try {
			const groups = groupBy(selectedNodes, (n) => n.message.orderingKey);
			await allSettledAndThrow(
				Object.entries(groups).map(async ([orderingKey, messages]) => {
					await executeReplayMessageMutation({
						messageIds: messages.map((m) => m.id),
						orderingKey,
					});
				}),
			);

			await executeGetDeliveriesQuery();
		} catch (err) {
			// TODO: better success/error handling...
			logger.error({ err }, 'failed to replay deliveries');
		}
	}
}

interface SearchParams {
	filter: MessageDeliveryFilter;
	sortDirection: SortDirection;
	cursor: PaginationArguments;
	selected: string[];
	selectedRow: string | undefined;
}

function parseSearchParams(rawValue: string): SearchParams {
	if (!rawValue) {
		return defaultSearchParams;
	}
	const parsed: SearchParams = JSON.parse(rawValue);
	return {
		cursor: parsed.cursor ?? defaultPaginationArgs,
		filter: normalizeFilter(parsed.filter) ?? defaultFilter,
		selected: parsed.selected ?? [],
		selectedRow: parsed.selectedRow,
		sortDirection: parsed.sortDirection ?? defaultSortDirection,
	};
}

function serializeSearchParams(value: SearchParams): string {
	return JSON.stringify(value);
}

function normalizeFilter(
	filter: MessageDeliveryFilter | undefined,
): MessageDeliveryFilter | undefined {
	function cleanObject(obj: object | undefined | null): object | undefined {
		if (!obj) {
			return undefined;
		}
		const clone = { ...obj };
		// eslint-disable-next-line prefer-const
		for (let [key, value] of Object.entries(clone)) {
			if (typeof value === 'object') {
				value = cleanObject(value);
			}
			if (value === undefined) {
				delete (clone as any)[key];
			}
		}
		if (Object.keys(clone).length === 0) {
			return undefined;
		}
		return clone;
	}

	return cleanObject(filter);
}
