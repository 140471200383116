import { ArrowDownMinor } from '@sixriver/lighthouse-icons';
import {
	Button,
	LegacyCard,
	Form,
	Layout,
	Page,
	LegacyStack,
	TextField,
	Banner,
	Icon,
} from '@sixriver/lighthouse-web-community';
import { useSiteName } from '@sixriver/react-support';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApkDownloadModal } from './ApkDownloadModal';
import { ScanQrCodeModal } from './ScanQrCodeModal';
import { useLocalization } from '../../../hooks/useLocalization';
import { logger } from '../../../utils';

const makePrefix = (name: string) => `mfa-${name}-`;

export function NewMfa(): JSX.Element {
	const navigate = useNavigate();
	const [scanCodeOpen, setScanCodeOpen] = useState(false);
	const [downloadModalOpen, setDownloadModalOpen] = useState(false);
	const { messages, translate } = useLocalization();
	const siteName = useSiteName();
	const [name, setName] = useState(makePrefix(siteName));
	const [notes, setNotes] = useState('');
	const [creating, setCreating] = useState(false);
	const [setupCode, setSetupCode] = useState<string>('');
	const [expiresAt, setExpiresAt] = useState<Date | null>(null);

	const onSubmit = useCallback(async () => {
		setCreating(true);

		try {
			const res = await fetch(`${import.meta.env.VITE_GATEKEEPER_API_URL}/auth/setup-codes`, {
				body: JSON.stringify({ name, notes }),
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'POST',
			}).then((res) => res.json());

			setSetupCode(res.id);
			setExpiresAt(new Date(res.expiresAt));

			setCreating(false);
			setScanCodeOpen(true);
		} catch (err) {
			logger.error({ err }, 'failed setting up auth codes');
		}
	}, [name, notes]);

	const nameError = useMemo(() => {
		// Must start with the prefix
		if (!name.startsWith(makePrefix(siteName))) {
			return true;
		}

		// Must not be the prefix
		if (name === makePrefix(siteName)) {
			return true;
		}

		// Must only be lowercase alphanumeric and dashes
		if (!/^[a-z0-9-]+$/.test(name)) {
			return true;
		}

		return false;
	}, [siteName, name]);

	return (
		<Page
			title={messages.newMfa.title}
			backAction={{ content: messages.newMfa.previousPage, url: '/mfas' }}
		>
			<ScanQrCodeModal
				open={scanCodeOpen}
				onDismiss={() => {
					navigate('/mfas');
				}}
				mfaName={name}
				id={setupCode}
				siteName={siteName}
				expiresAt={expiresAt}
			/>
			<ApkDownloadModal open={downloadModalOpen} onDismiss={() => setDownloadModalOpen(false)} />
			<Layout>
				<Layout.Section>
					<LegacyStack distribution="trailing">
						<Button
							plain
							monochrome
							removeUnderline
							icon={<Icon source={ArrowDownMinor} />}
							onClick={() => setDownloadModalOpen(true)}
						>
							{messages.newMfa.downloadModal.openButton}
						</Button>
					</LegacyStack>
				</Layout.Section>
				<Layout.Section>
					<LegacyCard title={messages.newMfa.details.title}>
						<LegacyCard.Section>
							<Form onSubmit={onSubmit}>
								<LegacyStack vertical>
									<LegacyStack.Item>
										<TextField
											label={messages.newMfa.details.name.label}
											value={name}
											onChange={(val) => setName(val.toLowerCase())}
											autoComplete="off"
											maxLength={36}
											requiredIndicator
											showCharacterCount
											helpText={translate(messages.newMfa.details.name.helpText, {
												prefix: makePrefix(siteName),
											})}
											error={nameError}
										/>
									</LegacyStack.Item>
									<LegacyStack.Item>
										<TextField
											label={messages.newMfa.details.notes.label}
											value={notes}
											onChange={setNotes}
											autoComplete="off"
											maxLength={250}
											showCharacterCount
											helpText={messages.newMfa.details.notes.helpText}
										/>
									</LegacyStack.Item>
									<LegacyStack.Item>
										<Banner status="warning">{messages.newMfa.details.limitation}</Banner>
									</LegacyStack.Item>
									<LegacyStack.Item>
										<Button primary submit loading={creating} disabled={nameError}>
											{messages.newMfa.details.submit}
										</Button>
									</LegacyStack.Item>
								</LegacyStack>
							</Form>
						</LegacyCard.Section>
					</LegacyCard>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
