import {
	Button,
	LegacyCard,
	ChoiceList,
	Page,
	Popover,
	LegacyStack,
	Layout,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useRef, useState } from 'react';

import { CutoffStat } from './CutoffStat';
import styles from './FulfillmentTracker.module.css';
import {
	GetOrderStatsDocument,
	GetOrderStatsQuery,
	GetOrderStatsQueryVariables,
} from './graphql/GetOrderStats.w-api-graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useNumberQueryState, useQueryState } from '../../hooks/useQueryState';

export const timeframeRanges: [number, number, string][] = [
	[-12, 12, '+/-'],
	[-24, 24, '+/-'],
	[-12, 0, 'past'],
	[-24, 0, 'past'],
	[-48, 0, 'past'],
	[-72, 0, 'past'],
	[-168, 0, 'past'],
	[-336, 0, 'past'],
];

export function calculateFromAndTo(fromHours: number, toHours: number): [Date, Date] {
	const from = new Date();
	const to = new Date();
	from.setHours(from.getHours() + fromHours);
	to.setHours(to.getHours() + toHours);
	return [from, to];
}

type DimensionStats = {
	completed: number;
	inProgress: number;
	late: number;
	total: number;
	unassigned: number;
};
const defaultStats: DimensionStats = {
	completed: 0,
	inProgress: 0,
	late: 0,
	total: 0,
	unassigned: 0,
};

export function FulfillmentTracker() {
	const { messages, translate } = useLocalization();
	const [selectedRange, setSelectedRange] = useNumberQueryState('range', 0);
	const [expectedShipDateFrom, setExpectedShipDateFrom] = useState(new Date());
	const [expectedShipDateTo, setExpectedShipDateTo] = useState(new Date());
	const [selectedDimension, setSelectedDimension] = useQueryState('dimension', 'order');
	const [dimensionMenuOpen, setDimensionMenuOpen] = useState(false);
	const [rangeMenuOpen, setRangeMenuOpen] = useState(false);
	const ranges: [number, number, string][] = timeframeRanges.map((x) => [
		x[0],
		x[1],
		Math.abs(x[0]) <= 72
			? `${x[2]} ${translate(messages.countHours, { count: Math.abs(x[0]) })}`
			: `${x[2]} ${translate(messages.countDays, { count: Math.abs(x[0]) / 24 })}`,
	]);
	const [getOrderStatsQuery] = usePollingQuery<GetOrderStatsQuery, GetOrderStatsQueryVariables>({
		pollInterval: 1800000,
		query: GetOrderStatsDocument,
		variables: {
			expectedShipDateFrom,
			expectedShipDateTo,
		},
	});

	const stats = useRef<DimensionStats>({
		completed: 0,
		inProgress: 0,
		late: 0,
		total: 0,
		unassigned: 0,
	});

	useEffect(() => {
		if (getOrderStatsQuery.fetching || !getOrderStatsQuery.data) {
			return;
		}
		stats.current = {
			...defaultStats,
			...getStatsFromDimension(getOrderStatsQuery.data, selectedDimension),
		};
	}, [getOrderStatsQuery.data, getOrderStatsQuery.fetching, selectedDimension]);

	useEffect(() => {
		const [fromHours, toHours] = timeframeRanges[selectedRange];
		const [from, to] = calculateFromAndTo(fromHours, toHours);
		setExpectedShipDateFrom(from);
		setExpectedShipDateTo(to);
	}, [selectedRange]);

	const handleRangeSelectionChanged = useCallback(
		(selectedRangeOption: number) => {
			setSelectedRange(selectedRangeOption);
		},
		[setSelectedRange],
	);

	return (
		<Page fullWidth title={messages.fulfillmentTracker}>
			<LegacyStack>
				<Popover
					activator={
						<Button onClick={() => setRangeMenuOpen(!rangeMenuOpen)} disclosure>
							{(ranges[selectedRange] as any)[2]}
						</Button>
					}
					active={rangeMenuOpen}
					onClose={() => setRangeMenuOpen(false)}
					sectioned
					preferredAlignment="left"
				>
					<ChoiceList
						title={messages.timeframe}
						choices={ranges.map((range, idx) => {
							return { label: range[2], value: idx.toString() };
						})}
						selected={[selectedRange.toString()]}
						onChange={(selected) => handleRangeSelectionChanged(parseInt(selected[0]))}
					/>
				</Popover>
				<Popover
					activator={
						<Button onClick={() => setDimensionMenuOpen(!dimensionMenuOpen)} disclosure>
							{
								(
									{
										line: messages.lines,
										order: messages.orders,
										unit: messages.units,
									} as any
								)[selectedDimension]
							}
						</Button>
					}
					active={dimensionMenuOpen}
					onClose={() => setDimensionMenuOpen(false)}
					sectioned
					preferredAlignment="left"
				>
					<ChoiceList
						title=""
						titleHidden
						choices={[
							{ label: messages.orders, value: 'order' },
							{ label: messages.lines, value: 'line' },
							{ label: messages.units, value: 'unit' },
						]}
						selected={[selectedDimension]}
						onChange={(selected) => setSelectedDimension(selected[0])}
					/>
				</Popover>
			</LegacyStack>
			<br />

			<Layout>
				<Layout.Section fullWidth>
					<LegacyCard sectioned>
						<div className={styles.stats}>
							<LegacyStack distribution="fill">
								<CutoffStat
									label={
										(
											{
												line: messages.totalLines,
												order: messages.totalOrders,
												unit: messages.totalUnits,
											} as any
										)[selectedDimension]
									}
									value={stats.current.total}
								/>
								<CutoffStat label={messages.unassigned} value={stats.current.unassigned} />
								<CutoffStat label={messages.inProgress} value={stats.current.inProgress} />
								<CutoffStat label={messages.completed} value={stats.current.completed} />
								<CutoffStat label={messages.late} value={stats.current.late} warn={true} />
							</LegacyStack>
						</div>
						{/* <div className={styles.scroll}>
							<table className={styles.cutoffs}>
								<thead>
									<tr>
										<th>{messages.carrierCutoff}</th>
										<th>{messages.all}</th>
										<th>{messages.unassigned}</th>
										<th>{messages.assignedToWall}</th>
										<th>{messages.inProgress}</th>
										<th>{messages.completed}</th>
										<th>{messages.exceptions}</th>
										<th>{messages.interrupted}</th>
									</tr>
								</thead>
								<tbody>
									{rows.map((row) => {
										return (
											<tr key={row.key} data-is-past={row.isPast} data-is-next={row.isNext}>
												<td>
													<CutoffTime
														expectedShipDate={row.expectedShipDate}
														isToday={row.isToday}
													/>
													<CutoffIcon
														isPast={row.isPast}
														isLooming={row.isLooming}
														isDone={row.isDone}
													/>
													<div className={styles.carrierName}>{row.carrierName}</div>
												</td>
												<td>{formatNumber(row.total)}</td>
												<td>
													{
														row.statusStats.find(
															(statusStat) => statusStat.key === JobStatus.Unassigned,
														)?.total
													}
												</td>
												<td>
													{
														row.statusStats.find(
															(statusStat) => statusStat.key === JobStatus.AssignedToSortWall,
														)?.total
													}
												</td>
												<td>
													{
														row.statusStats.find((statusStat) => statusStat.key === 'InProgress')
															?.total
													}
												</td>
												<td>
													{row.statusStats.find((statusStat) => statusStat.key === 'Done')?.total}
												</td>
												<td>-</td>
												<td>-</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div> */}
					</LegacyCard>
				</Layout.Section>
				{/*
				<Layout.Section oneHalf>
					<PickingByEquipment></PickingByEquipment>
				</Layout.Section> */}
				{/* <Layout.Section oneHalf></Layout.Section> */}
			</Layout>
		</Page>
	);
}

function getStatsFromDimension(data: GetOrderStatsQuery, dimension: string) {
	if (dimension === 'order') {
		return data.orderStats.statusStats.reduce(
			(stats, statusStat) => {
				if (statusStat.key === 'AssignedToSortWall') {
					return {
						...stats,
						assignedToSortWall: statusStat.total,
					};
				}

				if (statusStat.key === 'Done') {
					return {
						...stats,
						completed: statusStat.total,
					};
				}

				if (statusStat.key === 'InProgress') {
					return {
						...stats,
						inProgress: statusStat.total,
					};
				}

				if (statusStat.key === 'Packed') {
					return {
						...stats,
						packed: statusStat.total,
					};
				}

				if (statusStat.key === 'Picked') {
					return {
						...stats,
						picked: statusStat.total,
					};
				}

				if (statusStat.key === 'Sorted') {
					return {
						...stats,
						sorted: statusStat.total,
					};
				}

				if (statusStat.key === 'Unassigned') {
					return {
						...stats,
						unassigned: statusStat.total,
					};
				}

				return stats;
			},
			{ late: data.orderStats.lateStats.total, total: data.orderStats.total },
		);
	}

	if (dimension === 'line') {
		return data.orderStats.statusStats.reduce(
			(stats, statusStat) => {
				if (statusStat.key === 'AssignedToSortWall') {
					return {
						...stats,
						assignedToSortWall: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'Done') {
					return {
						...stats,
						completed: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'InProgress') {
					return {
						...stats,
						inProgress: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'Packed') {
					return {
						...stats,
						packed: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'Picked') {
					return {
						...stats,
						picked: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'Sorted') {
					return {
						...stats,
						sorted: statusStat.lineStats.total,
					};
				}

				if (statusStat.key === 'Unassigned') {
					return {
						...stats,
						unassigned: statusStat.lineStats.total,
					};
				}

				return stats;
			},
			{ late: data.orderStats.lateStats.lineStats.total, total: data.orderStats.lineStats.total },
		);
	}

	if (dimension === 'unit') {
		return data.orderStats.statusStats.reduce(
			(stats, statusStat) => {
				if (statusStat.key === 'AssignedToSortWall') {
					return {
						...stats,
						assignedToSortWall: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'Done') {
					return {
						...stats,
						completed: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'InProgress') {
					return {
						...stats,
						inProgress: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'Packed') {
					return {
						...stats,
						packed: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'Picked') {
					return {
						...stats,
						picked: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'Sorted') {
					return {
						...stats,
						sorted: statusStat.lineStats.totalQuantity,
					};
				}

				if (statusStat.key === 'Unassigned') {
					return {
						...stats,
						unassigned: statusStat.lineStats.totalQuantity,
					};
				}

				return stats;
			},
			{
				late: data.orderStats.lateStats.lineStats.totalQuantity,
				total: data.orderStats.lineStats.totalQuantity,
			},
		);
	}

	return {
		completed: 0,
		inProgress: 0,
		late: 0,
		total: 0,
		unassigned: 0,
	};
}
