import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface PutawayJob404Props {
	id: string;
}

export function PutawayJob404({ id }: PutawayJob404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.putawayJob404.title}
			message={translate(messages.putawayJob404.message, { id })}
			action={{
				content: messages.putawayJob404.action,
				url: routes.putawayJobs(),
			}}
		/>
	);
}
