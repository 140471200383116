import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Exception404Props {
	id: string;
}

export function Exception404({ id }: Exception404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.exception404.title}
			message={translate(messages.exception404.message, { id })}
			action={{
				content: messages.exception404.action,
				url: routes.exceptions(),
			}}
		/>
	);
}
