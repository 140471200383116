import { EffectCallback, useEffect } from 'react';

/**
 * Runs code once, on component mount
 */
export function useMount(callback: EffectCallback) {
	useEffect(() => {
		callback();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
}
