import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Container404Props {
	id: string;
}

export function Container404({ id }: Container404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.container404.title}
			message={translate(messages.container404.message, { id })}
			action={{
				content: messages.container404.action,
				url: routes.containers(),
			}}
		/>
	);
}
