import { UserRole } from '@sixriver/react-support';

import { Settings } from './Settings';
import { ConfigServiceProvider } from '../../providers';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';
import { FeatureFlaggedRoute } from '../../routeGuards/FeatureFlaggedRoute';

export function SettingsRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}>
			<FeatureFlaggedRoute flag="SETTINGS_PAGE">
				<ConfigServiceProvider>
					<Settings />
				</ConfigServiceProvider>
			</FeatureFlaggedRoute>
		</AuthorizedRoute>
	);
}
