import { useLocalization } from '../../hooks/useLocalization';
import { logger } from '../../utils';
import { useToast } from '../useToast';

export const useCopyToClipboard = () => {
	const { messages } = useLocalization();
	const { showToast } = useToast();

	return {
		copyToClipboard: async (textToCopy?: string | null) => {
			if (textToCopy) {
				try {
					await navigator.clipboard.writeText(textToCopy);
					showToast(messages.copiedToClipboard);
				} catch (err) {
					logger.error({ err }, 'failed copying text to clipboard');

					showToast(messages.copyFailed, true);
				}
			}
		},
	};
};
