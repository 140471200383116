import {
	FormLayout,
	Form,
	Button,
	Layout,
	Banner,
	Page,
	LegacyCard,
	TextField,
	Link,
	Heading,
} from '@sixriver/lighthouse-web-community';
import { useAuth } from '@sixriver/react-support';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './Login.module.css';
import { redirectUserAfterLogin } from './redirect-user-after-login';
import sixrslogo from './sixrslogo.png';
import { useServiceConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { logger } from '../../utils';

export function Login(): JSX.Element {
	const { messages } = useLocalization();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { login } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [error, setError] = useState<string | undefined>(undefined);
	const { refetch } = useServiceConfig();

	const onSubmit = async () => {
		setError(undefined);

		try {
			await login(username, password);
			redirectUserAfterLogin(navigate, location);
		} catch (err: any) {
			logger.error({ err }, 'failed to log in');

			setError(err.message as string);
		}
	};

	// When the page is left, refetch the service config
	useEffect(() => {
		return () => {
			refetch();
		};
		// Intionally leaving empty to only run once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page>
			<Layout>
				<div className={styles.container}>
					<LegacyCard sectioned>
						<Form onSubmit={() => void onSubmit()}>
							<FormLayout>
								<img src={sixrslogo} className={styles.logo} alt="6 River Systems" />
								<Heading element="h1">{messages.signInToTheBridge}</Heading>
								{error ? <Banner status="critical">{error}</Banner> : null}
								<TextField
									autoComplete="on"
									name="username"
									label={messages.email}
									value={username}
									onChange={setUsername}
								/>
								<TextField
									autoComplete="current-password"
									name="password"
									type="password"
									label={messages.password}
									value={password}
									onChange={setPassword}
								/>
								<Button submit primary>
									{messages.signIn}
								</Button>
								<Link removeUnderline url={routes.forgotPassword() + '?email=' + username}>
									{messages.forgotPassword}
								</Link>
							</FormLayout>
						</Form>
					</LegacyCard>
					<div className={styles.version}>
						<small>{APP_VERSION}</small>
					</div>
				</div>
			</Layout>
		</Page>
	);
}
