import { CirclePlusMajor } from '@sixriver/lighthouse-icons';
import { TextStyle, LegacyStack, Tag, Autocomplete } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	label?: string;
	placeholder?: string;
	popularTags: string[];
	availableOptions: Array<{
		value: string;
		label: string;
	}>;
	selectedOptions: string[];
	allowNew?: boolean;
	isLoading?: boolean;
	selectedTagsPlacement?: 'above' | 'below';
	onSelectedOptionsChange: (selected: string[]) => void;
	onNewTag?: (newTag: string) => Promise<void> | void;
	onRemoveTag?: (removedTagName: string) => Promise<void> | void;
}

export function AutocompleteTags({
	availableOptions = [],
	popularTags = [],
	selectedOptions,
	label,
	placeholder,
	allowNew,
	isLoading,
	selectedTagsPlacement = 'above',
	onSelectedOptionsChange,
	onNewTag,
	onRemoveTag,
}: Props) {
	const { messages } = useLocalization();

	// State
	const [options, setOptions] = useState(availableOptions);
	const [inputValue, setInputValue] = useState('');
	const [inputHasFocus, setInputHasFocus] = useState(false);

	// Computed
	const trimmedInputValue = cleanUpTagTextInput(inputValue);
	const isInputValueUnique = useMemo(
		() => !availableOptions.map((opt) => opt.value).includes(trimmedInputValue),
		[availableOptions, trimmedInputValue],
	);

	const unusedPopularTags = popularTags
		.filter((tag) => !(selectedOptions || []).includes(tag))
		.map((tag) => {
			return (
				<Tag key={`popular-tag-${tag}`} onClick={() => onClickPopularTag(tag)}>
					{tag}
				</Tag>
			);
		});

	const addNewTagAction =
		allowNew && trimmedInputValue.length && isInputValueUnique
			? {
					content: `Add "${trimmedInputValue}"`,
					icon: CirclePlusMajor,
					onAction: () => {
						onNewTag?.(trimmedInputValue);
						setInputValue('');
					},
			  }
			: undefined;

	// Methods
	const handleTagEntry = useCallback(async () => {
		const trimmedTag = cleanUpTagTextInput(inputValue);

		const isTrimmedTagUnique = !availableOptions.map((opt) => opt.value).includes(trimmedTag);

		if (isTrimmedTagUnique && onNewTag) {
			await onNewTag(trimmedTag);
		}

		onSelectedOptionsChange(Array.from(new Set([...selectedOptions, trimmedTag])));

		setInputValue('');
	}, [availableOptions, inputValue, onNewTag, onSelectedOptionsChange, selectedOptions]);

	const updateText = useCallback(
		async (value: string) => {
			if (value.includes(',')) {
				setOptions(availableOptions);
				await handleTagEntry();
				return;
			}

			setInputValue(value);

			if (value === '') {
				setOptions(availableOptions);
				return;
			}

			const resultOptions = availableOptions.filter((option) =>
				option.label.toLowerCase().includes(cleanUpTagTextInput(value)),
			);

			setOptions(resultOptions);
		},
		[availableOptions, handleTagEntry],
	);

	const removeTag = useCallback(
		(tag: string) => () => {
			const options = [...selectedOptions];
			options.splice(options.indexOf(tag), 1);
			onSelectedOptionsChange(options);
			onRemoveTag?.(tag);
		},
		[onRemoveTag, onSelectedOptionsChange, selectedOptions],
	);

	const onClickPopularTag = useCallback(
		(tag: string) => {
			onSelectedOptionsChange(Array.from(new Set([...selectedOptions, tag])));
		},
		[onSelectedOptionsChange, selectedOptions],
	);

	const handleKeyDownEvent = useCallback(
		(evt: KeyboardEvent) => {
			async function handle() {
				if (evt.code === 'Enter') {
					evt.preventDefault();
					if (inputHasFocus && inputValue.trim().length > 0) {
						await handleTagEntry();
						return;
					}
				}
			}

			handle();
		},
		[inputHasFocus, handleTagEntry, inputValue],
	);

	// Effects
	useEffect(() => {
		document.addEventListener('keydown', handleKeyDownEvent);
		return () => {
			document.removeEventListener('keydown', handleKeyDownEvent);
		};
	}, [handleKeyDownEvent]);

	// Markup
	const tagsMarkup = selectedOptions.map((option) => {
		return (
			<Tag key={`option${option}`} onRemove={removeTag(option)}>
				{option}
			</Tag>
		);
	});

	// Render
	return (
		<div>
			{selectedTagsPlacement === 'above' && tagsMarkup.length ? (
				<>
					<LegacyStack>{tagsMarkup}</LegacyStack>
					<br />
				</>
			) : null}

			{unusedPopularTags?.length > 0 ? (
				<>
					<TextStyle>{messages.frequentlyUsedTags}:</TextStyle>

					<br />
					<br />

					<LegacyStack>{unusedPopularTags}</LegacyStack>
					<br />
				</>
			) : null}

			<Autocomplete
				allowMultiple
				options={options}
				selected={selectedOptions}
				actionBefore={addNewTagAction}
				preferredPosition="above"
				textField={
					<Autocomplete.TextField
						autoComplete="off"
						onChange={(value) => void updateText(value)}
						label={label}
						value={inputValue}
						placeholder={placeholder}
						onFocus={() => setInputHasFocus(true)}
						onBlur={() => setInputHasFocus(false)}
					/>
				}
				onSelect={(selected) => {
					onSelectedOptionsChange(selected);
				}}
				listTitle="Suggested Tags"
				loading={isLoading}
			/>

			{selectedTagsPlacement === 'below' && tagsMarkup.length > 0 ? (
				<>
					<br />
					<LegacyStack>{tagsMarkup}</LegacyStack>
				</>
			) : null}
		</div>
	);
}

function cleanUpTagTextInput(text: string) {
	return text.trim().toLowerCase().replace(',', '');
}
