import { useDebouncedValue } from '@shopify/react-hooks';
import { AddMajor } from '@sixriver/lighthouse-icons';
import { Button, Layout, LegacyStack, Icon } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';

import { ProductsTable } from './ProductsTable';
import {
	GetProductsDocument,
	GetProductsQuery,
	GetProductsQueryVariables,
} from './graphql/GetProducts.w-api-graphql';
import { OrderByDirection, StorageLocationType } from '../../api/fulfillment-api/types';
import { AssetTypeOrderByFields } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

const PRODUCT_TYPE = 'productType';
const SKU_ATTRIBUTES = 'skuAttributes';
enum SkuAttributesOptionValues {
	'lotTracked' = 'lotTracked',
	'expirationTracked' = 'expirationTracked',
}

export function Products() {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const defaultSort = AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Asc;

	const {
		view,
		query,
		sort = defaultSort,
		productType = 'all',
		[SKU_ATTRIBUTES]: skuAttributes,
	} = useFilters([PRODUCT_TYPE, SKU_ATTRIBUTES]);
	const selectedSkuAttributes = skuAttributes ? skuAttributes.split(' ') : undefined;

	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';

	const [getProductsQuery] = usePollingQuery<GetProductsQuery, GetProductsQueryVariables>({
		query: GetProductsDocument,
		variables: {
			after: paginationCursors[0],
			cursor: paginationCursors[0],
			first: getPageSize(),

			isExpirationTracked: selectedSkuAttributes?.includes(
				SkuAttributesOptionValues.expirationTracked,
			),

			isLotTracked: selectedSkuAttributes?.includes(SkuAttributesOptionValues.lotTracked),

			// Expected args for endpoint /v1/inventory-states/asset-location-type-summary for param containerTypeType
			// See fulfillment-api arg kinds in ProductSummary.args.ts for description
			kinds: productType === 'all' ? undefined : productType === 'shipping' ? ['shipping'] : [''],

			orderBy: sort ? (sort.split(' ')[0] as AssetTypeOrderByFields) : undefined,

			orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
			type: view === 'all' ? undefined : (view as StorageLocationType),
		},
	});

	const { isUserAllowed } = useAuth();

	const sorts = [
		// Legacy used "Item" in the UI and "CustomerIdentifier" in the GQL for externalId
		{
			label: messages.sortOptions.itemAsc,
			value: AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.itemDesc,
			value: AssetTypeOrderByFields.ExternalId + ' ' + OrderByDirection.Desc,
		},
		// Legacy used "Identifier" as the UI text for sorting by name
		{
			label: messages.sortOptions.nameAsc,
			value: AssetTypeOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.nameDesc,
			value: AssetTypeOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.descriptionAsc,
			value: AssetTypeOrderByFields.Description + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.descriptionDesc,
			value: AssetTypeOrderByFields.Description + ' ' + OrderByDirection.Desc,
		},
	];

	return getProductsQuery.error ? (
		<ErrorPage combinedError={getProductsQuery.error} />
	) : (
		<AutoRefreshPage queries={[getProductsQuery]} fullWidth title={messages.products}>
			<Layout>
				<Layout.Section>
					<LegacyStack distribution="trailing" alignment="trailing" spacing="extraLoose">
						<Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin])}
							icon={<Icon source={AddMajor} />}
							url={routes.addProduct()}
						>
							{messages.addProduct}
						</Button>
					</LegacyStack>
				</Layout.Section>
				<Layout.Section>
					<ProductsTable
						loading={getProductsQuery.fetching}
						data={getProductsQuery.data?.products}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						setFilters={setFilters}
						query={query}
						selectedView={(view as StorageLocationType) || 'all'}
						sorts={sorts}
						selectedSort={sort}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
