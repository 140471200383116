import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface PickWave404Props {
	id: string;
}

export function PickWave404({ id }: PickWave404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.pickWave404.title}
			message={translate(messages.pickWave404.message, { id })}
			action={{
				content: messages.pickWave404.action,
				url: routes.pickWaves(),
			}}
		/>
	);
}
