import { UserRole } from '@sixriver/react-support';

import { Employees } from './Employees';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function EmployeesRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<Employees />
		</AuthorizedRoute>
	);
}
