import * as Sentry from '@sentry/react';
import { MinimalLogger, type NeverHasMessageKey } from '@sixriver/typescript-support';
import { pino } from 'pino';

// TODO: could probably make this configurable via localStorage
export const LOG_LEVEL = 'info';

class Logger implements MinimalLogger {
	private logger: MinimalLogger;

	constructor() {
		this.logger = pino({ level: LOG_LEVEL });
	}

	trace(msg: string): void;
	trace(obj: NeverHasMessageKey, msg: string): void;
	trace(obj: any, msg?: any): void {
		this.logger.trace(obj, msg);
	}

	debug(msg: string): void;
	debug(obj: NeverHasMessageKey, msg: string): void;
	debug(obj: any, msg?: any): void {
		this.logger.debug(obj, msg);
	}

	info(msg: string): void;
	info(obj: NeverHasMessageKey, msg: string): void;
	info(obj: any, msg?: any): void {
		this.logger.info(obj, msg);
	}

	warn(msg: string): void;
	warn(obj: NeverHasMessageKey, msg: string): void;
	warn(obj: any, msg?: any): void {
		this.logger.warn(obj, msg);
	}

	error(msg: string): void;
	error(obj: NeverHasMessageKey, msg: string): void;
	error(obj: NeverHasMessageKey | string, msg?: string): void {
		if (typeof obj === 'string') {
			this.logger.error(obj);
			Sentry.captureException({ reason: obj });
		} else {
			msg = msg || 'message not provided';
			this.logger.error(obj, msg);
			Sentry.captureException(obj, {
				extra: {
					reason: msg,
				},
			});
		}
	}
}

export const logger = new Logger();
