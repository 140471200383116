import { Page, PageProps } from '@sixriver/lighthouse-web-community';
import { useEffect, useRef, useState } from 'react';
import { UseQueryState } from 'urql';

import { AutoRefresh } from '../AutoRefresh';

interface AutoRefreshPageGQLProps extends PageProps {
	queries: Array<UseQueryState>;
}

interface AutoRefreshPageFetchProps extends PageProps {
	discriminatorData: Record<string, unknown>;
}

type AutoRefreshPageProps = AutoRefreshPageGQLProps | AutoRefreshPageFetchProps;

// If a request fails for more than 5 minutes, we consider the request to be failing
const FAILING_REQUEST_THRESHOLD = 5 * 60_000;

export function AutoRefreshPage(props: AutoRefreshPageProps) {
	const [failingRequest, setFailingRequest] = useState(false);
	const failingTimeoutId = useRef<number | null>(null);

	const discriminatorData =
		'queries' in props
			? props.queries.map((query) => query.operation?.context.meta?.startTime)
			: props.discriminatorData;

	useEffect(() => {
		if ('queries' in props) {
			const someFailing = props.queries.some((query) => query.error);
			const someFetching = props.queries.some((query) => query.fetching);
			const someStale = props.queries.some((query) => query.stale);

			if (someFailing && !failingTimeoutId.current) {
				failingTimeoutId.current = window.setTimeout(() => {
					setFailingRequest(true);
				}, FAILING_REQUEST_THRESHOLD);
			}

			// If we know a request was failing, but we're still fetching data, don't
			// change state.  This avoids the status flapping between failing and success
			// when fetching
			if (failingTimeoutId.current && (someFetching || someStale)) {
				return;
			}

			// Cancel out any failing timeout if we no longer have failing queries
			if (!someFailing && failingTimeoutId.current) {
				setFailingRequest(false);
				window.clearTimeout(failingTimeoutId.current);
				failingTimeoutId.current = null;
			}
		}
	}, [failingRequest, props]);

	// When the page is unmounted, make sure data isn't fetched one last time
	useEffect(() => {
		return () => {
			if (failingTimeoutId.current) {
				window.clearTimeout(failingTimeoutId.current);
			}
		};
	}, []);

	return (
		<Page {...props}>
			{props.children}
			<AutoRefresh discriminatorData={discriminatorData} failingRequest={failingRequest} />
		</Page>
	);
}
