import { Layout, Page, LegacyStack, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Details } from './Details';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { PutawayJob404 } from './PutawayJob404';
import { useGetPutawayJobQuery } from './graphql/GetPutawayJob.w-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';
import { PutawayJobStatus } from '../PutawayJobs/PutawayJobsTable/PutawayJobStatus';

export function PutawayJob() {
	const { putawayJobId = '' } = useParams<{ putawayJobId: string }>();
	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.putawayJobs,
		url: routes.putawayJobs(),
	};
	// query logic
	const [getPutawayJobQuery] = useGetPutawayJobQuery({
		variables: {
			id: putawayJobId,
		},
	});

	const { putawayJob } = getPutawayJobQuery.data || {};

	if (getPutawayJobQuery.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (getPutawayJobQuery.error || !putawayJob) {
		if (hasMissingEntityError(getPutawayJobQuery.error)) {
			return <PutawayJob404 id={putawayJobId} />;
		}
		return <ErrorPage combinedError={getPutawayJobQuery.error} />;
	}

	return (
		<Page
			title={putawayJob?.inputs.containerLabels.default || ''}
			backAction={backAction}
			titleMetadata={
				<LegacyStack spacing="extraTight">
					<PutawayJobStatus status={putawayJob?.state} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</LegacyStack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<Lines putawayJob={putawayJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
