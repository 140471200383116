import { IndexTable, LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';

import { PickStrategy } from '../../../api/warehouse-api/types';
import { NoData } from '../../../components/NoData';
import { usePickStrategyName } from '../../../hooks/usePickStrategies';

interface PickStrategyCellProps {
	pickStrategy?: PickStrategy;
}

export function PickStrategyCell({ pickStrategy }: PickStrategyCellProps) {
	const getPickStrategyName = usePickStrategyName();
	return (
		<IndexTable.Cell>
			<LegacyStack vertical spacing="extraTight">
				<TextStyle>{pickStrategy ? getPickStrategyName(pickStrategy) : <NoData />}</TextStyle>
			</LegacyStack>
		</IndexTable.Cell>
	);
}
