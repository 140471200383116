import { ArrowDownMinor, ArrowUpMinor } from '@sixriver/lighthouse-icons';
import { Button, LegacyStack } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface Props {
	onMoveUp?(): void;
	onMoveDown?(): void;
	disabled?: boolean;
}

export function PriorityControls({ onMoveUp, onMoveDown, disabled }: Props) {
	const { messages } = useLocalization();

	return (
		<LegacyStack spacing="tight">
			<Button icon={ArrowUpMinor} disabled={onMoveUp === undefined || disabled} onClick={onMoveUp}>
				{messages.up}
			</Button>
			<Button
				icon={ArrowDownMinor}
				disabled={onMoveDown === undefined || disabled}
				onClick={onMoveDown}
			>
				{messages.down}
			</Button>
		</LegacyStack>
	);
}
