import { Banner, LegacyCard, Heading, LegacyStack, Text } from '@sixriver/lighthouse-web-community';
import { MapStack } from '@sixriver/map-io';

import styles from './FloorView.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import closeImg from '../../images/close.svg';

interface Props {
	onClose(): void;
	mapStack: MapStack;
	selectedId?: string;
}

export function AprilTag({ selectedId, mapStack, onClose }: Props) {
	const { messages } = useLocalization();
	const aprilTag = mapStack.aprilTags.features.find((feature: any) => {
		return selectedId && selectedId === feature.properties.id;
	});

	return !aprilTag ? (
		<div className={styles.form}>
			<Banner status="critical">{messages.unknownError}</Banner>
		</div>
	) : (
		<div className={styles.form}>
			<LegacyCard sectioned>
				<LegacyStack vertical>
					<LegacyStack distribution="equalSpacing" alignment="center">
						<Heading>{`April Tag ${aprilTag.properties.name}`}</Heading>
						<button className={styles.close} onClick={onClose}>
							<img src={closeImg} width="16" height="16" alt={messages.close} />
						</button>
					</LegacyStack>
					<LegacyStack vertical>
						<Heading>{messages.properties}</Heading>
						<LegacyStack distribution="equalSpacing" alignment="center">
							<Text variant="bodyMd" as="h5">
								{messages.workArea}
							</Text>
							<Text variant="bodyMd" as="h5">
								{aprilTag.properties.target}
							</Text>
						</LegacyStack>
					</LegacyStack>
					<LegacyStack vertical>
						<Heading>{messages.position}</Heading>
						<LegacyStack distribution="equalSpacing" alignment="center">
							<Text variant="bodyMd" as="h5">
								{messages.orientation}
							</Text>
							<Text variant="bodyMd" as="h5">
								{`${((aprilTag.properties.orientation + 270) % 360).toFixed(2)}°`}
							</Text>
						</LegacyStack>
						<LegacyStack distribution="equalSpacing" alignment="center">
							<Text variant="bodyMd" as="h5">
								{`X ${messages.coordinate}`}
							</Text>
							<Text variant="bodyMd" as="h5">
								{aprilTag.geometry.coordinates[0].toFixed(2)}
							</Text>
						</LegacyStack>
						<LegacyStack distribution="equalSpacing" alignment="center">
							<Text variant="bodyMd" as="h5">
								{`Y ${messages.coordinate}`}
							</Text>
							<Text variant="bodyMd" as="h5">
								{aprilTag.geometry.coordinates[1].toFixed(2)}
							</Text>
						</LegacyStack>
					</LegacyStack>
				</LegacyStack>
			</LegacyCard>
		</div>
	);
}
