import { IndexTable, Link, LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../hooks/useLocalization';
import * as routes from '../../../routes';

interface LicensePlateCellProps {
	jobId: string;
	licensePlate?: string | null;
	// LOL-3315
	// destinationNode: string;
	isHealJob?: boolean;
}

export function LicensePlateCell({
	jobId,
	licensePlate,
	// destinationNode,
	isHealJob,
}: LicensePlateCellProps) {
	const { messages } = useLocalization();

	return (
		<IndexTable.Cell>
			<LegacyStack>
				<Link url={routes.outboundJob(jobId)} removeUnderline>
					{licensePlate ?? messages.unknown}
				</Link>
				{/* {destinationNode ? (
					<div>
						<TextStyle variation="subdued">{`${messages.destination} ${destinationNode}`}</TextStyle>
					</div>
				) : null} */}
				{isHealJob ? (
					<TextStyle variation="subdued">{`${messages.resolutionShortHand}`}</TextStyle>
				) : null}
			</LegacyStack>
		</IndexTable.Cell>
	);
}
