// THIS FILE IS GENERATED, DO NOT EDIT!
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ApiMessageType: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Object: { input: any; output: any; }
  ObjectOrString: { input: any; output: any; }
  StringDateTime: { input: any; output: any; }
};

export type AdditionalRequestFieldInput = {
  /** The name of the additional field */
  name: Scalars['String']['input'];
  /** The value of the additional field */
  value: Scalars['String']['input'];
};

export enum AttemptResultCode {
  DeadlineExpired = 'DeadlineExpired',
  DeliverySuppressed = 'DeliverySuppressed',
  Failed = 'Failed',
  MessageTypeNotEnabled = 'MessageTypeNotEnabled',
  SiteNameMismatch = 'SiteNameMismatch',
  Success = 'Success'
}

export enum BackoffMode {
  Exponential = 'Exponential',
  Linear = 'Linear',
  None = 'None'
}

export type CreateHookInput = {
  /** If true, a failure to deliver a message will not block the scope (i.e. messages with the same ordering_key), meaning subsequent messages in the scope will be delivered */
  abandonFailedDeliveries?: InputMaybe<Scalars['Boolean']['input']>;
  /** the backoff factor (see description of backoffMode) */
  backoff?: InputMaybe<Scalars['Float']['input']>;
  /** backoff mode for retries, can be one of "none", "linear", or "exponential". The delay is constrained to be between minRetryDelayMs and maxRetryDelayMs. For "none" the delay is always minRetryDelayMs; for "linear" the delay is computed as [minRetryDelayMs + (attempt * backoff)]; for "exponential" it is computed as [minRetryDelayMs * Math.pow(backoff, attempt)] */
  backoffMode?: InputMaybe<BackoffMode>;
  /** JSON 'where' filter, when matched to a given message for delivery, causes the delivery to be suppressed */
  deliverySuppressionFilter?: InputMaybe<Scalars['Object']['input']>;
  /** Whether the hook is enabled. When disabled, incoming messages will not be added to the queue for this hook */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of this hook configuration */
  id?: InputMaybe<Scalars['String']['input']>;
  /** The max number of attempts before a delivery is abandoned. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  /** The max number of in-flight messages allowed. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxInFlight?: InputMaybe<Scalars['Int']['input']>;
  /** the max retry delay (see description of backoffMode) */
  maxRetryDelayMs?: InputMaybe<Scalars['Int']['input']>;
  /** The time-to-live for messages; once this time expires for a message it will be deleted as long as there are no active queue entries (normally queue entries expire at around the same time as the messages they reference; however, retries can bump the queue entry ttl) */
  messageTtlMs?: InputMaybe<Scalars['Float']['input']>;
  /** Configured message types for the hook */
  messageTypes?: InputMaybe<Array<CreateHookMessageTypeInput>>;
  /** the min retry delay (see description of backoffMode) */
  minRetryDelayMs?: InputMaybe<Scalars['Int']['input']>;
  /** The name of this hook configuration */
  name: Scalars['String']['input'];
  /** Whether the hook is paused. When paused, incoming messages will still be added to the queue for this hook but they will not be delivered. */
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Timeout for individual requests */
  requestTimeoutMs?: InputMaybe<Scalars['Int']['input']>;
  /** An optional regex for additional http status codes to be treated as"success" (note that all 2xx status codes are always consideredsuccessful). This can be overridden on a per-message-type basis.EXAMPLE: "^(4|5)\d\d$" matches all 4xx and 5xx status codes */
  successStatusCodeRegex?: InputMaybe<Scalars['String']['input']>;
  /** deprecated - will be ignored */
  transformationEndpoint?: InputMaybe<Scalars['String']['input']>;
  /** deprecated - use transformationTargetFormat instead */
  transformationFormat?: InputMaybe<PayloadFormatInputType>;
  /** Desired payload format to ask transformation to convert payloads into */
  transformationTargetFormat?: InputMaybe<Scalars['String']['input']>;
  /** Transport type and associated attributes. */
  transport: HookTransportInput;
};

export type CreateHookMessageTypeInput = {
  /** Fields to be omitted from messages before they are sent to the customer. Field are denoted by dot notation paths. */
  filteredFields?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The NB message type to enable. The known message types are [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  messageType?: InputMaybe<Scalars['ApiMessageType']['input']>;
  /** An optional regex for additional http status codes to be treated as"success" (note that all 2xx status codes are always consideredsuccessful). This can be overridden on a per-message-type basis.EXAMPLE: "^(4|5)\d\d$" matches all 4xx and 5xx status codes */
  successStatusCodeRegex?: InputMaybe<Scalars['String']['input']>;
  /** Transport type and associated attributes. */
  transport?: InputMaybe<HookTransportInput>;
};

export enum DeliveryState {
  Abandoned = 'Abandoned',
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Pending = 'Pending'
}

export enum DeliveryType {
  Message = 'Message',
  Request = 'Request'
}

export type Hook = {
  __typename?: 'Hook';
  /** If true, a failure to deliver a message will not block the scope (i.e. messages with the same ordering_key), meaning subsequent messages in the scope will be delivered */
  abandonFailedDeliveries: Scalars['Boolean']['output'];
  /** the backoff factor (see description of backoffMode) */
  backoff: Scalars['Float']['output'];
  /** backoff mode for retries, can be one of "none", "linear", or "exponential". The delay is constrained to be between minRetryDelayMs and maxRetryDelayMs. For "none" the delay is always minRetryDelayMs; for "linear" the delay is computed as [minRetryDelayMs + (attempt * backoff)]; for "exponential" it is computed as [minRetryDelayMs * Math.pow(backoff, attempt)] */
  backoffMode: BackoffMode;
  /** JSON 'where' filter, when matched to a given message for delivery, causes the delivery to be suppressed */
  deliverySuppressionFilter?: Maybe<Scalars['Object']['output']>;
  /** Whether the hook is enabled. When disabled, incoming messages will not be added to the queue for this hook */
  enabled: Scalars['Boolean']['output'];
  /** The ID of this hook configuration */
  id: Scalars['String']['output'];
  /** "true" if this hook is managed by the system - it will be synced at startup and will have a more limited set of editable options */
  isSystemHook: Scalars['Boolean']['output'];
  /** The max number of attempts before a delivery is abandoned. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxAttempts: Scalars['Int']['output'];
  /** The max number of in-flight messages allowed. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxInFlight: Scalars['Int']['output'];
  /** the max retry delay (see description of backoffMode) */
  maxRetryDelayMs: Scalars['Int']['output'];
  /** The time-to-live for messages; once this time expires for a message it will be deleted as long as there are no active queue entries (normally queue entries expire at around the same time as the messages they reference; however, retries can bump the queue entry ttl) */
  messageTtlMs: Scalars['Float']['output'];
  /** Configured message types for the hook */
  messageTypes: Array<HookMessageType>;
  /** the min retry delay (see description of backoffMode) */
  minRetryDelayMs: Scalars['Int']['output'];
  /** The name of this hook configuration */
  name: Scalars['String']['output'];
  /** Whether the hook is paused. When paused, incoming messages will still be added to the queue for this hook but they will not be delivered. */
  paused: Scalars['Boolean']['output'];
  /** Timeout for individual requests */
  requestTimeoutMs: Scalars['Int']['output'];
  /** An optional regex for additional http status codes to be treated as"success" (note that all 2xx status codes are always consideredsuccessful). This can be overridden on a per-message-type basis.EXAMPLE: "^(4|5)\d\d$" matches all 4xx and 5xx status codes */
  successStatusCodeRegex?: Maybe<Scalars['String']['output']>;
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
  /** deprecated - will be ignored */
  transformationEndpoint?: Maybe<Scalars['String']['output']>;
  /** deprecated - use transformationTargetFormat */
  transformationFormat?: Maybe<PayloadFormatType>;
  /** Desired payload format to ask transformation to convert payloads into */
  transformationTargetFormat?: Maybe<Scalars['String']['output']>;
  /** Transport type and associated attributes. */
  transport: HookTransport;
  /** DEPRECATED: this is not used anymore - the value will always be 0 */
  version?: Maybe<Scalars['Int']['output']>;
};

export type HookFilter = {
  /** The ID of this hook configuration */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type HookMessageType = {
  __typename?: 'HookMessageType';
  /** Only allowed on systems hooks; if true, messages will be delivered without extracting/loading the payload - useful for sending large payloads to a backend service via storage bucket */
  deliverRawPayloads: Scalars['Boolean']['output'];
  /** Fields to be omitted from messages before they are sent to the customer. Field are denoted by dot notation paths. */
  filteredFields?: Maybe<Array<Scalars['String']['output']>>;
  /** The ID of this messageType configuration */
  id: Scalars['String']['output'];
  /** The NB message type to enable. The known message types are [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  messageType: Scalars['ApiMessageType']['output'];
  /** An optional regex for additional http status codes to be treated as "success" (note that all 2xx status codes are always considered successful). This overrides any value set on the parent hook. EXAMPLE: "^(4|5)dd$" matches all 4xx and 5xx status codes  */
  successStatusCodeRegex?: Maybe<Scalars['String']['output']>;
  /** Transport type and associated attributes. */
  transport?: Maybe<HookTransport>;
};

export type HookTransport = HttpHookTransport | SftpHookTransport;

export type HookTransportInput = {
  /** Http headers that will be attached to each message */
  headers?: InputMaybe<Array<HttpHeaderInput>>;
  /** The host IP address of the SFTP server */
  host?: InputMaybe<Scalars['String']['input']>;
  /** Authentication type and associated attributes. */
  httpAuthentication?: InputMaybe<HttpAuthenticationInput>;
  /** The directory on the SFTP server for messages going from the WMS to 6RS */
  inboxDirectory?: InputMaybe<Scalars['String']['input']>;
  /** The directory on the SFTP server for messages going from 6RS to the WMS */
  outboxDirectory?: InputMaybe<Scalars['String']['input']>;
  /** The port to which to connect on the SFTP server. Defaults to port 22. */
  port?: InputMaybe<Scalars['Float']['input']>;
  /** Authentication type and associated attributes. */
  sshAuthentication?: InputMaybe<SshAuthenticationInput>;
  /** Http or Sftp */
  type: HookTransportType;
  /** Http endpoint that messages will be sent to */
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum HookTransportType {
  Http = 'Http',
  Sftp = 'Sftp'
}

export type HttpAuthentication = OAuth2HttpAuthentication | OAuth2ResourceOwnerPasswordHttpAuthentication | PasswordHttpAuthentication;

export type HttpAuthenticationInput = {
  /** Additional fields to include in the request */
  additionalRequestFields?: InputMaybe<Array<AdditionalRequestFieldInput>>;
  /** The client ID used for authentication */
  clientId?: InputMaybe<Scalars['String']['input']>;
  /** The client secret used for authentication */
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  /** The endpoint for authentication requests */
  endpoint?: InputMaybe<Scalars['String']['input']>;
  /** The type of the authentication endpoint; one of [discovery, token] */
  endpointType?: InputMaybe<OAuth2EndpointType>;
  /** The grant type to use for OAuth2 authentication; one of [client_credentials, password] */
  grantType?: InputMaybe<OAuth2GrantType>;
  /** The header name to use for authentication; default is Authorization */
  headerName?: InputMaybe<Scalars['String']['input']>;
  /** Should the custom headers defined for the transport also be sent in the OAuth2 token requests? Default is false. */
  includeTransportHeaders?: InputMaybe<Scalars['Boolean']['input']>;
  /** The password for authentication */
  password?: InputMaybe<Scalars['String']['input']>;
  /** The schema to use for authentication; defaults are password:Basic, oauth2:Bearer */
  scheme?: InputMaybe<Scalars['String']['input']>;
  /** The scope for the client's access */
  scope?: InputMaybe<Scalars['String']['input']>;
  /** The authentication type to use for HTTP requests; one of [password, oauth2] */
  type: HttpAuthenticationType;
  /** The username for authentication */
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum HttpAuthenticationType {
  Oauth2 = 'Oauth2',
  Password = 'Password'
}

export type HttpHeaderInput = {
  /** The name of the header */
  name: Scalars['String']['input'];
  /** The value of the header */
  value: Scalars['String']['input'];
};

export type HttpHookTransport = {
  __typename?: 'HttpHookTransport';
  headers?: Maybe<Scalars['Object']['output']>;
  httpAuthentication?: Maybe<HttpAuthentication>;
  type: HookTransportType;
  url?: Maybe<Scalars['String']['output']>;
};

export type HttpMessageDeliveryAttemptData = {
  __typename?: 'HttpMessageDeliveryAttemptData';
  /** The response body received */
  body?: Maybe<Scalars['ObjectOrString']['output']>;
  /** The http status code of this response */
  status?: Maybe<Scalars['Float']['output']>;
  type: HookTransportType;
};

export type Message = {
  __typename?: 'Message';
  /** Denotes the delivery type, which can be either "Message" (i.e. a published event, such as confirmation messages, or things like warehouseInventoryChanged, etc) or "Request" (i.e. a request for which a response is likely desired, such as container validation, APL, etc) */
  deliveryType?: Maybe<DeliveryType>;
  /** Message payload format */
  format?: Maybe<Scalars['String']['output']>;
  /** The ID of this message */
  id: Scalars['String']['output'];
  /** The type of the northbound message; this can be a string, known values are: [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  messageType: Scalars['ApiMessageType']['output'];
  /** TODO */
  orderingKey?: Maybe<Scalars['String']['output']>;
  /** TODO */
  payload: Scalars['Object']['output'];
  /** TODO */
  publishedAt: Scalars['StringDateTime']['output'];
  /** resolves payloads stored in a bucket; returns the db value if the payload is fully stored in the database */
  resolvedPayload?: Maybe<Scalars['Object']['output']>;
  /** Indicates how payload is stored (i.e. write directly to the database; write to a storage bucket and reference from the db; etc) */
  storageMode?: Maybe<PayloadStorageMode>;
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
};

export type MessageConnection = {
  __typename?: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: MessagePageInfo;
};

export type MessageDelivery = {
  __typename?: 'MessageDelivery';
  /** If a delivery attempt is currently active, the time that the attempt started */
  activeAttemptStartedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The number of attempts started for this delivery */
  attemptsStarted: Scalars['Int']['output'];
  /** The current state of the delivery */
  deliveryState: DeliveryState;
  /** The time that this request ended (success, abandoned, or cancelled) */
  endedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The time at which this delivery was added to the queue */
  enqueuedAt: Scalars['StringDateTime']['output'];
  /** The expiration time of this delivery */
  expiresAt: Scalars['StringDateTime']['output'];
  /** The hook configuration for this request */
  hook: Hook;
  /** The ID of this delivery queue entry */
  id: Scalars['String']['output'];
  /** Whether this delivery is a "replay" */
  isReplay?: Maybe<Scalars['Boolean']['output']>;
  /** The northbound message for this request */
  message: Message;
  /** The next time this request can be attempted */
  nextAttemptAt: Scalars['StringDateTime']['output'];
  /** If non-null, this delivery will not be attempted until the specified delivery is complete */
  notBeforeId?: Maybe<Scalars['String']['output']>;
  /** The transformation format required by the hook at the time this queue entry was ingested */
  requiredFormat?: Maybe<Scalars['String']['output']>;
  /** The time that this request was started */
  startedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
};

export type MessageDeliveryAndAttemptFilter = {
  /** The current state of the delivery */
  deliveryState?: InputMaybe<DeliveryState>;
  /** The hook configuration for this request */
  hook?: InputMaybe<HookFilter>;
  /** The Http Status code of the message delivery. */
  httpStatus?: InputMaybe<Scalars['Float']['input']>;
  /** The ID of this delivery queue entry */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The number of attempts started for this delivery */
  maxAttemptsStarted?: InputMaybe<Scalars['Int']['input']>;
  /** The time that this request ended (success, abandoned, or cancelled) */
  maxEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time at which this delivery was added to the queue */
  maxEnqueuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time that this request was started */
  maxStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The northbound message for this request */
  message?: InputMaybe<MessageFilter>;
  /** The number of attempts started for this delivery */
  minAttemptsStarted?: InputMaybe<Scalars['Int']['input']>;
  /** The time that this request ended (success, abandoned, or cancelled) */
  minEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time at which this delivery was added to the queue */
  minEnqueuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time that this request was started */
  minStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MessageDeliveryAttempt = {
  __typename?: 'MessageDeliveryAttempt';
  /** The attempt number */
  attemptNumber: Scalars['Float']['output'];
  /** Additional transport specific data */
  data?: Maybe<MessageDeliveryAttemptData>;
  /** The queue entry which resulted in this attempt */
  delivery: MessageDelivery;
  /** When the request corresponding to this attempt ended (success or fail) */
  endedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The ID of this attempt */
  id: Scalars['String']['output'];
  /** Any error received while submitting the http request; this includes human readable string for the status code (if failure) or any non-http errors (e.g. connection failures/timeouts) */
  requestError?: Maybe<Scalars['String']['output']>;
  /** A status code describing the general outcome of the attempt */
  sendResult?: Maybe<AttemptResultCode>;
  /** When the request corresponding to this attempt was started */
  startedAt: Scalars['StringDateTime']['output'];
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
  /** The ID of the worker (pod) that made this attempt */
  workerId: Scalars['String']['output'];
};

export type MessageDeliveryAttemptConnection = {
  __typename?: 'MessageDeliveryAttemptConnection';
  edges: Array<MessageDeliveryAttemptEdge>;
  pageInfo: MessageDeliveryAttemptPageInfo;
};

export type MessageDeliveryAttemptData = HttpMessageDeliveryAttemptData | SftpMessageDeliveryAttemptData;

export type MessageDeliveryAttemptEdge = {
  __typename?: 'MessageDeliveryAttemptEdge';
  cursor: Scalars['String']['output'];
  node: MessageDeliveryAttempt;
};

export type MessageDeliveryAttemptFilter = {
  /** The queue entry which resulted in this attempt */
  delivery?: InputMaybe<MessageDeliveryFilter>;
  /** The http status code of this response */
  httpStatus?: InputMaybe<Scalars['Int']['input']>;
  /** The ID of this attempt */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** When the request corresponding to this attempt ended (success or fail) */
  maxEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** When the request corresponding to this attempt was started */
  maxStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** When the request corresponding to this attempt ended (success or fail) */
  minEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** When the request corresponding to this attempt was started */
  minStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MessageDeliveryAttemptOrderBy = {
  orderField?: InputMaybe<MessageDeliveryAttemptOrderKey>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum MessageDeliveryAttemptOrderKey {
  StartedAt = 'StartedAt'
}

export type MessageDeliveryAttemptPageInfo = {
  __typename?: 'MessageDeliveryAttemptPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type MessageDeliveryConnection = {
  __typename?: 'MessageDeliveryConnection';
  edges: Array<MessageDeliveryEdge>;
  pageInfo: MessageDeliveryPageInfo;
};

export type MessageDeliveryEdge = {
  __typename?: 'MessageDeliveryEdge';
  cursor: Scalars['String']['output'];
  node: MessageDelivery;
};

export type MessageDeliveryFilter = {
  /** The current state of the delivery */
  deliveryState?: InputMaybe<DeliveryState>;
  /** The hook configuration for this request */
  hook?: InputMaybe<HookFilter>;
  /** The ID of this delivery queue entry */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The number of attempts started for this delivery */
  maxAttemptsStarted?: InputMaybe<Scalars['Int']['input']>;
  /** The time that this request ended (success, abandoned, or cancelled) */
  maxEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time at which this delivery was added to the queue */
  maxEnqueuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time that this request was started */
  maxStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The northbound message for this request */
  message?: InputMaybe<MessageFilter>;
  /** The number of attempts started for this delivery */
  minAttemptsStarted?: InputMaybe<Scalars['Int']['input']>;
  /** The time that this request ended (success, abandoned, or cancelled) */
  minEndedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time at which this delivery was added to the queue */
  minEnqueuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The time that this request was started */
  minStartedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type MessageDeliveryOrderBy = {
  orderField?: InputMaybe<MessageDeliveryOrderKey>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum MessageDeliveryOrderKey {
  EnqueuedAt = 'EnqueuedAt'
}

export type MessageDeliveryPageInfo = {
  __typename?: 'MessageDeliveryPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type MessageFilter = {
  /** Denotes the delivery type, which can be either "Message" (i.e. a published event, such as confirmation messages, or things like warehouseInventoryChanged, etc) or "Request" (i.e. a request for which a response is likely desired, such as container validation, APL, etc) */
  deliveryType?: InputMaybe<DeliveryType>;
  /** The ID of this message */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The type of the northbound message; this can be a string, known values are: [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  messageType?: InputMaybe<Scalars['ApiMessageType']['input']>;
  /** The type of the northbound message; this can be a string, known values are: [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  orderingKey?: InputMaybe<Scalars['String']['input']>;
  /** arbitrary text to search for in the message payload */
  payloadContent?: InputMaybe<Scalars['String']['input']>;
};

export type MessageOrderBy = {
  orderField?: InputMaybe<MessageOrderKey>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum MessageOrderKey {
  PublishedAt = 'PublishedAt'
}

export type MessagePageInfo = {
  __typename?: 'MessagePageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type MessagePayload = {
  __typename?: 'MessagePayload';
  /** The timestamp recorded at creation */
  createdAt: Scalars['StringDateTime']['output'];
  /** The format that describes this payload */
  format: Scalars['String']['output'];
  /** The ID of this message payload */
  id: Scalars['String']['output'];
  /** The messageId this payload corresponds to */
  messageId: Scalars['String']['output'];
  /** The body of the payload */
  payload: Scalars['Object']['output'];
  /** Indicates how payload is stored (i.e. write directly to the database; write to a storage bucket and reference from the db; etc) */
  storageMode?: Maybe<PayloadStorageMode>;
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
};

export type MessagePayloadConnection = {
  __typename?: 'MessagePayloadConnection';
  edges: Array<MessagePayloadEdge>;
  pageInfo: MessagePayloadPageInfo;
};

export type MessagePayloadEdge = {
  __typename?: 'MessagePayloadEdge';
  cursor: Scalars['String']['output'];
  node: MessagePayload;
};

export type MessagePayloadFilter = {
  /** The format that describes this payload */
  formats?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The ID of this message payload */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The messageId this payload corresponds to */
  messageIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type MessagePayloadOrderBy = {
  orderField?: InputMaybe<MessagePayloadOrderKey>;
  sortDirection?: InputMaybe<SortDirection>;
};

export enum MessagePayloadOrderKey {
  CreatedAt = 'CreatedAt'
}

export type MessagePayloadPageInfo = {
  __typename?: 'MessagePayloadPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type MessageTransform = {
  __typename?: 'MessageTransform';
  /** If a transform attempt is currently active, the time that the attempt started */
  activeAttemptStartedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The number of attempts started for this transform */
  attemptsStarted: Scalars['Int']['output'];
  /** The time that this request ended (success, abandoned, or cancelled) */
  endedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The time at which this transform was added to the queue */
  enqueuedAt: Scalars['StringDateTime']['output'];
  /** The expiration time of this transform */
  expiresAt: Scalars['StringDateTime']['output'];
  /** The ID of this v queue entry */
  id: Scalars['String']['output'];
  /** The northbound message for this request */
  message: Message;
  /** The next time this request can be attempted */
  nextAttemptAt: Scalars['StringDateTime']['output'];
  /** The time that this request was started */
  startedAt?: Maybe<Scalars['StringDateTime']['output']>;
  /** The format this message should be transformed to */
  targetFormat: Scalars['String']['output'];
  /** The ID of the tenant to which this entity belongs */
  tenantId: Scalars['String']['output'];
  /** The current state of the transform */
  transformState: TransformState;
};

export type Mutation = {
  __typename?: 'Mutation';
  addHook: Hook;
  cancelDelivery: MessageDelivery;
  ensureSystemHooks: Array<Hook>;
  removeHook: Array<Hook>;
  replayMessages: ReplayResult;
  updateHook: Hook;
};


export type MutationAddHookArgs = {
  hook: CreateHookInput;
};


export type MutationCancelDeliveryArgs = {
  deliveryId: Scalars['String']['input'];
  orderingKey?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnsureSystemHooksArgs = {
  deliverRawPayloadsMessageTypes?: InputMaybe<Array<SouthboundMessageType>>;
  useAdapterForSouthbound?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationRemoveHookArgs = {
  id: Scalars['String']['input'];
};


export type MutationReplayMessagesArgs = {
  hookIds?: InputMaybe<Array<Scalars['String']['input']>>;
  messageIds: Array<Scalars['String']['input']>;
  orderingKey?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateHookArgs = {
  hook: UpdateHookInput;
};

export enum OAuth2EndpointType {
  Discovery = 'Discovery',
  Token = 'Token'
}

export enum OAuth2GrantType {
  ClientCredentials = 'ClientCredentials',
  Password = 'Password'
}

export type OAuth2HttpAuthentication = {
  __typename?: 'OAuth2HttpAuthentication';
  additionalRequestFields?: Maybe<Scalars['Object']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  endpoint: Scalars['String']['output'];
  endpointType: OAuth2EndpointType;
  grantType: OAuth2GrantType;
  headerName?: Maybe<Scalars['String']['output']>;
  includeTransportHeaders?: Maybe<Scalars['Boolean']['output']>;
  scheme?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  type: HttpAuthenticationType;
};

export type OAuth2ResourceOwnerPasswordHttpAuthentication = {
  __typename?: 'OAuth2ResourceOwnerPasswordHttpAuthentication';
  additionalRequestFields?: Maybe<Scalars['Object']['output']>;
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  endpoint: Scalars['String']['output'];
  endpointType: OAuth2EndpointType;
  grantType: OAuth2GrantType;
  headerName?: Maybe<Scalars['String']['output']>;
  includeTransportHeaders?: Maybe<Scalars['Boolean']['output']>;
  password: Scalars['String']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  scope?: Maybe<Scalars['String']['output']>;
  type: HttpAuthenticationType;
  username: Scalars['String']['output'];
};

export type PasswordHttpAuthentication = {
  __typename?: 'PasswordHttpAuthentication';
  headerName?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  scheme?: Maybe<Scalars['String']['output']>;
  type: HttpAuthenticationType;
  username: Scalars['String']['output'];
};

export type PasswordSshAuthentication = {
  __typename?: 'PasswordSshAuthentication';
  password: Scalars['String']['output'];
  type: SshAuthenticationType;
  username: Scalars['String']['output'];
};

export type PayloadFormatInputType = {
  /** deprecated - use transformationTargetFormat instead */
  name: Scalars['String']['input'];
  /** deprecated - use transformationTargetFormat instead */
  version: Scalars['String']['input'];
};

export type PayloadFormatType = {
  __typename?: 'PayloadFormatType';
  /** deprecated - use transformationTargetFormat instead */
  name: Scalars['String']['output'];
  /** deprecated - use transformationTargetFormat instead */
  version: Scalars['String']['output'];
};

export enum PayloadStorageMode {
  External = 'External',
  Local = 'Local',
  LocalWrapped = 'LocalWrapped'
}

export type Query = {
  __typename?: 'Query';
  getDeliveries: MessageDeliveryConnection;
  getDelivery: MessageDelivery;
  getDeliveryAttempt: MessageDeliveryAttempt;
  getDeliveryAttempts: MessageDeliveryAttemptConnection;
  getHook: Hook;
  getMessage: Message;
  getMessagePayload: MessagePayload;
  getMessagePayloads: MessagePayloadConnection;
  getMessages: MessageConnection;
  hooks: Array<Hook>;
};


export type QueryGetDeliveriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessageDeliveryAndAttemptFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MessageDeliveryOrderBy>;
};


export type QueryGetDeliveryArgs = {
  deliveryId: Scalars['String']['input'];
};


export type QueryGetDeliveryAttemptArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDeliveryAttemptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessageDeliveryAttemptFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MessageDeliveryAttemptOrderBy>;
};


export type QueryGetHookArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetMessageArgs = {
  messageId: Scalars['String']['input'];
};


export type QueryGetMessagePayloadArgs = {
  messagePayloadId: Scalars['String']['input'];
};


export type QueryGetMessagePayloadsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessagePayloadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MessagePayloadOrderBy>;
};


export type QueryGetMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<MessageOrderBy>;
};

export type ReplayDeliveryResult = {
  __typename?: 'ReplayDeliveryResult';
  /** The hook that the messages were queued to (or skipped on) */
  hook: Hook;
  /** The list of replayed messages for this hook */
  replayed: Array<MessageDelivery>;
  /** The list of skipped messages for this hook */
  skipped: Array<Message>;
};

export type ReplayResult = {
  __typename?: 'ReplayResult';
  /** The results for each hook/message */
  results: Array<ReplayDeliveryResult>;
  /** The status of the request */
  status: ReplayStatusCode;
};

export enum ReplayStatusCode {
  AllReplayed = 'AllReplayed',
  AllSkipped = 'AllSkipped',
  NoMessagesProvided = 'NoMessagesProvided',
  SomeSkipped = 'SomeSkipped'
}

export type SftpHookTransport = {
  __typename?: 'SftpHookTransport';
  host: Scalars['String']['output'];
  inboxDirectory?: Maybe<Scalars['String']['output']>;
  outboxDirectory?: Maybe<Scalars['String']['output']>;
  port?: Maybe<Scalars['Float']['output']>;
  sshAuthentication: SshAuthentication;
  type: HookTransportType;
};

export type SftpMessageDeliveryAttemptData = {
  __typename?: 'SftpMessageDeliveryAttemptData';
  /** The response body received */
  body?: Maybe<Scalars['ObjectOrString']['output']>;
  type: HookTransportType;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SouthboundMessageType {
  AvailableCarrierLanesUpdated = 'AvailableCarrierLanesUpdated',
  BulkGroupCancel = 'BulkGroupCancel',
  BulkGroupUpdate = 'BulkGroupUpdate',
  ContainerPackoutUpdate = 'ContainerPackoutUpdate',
  GroupCancel = 'GroupCancel',
  GroupUpdate = 'GroupUpdate',
  MfpTaskCompletion = 'MfpTaskCompletion',
  PackoutPrintServiceResponse = 'PackoutPrintServiceResponse',
  PickWave = 'PickWave',
  PreKitRequest = 'PreKitRequest',
  Product = 'Product',
  PutawayCancelContainers = 'PutawayCancelContainers',
  PutawayWave = 'PutawayWave',
  ShuttleAvailableContainers = 'ShuttleAvailableContainers',
  ShuttleAvailableDestinations = 'ShuttleAvailableDestinations',
  ShuttleCancelContainers = 'ShuttleCancelContainers',
  ShuttleRedirectContainers = 'ShuttleRedirectContainers',
  ShuttleWave = 'ShuttleWave',
  ShuttleWithdrawDestinations = 'ShuttleWithdrawDestinations',
  SplittablePickWave = 'SplittablePickWave',
  UpdateGroupTakeoffDestination = 'UpdateGroupTakeoffDestination',
  WorkOrderRequest = 'WorkOrderRequest'
}

export type SshAuthentication = PasswordSshAuthentication;

export type SshAuthenticationInput = {
  /** The password for authentication */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Password */
  type: SshAuthenticationType;
  /** The username for authentication */
  username?: InputMaybe<Scalars['String']['input']>;
};

export enum SshAuthenticationType {
  Password = 'Password'
}

export enum TransformState {
  Cancelled = 'Cancelled',
  Complete = 'Complete',
  Failed = 'Failed',
  Pending = 'Pending'
}

export type UpdateHookInput = {
  /** If true, a failure to deliver a message will not block the scope (i.e. messages with the same ordering_key), meaning subsequent messages in the scope will be delivered */
  abandonFailedDeliveries?: InputMaybe<Scalars['Boolean']['input']>;
  /** the backoff factor (see description of backoffMode) */
  backoff?: InputMaybe<Scalars['Float']['input']>;
  /** the backoff factor (see description of backoffMode) */
  backoffMode?: InputMaybe<BackoffMode>;
  /** JSON 'where' filter, when matched to a given message for delivery, causes the delivery to be suppressed */
  deliverySuppressionFilter?: InputMaybe<Scalars['Object']['input']>;
  /** Whether the hook is enabled. When disabled, incoming messages will not be added to the queue for this hook */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** The ID of this hook configuration */
  id: Scalars['String']['input'];
  /** The max number of attempts before a delivery is abandoned. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxAttempts?: InputMaybe<Scalars['Int']['input']>;
  /** The max number of in-flight messages allowed. Negative numbers are interpreted as positive infinity. 0 Is not allowed */
  maxInFlight?: InputMaybe<Scalars['Int']['input']>;
  /** the max retry delay (see description of backoffMode) */
  maxRetryDelayMs?: InputMaybe<Scalars['Int']['input']>;
  /** The time-to-live for messages; once this time expires for a message it will be deleted as long as there are no active queue entries (normally queue entries expire at around the same time as the messages they reference; however, retries can bump the queue entry ttl) */
  messageTtlMs?: InputMaybe<Scalars['Float']['input']>;
  /** Configured message types for the hook */
  messageTypes?: InputMaybe<Array<UpdateHookMessageTypeInput>>;
  /** the min retry delay (see description of backoffMode) */
  minRetryDelayMs?: InputMaybe<Scalars['Int']['input']>;
  /** The name of this hook configuration */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Whether the hook is paused. When paused, incoming messages will still be added to the queue for this hook but they will not be delivered. */
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Timeout for individual requests */
  requestTimeoutMs?: InputMaybe<Scalars['Int']['input']>;
  /** An optional regex for additional http status codes to be treated as"success" (note that all 2xx status codes are always consideredsuccessful). This can be overridden on a per-message-type basis.EXAMPLE: "^(4|5)\d\d$" matches all 4xx and 5xx status codes */
  successStatusCodeRegex?: InputMaybe<Scalars['String']['input']>;
  /** deprecated - will be ignored */
  transformationEndpoint?: InputMaybe<Scalars['String']['input']>;
  /** deprecated - use transformationTargetFormat instead */
  transformationFormat?: InputMaybe<PayloadFormatInputType>;
  /** Desired payload format to ask transformation to convert payloads into */
  transformationTargetFormat?: InputMaybe<Scalars['String']['input']>;
  /** Transport type and associated attributes. */
  transport?: InputMaybe<HookTransportInput>;
};

export type UpdateHookMessageTypeInput = {
  /** Fields to be omitted from messages before they are sent to the customer. Field are denoted by dot notation paths. */
  filteredFields?: InputMaybe<Array<Scalars['String']['input']>>;
  /** The NB message type to enable. The known message types are [alternatePickLocations, balanceOnHand, containerValidation, printDataRequest, printRequest, substitutionRequest, productLocations, warehouseInventoryChanged, product-created, product-updated, product-inspected, acknowledgement, containerAccepted, containerInducted, containerPacked, containerPickComplete, containerSorted, containerTakenOff, pickTaskAccepted, pickTaskGrouped, pickTaskPicked, pickTaskRejected, batchFirstContainerInducted, batchLastContainerPickComplete, packoutContainerDetermined, packoutPrintServiceRequest, containerDestination, laneAssignment, locationTasksDone, locationPicked, workOrderStatus, workOrderComplete, mfpArrived, eventMessage, putawayContainerInducted, putawayContainerLineCompleted, putawayContainerCompleted, putawayContainerCompletedRollup, putawayWaveIngested, putawayCancelContainersResult, shuttleWaveIngested, shuttleContainerAssigned, shuttleContainerPickupResult, shuttleContainerDropoffResult, shuttleContainerResult, shuttleCancelContainersResult, shuttleRedirectContainersResult, shuttleAvailableContainersResult, shuttleAvailableDestinationsResult, shuttleWithdrawDestinationsResult, shuttleContainerDestinationSelected, pickWave, splittablePickWave, groupUpdate, updateGroupTakeoffDestination, groupCancel, bulkGroupCancel, packoutPrintServiceResponse, availableCarrierLanesUpdated, containerPackoutUpdate, product, preKitRequest, workOrderRequest, putawayWave, putawayCancelContainers, bulkGroupUpdate, mfpTaskCompletion, shuttleWave, shuttleCancelContainers, shuttleRedirectContainers, shuttleAvailableContainers, shuttleAvailableDestinations, shuttleWithdrawDestinations] */
  messageType?: InputMaybe<Scalars['ApiMessageType']['input']>;
  /** An optional regex for additional http status codes to be treated as"success" (note that all 2xx status codes are always consideredsuccessful). This can be overridden on a per-message-type basis.EXAMPLE: "^(4|5)\d\d$" matches all 4xx and 5xx status codes */
  successStatusCodeRegex?: InputMaybe<Scalars['String']['input']>;
  /** Transport type and associated attributes. */
  transport?: InputMaybe<HookTransportInput>;
};
