import {
	Modal,
	Page,
	Layout,
	ContextualSaveBar,
	Banner,
	Toast,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useState, useMemo } from 'react';

import InternationalizationSection from './Internationalization';
import Readiness from './Readiness';
import SettingsSection from './SettingsSection';
import { useSiteConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { useConfigServiceClient } from '../../providers';
import { logger } from '../../utils';

export function Settings() {
	const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
	const [warnings, setWarnings] = useState<string[]>([]);

	const closeConfirmModal = useCallback(
		() => setIsWarningModalOpen(false),
		[setIsWarningModalOpen],
	);

	const { messages } = useLocalization();
	const pageTitle = messages.siteSettings;

	const { data: siteConfig } = useSiteConfig();

	const fallbackLocale = siteConfig?.globalConfig.internationalization?.fallbackLocale || 'en-US';
	const [selectedLocale, setSelectedLocale] = useState<string>(fallbackLocale);
	const [localeError, setLocaleError] = useState<string>('');

	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [isDirty, setIsDirty] = useState<boolean>(false);
	const [generalError, setGeneralError] = useState<string>('');

	const sections: SettingsSection[] = useMemo(() => {
		return [
			new InternationalizationSection(
				{
					error: localeError,
					original: fallbackLocale,
					selected: selectedLocale,
					setError: setLocaleError,
					setSelected: setSelectedLocale,
				},
				setIsDirty,
				messages,
			),
		];
	}, [fallbackLocale, selectedLocale, localeError, messages]);

	const configServiceClient = useConfigServiceClient();

	const [toastActive, setToastActive] = useState(false);
	const toggleToastActive = useCallback(() => setToastActive((active) => !active), []);

	const save = useCallback(async () => {
		setIsSaving(true);
		const config = {
			site: { globalConfig: {} },
		};
		const changes: string[] = [];

		sections.forEach((section) => {
			section.visit(config, changes);
		});

		const finalPayload = {
			changes,
			config,
		};

		try {
			await configServiceClient.saveConfig(JSON.stringify(finalPayload));

			setIsDirty(false);
			toggleToastActive();
		} catch (err) {
			logger.error({ err }, 'failed saving settings config');

			setGeneralError('An error occurred. Please try again.');
		} finally {
			setIsWarningModalOpen(false);
			setIsSaving(false);
		}
	}, [sections, configServiceClient, toggleToastActive]);

	const handleSave = useCallback(async () => {
		const isValid = sections.every((section) => section.validate());
		if (!isValid) {
			return;
		}

		const warnings = sections.flatMap((section) => section.hasWarnings());
		if (warnings.length > 0) {
			setIsWarningModalOpen(true);
			setWarnings(warnings);
			return;
		}

		save();
	}, [sections, save]);

	const handleDiscard = useCallback(() => {
		sections.forEach((section) => section.discard());

		setIsDirty(false);
		setGeneralError('');
	}, [sections]);

	return (
		<>
			<Page title={pageTitle}>
				<Modal
					title={messages.modalSettingsWarning.title}
					open={isWarningModalOpen}
					onClose={closeConfirmModal}
					primaryAction={{
						content: isSaving ? messages.modalSettingsWarning.acting : messages.yesContinue,
						destructive: true,
						loading: isSaving,
						onAction: save,
					}}
					secondaryActions={[
						{
							content: messages.noCancel,
							destructive: false,
							onAction: closeConfirmModal,
						},
					]}
				>
					<Modal.Section>
						<>
							<ul>
								{warnings.map((warning, index) => (
									<li key={index}>{warning}</li>
								))}
							</ul>
						</>
						<p>{messages.wouldYouLikeToContinue}</p>
					</Modal.Section>
				</Modal>

				{generalError && (
					<div style={{ marginBottom: '20px' }}>
						<Banner title="Error" status="critical">
							<p>{generalError}</p>
						</Banner>
					</div>
				)}
				<Layout>
					<Readiness />
					{sections.map((section, index) => (
						<Layout.Section key={index}>{section.render()}</Layout.Section>
					))}
				</Layout>
				{isDirty && (
					<ContextualSaveBar
						message="Unsaved changes"
						saveAction={{
							disabled: isSaving,
							loading: isSaving,
							onAction: handleSave,
						}}
						discardAction={{
							onAction: handleDiscard,
						}}
					/>
				)}
				{toastActive ? (
					<Toast content={messages.configSavedToast} onDismiss={toggleToastActive} />
				) : null}
			</Page>
		</>
	);
}
