import { Layout, Page, LegacyStack, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Details } from './Details';
import { Progress } from './Progress';
import { ShuttleAvailableDestination404 } from './ShuttleAvailableDestination404';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable';
import { useGetShuttleAvailableDestinationQuery } from './graphql/GetShuttleAvailableDestination.w-api-graphql';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function ShuttleAvailableDestination() {
	const { id = '' } = useParams<{ id: string }>();
	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.shuttleJobs,
		url: routes.shuttleAvailableContainers(),
	};
	const [query] = useGetShuttleAvailableDestinationQuery({
		variables: {
			id,
		},
	});
	const { shuttleAvailableDestination } = query.data ?? {};

	if (query.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (query.error) {
		if (hasMissingEntityError(query.error)) {
			return <ShuttleAvailableDestination404 id={id} />;
		}
		return <ErrorPage combinedError={query.error} />;
	}

	return (
		<Page
			title={shuttleAvailableDestination?.inputs.storageLocationAddress || ''}
			titleMetadata={
				<LegacyStack spacing="extraTight">
					<ShuttleJobStatus status={shuttleAvailableDestination?.state} />
				</LegacyStack>
			}
			backAction={backAction}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleAvailableDestination={shuttleAvailableDestination} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleAvailableDestination={shuttleAvailableDestination} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
