import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
	Routes,
	Navigate,
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	RouterProvider,
} from 'react-router-dom';

import { Frame } from './Frame';
import { LoginRoute } from '../components/LoginRoute';
import { Toast } from '../components/Toast';
import { useSiteConfig } from '../hooks/useConfig';
import { cleanUpPrintLabelLocalStorage } from '../hooks/usePrintLabelButton';
import { ActionLogRoute } from '../pages/ActionLog/ActionLog.route';
import { AllocationRulesRoute } from '../pages/AllocationRules/AllocationRules.route';
import { AuditLogsRoute } from '../pages/AuditLogs/AuditLogs.route';
import { Login, Logout } from '../pages/Auth';
import { ForgotPassword } from '../pages/Auth/ForgotPassword';
import { SetPassword } from '../pages/Auth/SetPassword';
import { ContainerAddRoute } from '../pages/Containers/ContainerAdd.route';
import { ContainerDetailsRoute } from '../pages/Containers/ContainerDetails.route';
import { ContainerEditRoute } from '../pages/Containers/ContainerEdit.route';
import { ContainersRoute } from '../pages/Containers/Containers.route';
import { CountRoute } from '../pages/Count/Count.route';
import { DeviceRoute } from '../pages/Devices/Device.route';
import { DeviceEditRoute } from '../pages/Devices/DeviceEdit.route';
import { DevicesRoute } from '../pages/Devices/Devices.route';
import { EmployeeRoute } from '../pages/Employees/Employee.route';
import { EmployeeAddRoute } from '../pages/Employees/EmployeeAdd.route';
import { EmployeeEditRoute } from '../pages/Employees/EmployeeEdit.route';
import { EmployeesRoute } from '../pages/Employees/Employees.route';
import { ExceptionRoute } from '../pages/Exception/Exception.route';
import { ExceptionsRoute } from '../pages/Exceptions/Exceptions.route';
import { FloorViewRoute } from '../pages/FloorView/FloorView.route';
import { FulfillmentTrackerRoute } from '../pages/FulfillmentTracker/FulfillmentTracker.route';
import { AddJobFlowRuleRoute } from '../pages/JobFlowRules/AddJobFlowRule.route';
import { EditJobFlowRuleRoute } from '../pages/JobFlowRules/EditJobFlowRule.route';
import { JobFlowRuleRoute } from '../pages/JobFlowRules/JobFlowRule.route';
import { JobFlowRulesRoute } from '../pages/JobFlowRules/JobFlowRules.route';
import { AddLocationRoute } from '../pages/Locations/AddLocation.route';
import { EditLocationRoute } from '../pages/Locations/EditLocation.route';
import { LocationRoute } from '../pages/Locations/Location.route';
import { LocationByAddressRoute } from '../pages/Locations/LocationByAddress.route';
import { LocationsRoute } from '../pages/Locations/Locations.route';
import { DeliveryLogPage } from '../pages/MessageLog';
import { MfaEntryRoute } from '../pages/MobileFulfillmentApp/MfaEntryRoute';
import * as mfaRoutes from '../pages/MobileFulfillmentApp/routes';
import { OrderRoute } from '../pages/Order/Order.route';
import { OrdersRoute } from '../pages/Orders/Orders.route';
import { OutboundJobRoute } from '../pages/OutboundJob/OutboundJob.route';
import { OutboundJobsRoute } from '../pages/OutboundJobs/OutboundJobs.route';
import { PickWaveRoute } from '../pages/PickWaves/PickWave.route';
import { PickWavesRoute } from '../pages/PickWaves/PickWaves.route';
import { AddProductRoute } from '../pages/Product/AddProduct.route';
import { EditProductRoute } from '../pages/Product/EditProduct.route';
import { ProductRoute } from '../pages/Product/Product.route';
import { ProductsRoute } from '../pages/Products/Products.route';
import { PutawayJobRoute } from '../pages/PutawayJob/PutawayJob.route';
import { PutawayJobsRoute } from '../pages/PutawayJobs/PutawayJobs.route';
import { SettingsRoute } from '../pages/Settings/Settings.route';
import { ShuttleAvailableContainerRoute } from '../pages/ShuttleAvailableContainer/ShuttleAvailableContainer.route';
import { ShuttleAvailableContainersRoute } from '../pages/ShuttleAvailableContainers/ShuttleAvailableContainers.route';
// eslint-disable-next-line max-len
import { ShuttleAvailableDestinationRoute } from '../pages/ShuttleAvailableDestination/ShuttleAvailableDestination.route';
// eslint-disable-next-line max-len
import { ShuttleAvailableDestinationsRoute } from '../pages/ShuttleAvailableDestinations/ShuttleAvailableDestinations.route';
import { ShuttleJobRoute } from '../pages/ShuttleJob/ShuttleJob.route';
import { ShuttleJobsRoute } from '../pages/ShuttleJobs/ShuttleJobs.route';
import { SortWallRoute } from '../pages/SortWall/SortWall.route';
import { SortWallsRoute } from '../pages/SortWalls/SortWalls.route';
import { SupportRoute } from '../pages/Support/Support.route';
import { TableauRoute } from '../pages/Tableau/Tableau.route';
import { AuthenticatedRoute } from '../routeGuards/AuthenticatedRoute/AuthenticatedRoute';
import * as routes from '../routes';

export function App() {
	useEffect(() => {
		cleanUpPrintLabelLocalStorage();
	}, []);

	const { data: siteConfig } = useSiteConfig();
	useEffect(() => {
		if (siteConfig?.environment) {
			Sentry.setTag('site-environment', siteConfig.environment);
			Sentry.setTag('site-status', siteConfig.status);
		}
	}, [siteConfig]);

	const content = (
		<>
			{/* Exceptions */}
			<Route path={routes.exceptions()} element={<ExceptionsRoute />} />
			<Route path={routes.exception()} element={<ExceptionRoute />} />

			{/* Orders */}
			<Route path={routes.fulfillmentTracker()} element={<FulfillmentTrackerRoute />} />

			<Route path={routes.orders()} element={<OrdersRoute />} />
			{/* don't move action log because it will parse as an order id! */}
			<Route path={routes.actionLog()} element={<ActionLogRoute />} />
			<Route path={routes.order()} element={<OrderRoute />} />
			<Route path={routes.outboundJobs()} element={<OutboundJobsRoute />} />
			<Route path={routes.outboundJob()} element={<OutboundJobRoute />} />
			<Route path={routes.sortWalls()} element={<SortWallsRoute />} />
			<Route path={routes.sortWall()} element={<SortWallRoute />} />
			<Route path={routes.countJobs()} element={<CountRoute />} />
			<Route path={routes.addJobFlowRule()} element={<AddJobFlowRuleRoute />} />
			<Route path={routes.editJobFlowRule()} element={<EditJobFlowRuleRoute />} />
			<Route path={routes.jobFlowRules()} element={<JobFlowRulesRoute />} />
			<Route path={routes.jobFlowRule()} element={<JobFlowRuleRoute />} />

			{/* Products */}
			<Route path={routes.products()} element={<ProductsRoute />} />
			<Route path={routes.addProduct()} element={<AddProductRoute />} />
			<Route path={routes.product()} element={<ProductRoute />} />
			<Route path={routes.editProduct()} element={<EditProductRoute />} />

			{/* Employees */}
			<Route path={routes.employees()} element={<EmployeesRoute />} />
			<Route path={routes.addEmployee()} element={<EmployeeAddRoute />} />
			<Route path={routes.editEmployee()} element={<EmployeeEditRoute />} />
			<Route path={routes.employee()} element={<EmployeeRoute />} />

			{/* Devices */}
			<Route path={routes.devices()} element={<DevicesRoute />} />
			<Route path={routes.device()} element={<DeviceRoute />} />

			<Route path={routes.editDevice()} element={<DeviceEditRoute />} />
			<Route path={routes.floorView()} element={<FloorViewRoute />} />
			<Route path={routes.allocationRules()} element={<AllocationRulesRoute />} />
			<Route path={routes.support()} element={<SupportRoute />} />
			<Route path={routes.analytics()} element={<TableauRoute />} />

			{/* Putaway */}
			<Route path={routes.putawayJobs()} element={<PutawayJobsRoute />} />
			<Route path={routes.putawayJob()} element={<PutawayJobRoute />} />

			{/* System-Direected Shuttle */}
			<Route path={routes.shuttleJobs()} element={<ShuttleJobsRoute />} />
			<Route path={routes.shuttleJob()} element={<ShuttleJobRoute />} />
			<Route
				path={routes.shuttleAvailableContainers()}
				element={<ShuttleAvailableContainersRoute />}
			/>
			<Route
				path={routes.shuttleAvailableContainer()}
				element={<ShuttleAvailableContainerRoute />}
			/>
			<Route
				path={routes.shuttleAvailableDestinations()}
				element={<ShuttleAvailableDestinationsRoute />}
			/>
			<Route
				path={routes.shuttleAvailableDestination()}
				element={<ShuttleAvailableDestinationRoute />}
			/>

			{/* Locations */}
			<Route path={routes.locations()} element={<LocationsRoute />} />
			<Route path={routes.addLocation()} element={<AddLocationRoute />} />
			<Route path={routes.editLocation()} element={<EditLocationRoute />} />
			<Route path={routes.location()} element={<LocationRoute />} />
			<Route path={routes.locationByAddress()} element={<LocationByAddressRoute />} />

			{/* Containers */}
			<Route path={routes.containers()} element={<ContainersRoute />} />
			<Route path={routes.addContainer()} element={<ContainerAddRoute />} />
			<Route path={routes.editContainer()} element={<ContainerEditRoute />} />
			<Route path={routes.container()} element={<ContainerDetailsRoute />} />

			{/* Pick Waves */}
			<Route path={routes.pickWaves()} element={<PickWavesRoute />} />
			<Route path={routes.pickWave()} element={<PickWaveRoute />} />

			{/* Message logs */}
			<Route path={routes.deliveryLog()} element={<DeliveryLogPage />} />

			{/* Settings */}
			<Route path={routes.settings()} element={<SettingsRoute />} />

			{/* Audit logs */}
			<Route path={routes.auditLogs()} element={<AuditLogsRoute />} />

			{/* Mobile Fulfillment App */}
			<Route path={mfaRoutes.mfas()} element={<MfaEntryRoute />} />

			{/* Default route */}
			<Route path="/" element={<Navigate to={routes.orders()} />} />
		</>
	);

	const router = createBrowserRouter(
		createRoutesFromElements([
			<Route
				key="login"
				path={routes.login()}
				element={
					<LoginRoute>
						<Login />
					</LoginRoute>
				}
			></Route>,
			<Route key="forgot-password" path={routes.forgotPassword()} element={<ForgotPassword />} />,
			<Route key="set-password" path={routes.setPassword()} element={<SetPassword />} />,
			<Route
				key="logout"
				path={routes.logout()}
				element={
					<AuthenticatedRoute>
						<Logout />
					</AuthenticatedRoute>
				}
			></Route>,
			<Route
				key="main"
				path="*"
				element={
					<AuthenticatedRoute>
						<Frame>
							<Routes>{content}</Routes>
							<Toast />
						</Frame>
					</AuthenticatedRoute>
				}
			/>,
		]),
		{
			basename: import.meta.env.BASE_URL,
			future: {
				v7_fetcherPersist: true,
				v7_normalizeFormMethod: true,
				v7_partialHydration: true,
				v7_relativeSplatPath: true,
				v7_skipActionErrorRevalidation: true,
			},
		},
	);

	return (
		<RouterProvider
			router={router}
			future={{
				v7_startTransition: true,
			}}
		/>
	);
}
