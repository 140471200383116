import { UserRole } from '@sixriver/react-support';

import { AddEmployee } from './EmployeeAdd';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function EmployeeAddRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<AddEmployee />
		</AuthorizedRoute>
	);
}
