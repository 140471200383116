import { Route, Routes } from 'react-router-dom';

import { MfaDataProvider } from '.';
import { MfaRoute } from './Mfa/Mfa.route';
import { MfaSplashPage } from './MfaSplashPage';
import { MfasRoute } from './Mfas.route';
import { NewMfaRoute } from './NewMfa/NewMfa.route';
import { useSiteConfig } from '../../hooks/useConfig';

export function MfaEntryRoute() {
	const siteConfig = useSiteConfig();

	if (!siteConfig.data) {
		return null;
	}

	if (!siteConfig?.data?.globalConfig.bridgeUi?.mfa?.enableBridgePage) {
		return <MfaSplashPage />;
	}

	return (
		<MfaDataProvider>
			<Routes>
				{/** Relative paths used due to the route being nested.  LOL-3618 */}
				<Route path={'/new'} element={<NewMfaRoute />} />
				<Route path={'/:mfaId'} element={<MfaRoute />} />
				<Route path={'*'} element={<MfasRoute />} />
			</Routes>
		</MfaDataProvider>
	);
}
