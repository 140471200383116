import { TextStyle } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../../../hooks/useLocalization';
import { endOfLifeDate } from '../lts-dates';

export function EndOfSupport() {
	const { formatDate, messages, translate } = useLocalization();
	return (
		<TextStyle>
			{translate(messages.currentVersion.supportedUntil, {
				date: (
					<time dateTime={endOfLifeDate.toISOString()}>
						{formatDate(endOfLifeDate, { month: 'long' })}
					</time>
				),
			})}
		</TextStyle>
	);
}
