import { PriorityReasons } from '@sixriver/cfs_models';

function htmlColor(rgb: { r: number; g: number; b: number }): string {
	return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
}

/**
 * Telemetry data comes at us every second from every Chuck. The raw values
 * are converted into this structure by the use-telemetry hook.
 */
export interface Telemetry {
	id: string;
	updatedAt: Date;
	faultyAt?: Date;
	idleAt?: Date;
	pausedAt?: Date;
	batteryLevel: number;
	x: number;
	y: number;
	orientation: number;
	destination?: string;
	motionState: 'charging' | 'docking' | 'idle' | 'loading' | 'paused' | 'traveling';
	faults?: Array<{ id: string; code: string; resolution: string }>;
	wifiSignal?: number;
	wifiConnected?: boolean;
	stageUpgradeInfo?: {
		updatedAt: string;
		currentVersion: string;
		nextVersion: string;
		status: 'requested' | 'started' | 'canceled' | 'finished' | 'failed';
	};
	highPriorityReasons: PriorityReasons[];
	highPriorityColor?: string;
}

export function parseTelemetryData(data: any): Telemetry {
	return {
		batteryLevel: data.batteryPercentage,
		destination: data.destination,

		faults: Object.values(data.faultCodes),

		faultyAt: faultyAt(data),

		highPriorityColor:
			data.highPriority?.toteLightColor && htmlColor(data.highPriority?.toteLightColor),
		highPriorityReasons: data.highPriority ? [...data.highPriority.reasons] : [],

		id: data.name,

		idleAt: idleAt(data, 'idle'),

		motionState: data.motionState?.toLowerCase(),

		orientation: data.pose.orientation,

		pausedAt: idleAt(data, 'paused'),

		stageUpgradeInfo: data.stageUpgradeInfo,
		// mfp ID
		updatedAt: new Date(data.updated),
		wifiConnected: data.wifiConnected,
		wifiSignal: data.wifiSignal,
		x: data.pose.x,
		y: data.pose.y,
	};
}

function faultyAt(data: any): Date | undefined {
	const faults = Object.values(data.faultCodes);
	const timestamps = faults.map((f: any) => f.timestamp).sort();

	return timestamps.length ? new Date(timestamps[0]) : undefined;
}

function idleAt(data: any, motionState: 'idle' | 'paused'): Date | undefined {
	const updated = data.fieldUpdated.find((f: any) => f[0] === 'motionState');

	return updated && data.motionState?.toLowerCase() === motionState
		? new Date(updated[1])
		: undefined;
}
