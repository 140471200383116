// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ForceOfflineMutationVariables = Types.Exact<{
  mfpName: Types.Scalars['String']['input'];
}>;


export type ForceOfflineMutation = { __typename?: 'Mutation', forceDeviceOffline?: { __typename?: 'Device', name?: string | null } | null };


export const ForceOfflineDocument = gql`
    mutation fapi_ForceOffline($mfpName: String!) {
  forceDeviceOffline(name: $mfpName) {
    name
  }
}
    `;

export function useForceOfflineMutation() {
  return Urql.useMutation<ForceOfflineMutation, ForceOfflineMutationVariables>(ForceOfflineDocument);
};