import { MagicMinor } from '@sixriver/lighthouse-icons';
import { Banner, LegacyStack, Spinner, Text } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';

interface OrderExpeditedProps {
	isManuallyExpedited: boolean;
	onUnexpedite: () => void;
	expediting: boolean;
	paused?: boolean;
}

export function OrderExpedited({
	isManuallyExpedited,
	onUnexpedite,
	expediting,
	paused,
}: OrderExpeditedProps) {
	const { messages } = useLocalization();
	if (!isManuallyExpedited && expediting) {
		return (
			<Banner title={messages.manualExpedite.expeditingBanner.title}>
				<LegacyStack spacing="loose">
					<Spinner size="small" />{' '}
					<Text as="span">{messages.manualExpedite.expeditingBanner.description}</Text>
				</LegacyStack>
			</Banner>
		);
	}

	if (!isManuallyExpedited) {
		return null;
	}

	if (paused) {
		return (
			<Banner
				status="warning"
				icon={MagicMinor}
				title={messages.manualExpedite.pausedAndExpeditedBanner.title}
				action={{
					content: messages.manualExpedite.pausedAndExpeditedBanner.action,
					loading: expediting,
					onAction: onUnexpedite,
				}}
			>
				<Text as="p">{messages.manualExpedite.pausedAndExpeditedBanner.description}</Text>
			</Banner>
		);
	}

	return (
		<Banner
			status="info"
			icon={MagicMinor}
			title={messages.manualExpedite.expeditedBanner.title}
			action={{
				content: messages.manualExpedite.expeditedBanner.action,
				loading: expediting,
				onAction: onUnexpedite,
			}}
		>
			<Text as="span">{messages.manualExpedite.expeditedBanner.description}</Text>
		</Banner>
	);
}
