import { Page } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StorageLocationForm } from './Location.form';
import { useAddNewLocationMutation } from './graphql/AddLocation.f-api-graphql';
import {
	GetStorageLocationDocument,
	GetStorageLocationQuery,
	GetStorageLocationQueryVariables,
} from './graphql/GetStorageLocation.w-api-graphql';
import { StorageLocationInput, StorageLocationType } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import * as routes from '../../routes';

export function AddLocation(): JSX.Element {
	const navigate = useNavigate();
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const [submitting, setSubmitting] = useState(false);

	// Mutations
	const [addStorageLocationMutation, executeAddStorageLocationMutation] =
		useAddNewLocationMutation();

	useWaitForWapiIngestion<GetStorageLocationQuery, GetStorageLocationQueryVariables>({
		enabled: !!addStorageLocationMutation.data?.addLocation?.referenceId,
		onResponse: (data) => {
			const wapiId = data?.storageLocation?.id;
			if (wapiId) {
				navigate(routes.location(wapiId));
			}
		},
		onTimeout: () => {
			showToast(messages.addProductWapiIngestionError, true);
		},
		query: GetStorageLocationDocument,
		variables: {
			id: addStorageLocationMutation.data?.addLocation?.referenceId ?? '',
		},
	});

	// Methods
	const onSubmit = async (input: StorageLocationInput) => {
		setSubmitting(true);
		await executeAddStorageLocationMutation({ input });
	};

	// Render
	return (
		<Page
			fullWidth
			title={messages.addLocation}
			backAction={{ content: messages.locations, url: routes.locations() }}
		>
			<StorageLocationForm
				mode="add"
				onSubmit={onSubmit}
				error={addStorageLocationMutation.error}
				submitting={submitting}
				data={
					{
						address: '',
						containerTypeId: undefined,
						description: '',
						externalAisleId: messages.none,
						type: StorageLocationType.Bin,
						x: 0,
						y: 0,
						z: 0,
					} as StorageLocationInput
				}
			/>
		</Page>
	);
}
