import { useLocation, useNavigate } from 'react-router-dom';

interface UseFiltersOptions {
	viewKey?: string;
	queryKey?: string;
	sortKey?: string;
}

export const DEFAULT_VIEW_KEY = 'selectedView';
export const DEFAULT_QUERY_KEY = 'query';
export const DEFAULT_SORT_KEY = 'sort';

export function useFilters(
	filterKeys?: string[],
	options?: UseFiltersOptions,
): { [key: string]: string | undefined } {
	const {
		viewKey = DEFAULT_VIEW_KEY,
		queryKey = DEFAULT_QUERY_KEY,
		sortKey = DEFAULT_SORT_KEY,
	} = options || {};

	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const filters = (filterKeys || []).reduce<{ [key: string]: string | undefined }>(
		(accumulator, key) => {
			accumulator[key] = searchParams.get(key) || undefined;
			return accumulator;
		},
		{},
	);

	return {
		...filters,
		query: searchParams.get(queryKey) || undefined,
		sort: searchParams.get(sortKey) || undefined,
		view: searchParams.get(viewKey) || undefined,
	};
}

export interface Param {
	key: string;
	value?: string;
}

export type SetFilters = (params: Param[]) => void;

export function useSetFilters() {
	const navigate = useNavigate();
	const { pathname, search } = useLocation();
	const searchParams = new URLSearchParams(search);
	return (params: Param[]) => {
		params.forEach(({ key, value }) => {
			if (value) {
				searchParams.set(key, value);
			} else {
				searchParams.delete(key);
			}
		});
		navigate(`${pathname}?${searchParams.toString()}`, { replace: true });
	};
}
