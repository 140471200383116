import '@sixriver/lighthouse-web-community/dist/css/bundled.css';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';
import { AppProvider } from './providers/AppProvider';

import './setup-sentry';
import './utils/silence-stupid-polaris-log-spam';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<StrictMode>
		<AppProvider>
			<App />
		</AppProvider>
	</StrictMode>,
);
