import { useState, useCallback } from 'react';

import { useCreateTagMutation } from './graphql/CreateTag.f-api-graphql';
import { useGetTagsQuery } from './graphql/GetTags.f-api-graphql';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { logger } from '../../utils';

interface UseEmployeeTagsHookOptions {
	defaultSelectedTags: string[];
}

export function useEmployeeTags({ defaultSelectedTags }: UseEmployeeTagsHookOptions): any {
	const { messages } = useLocalization();

	// External hooks
	const { showToast } = useToast();

	// State
	const [selectedTags, setSelectedTags] = useState<string[]>(defaultSelectedTags);

	// GQL Mutations
	const [createTagMutation, executeCreateTagMutation] = useCreateTagMutation();

	// GQL Queries
	const [getTagsQuery] = useGetTagsQuery();

	// Query Results
	const allTags = getTagsQuery.data?.searchTags?.map((tag) => tag.name) || [];
	const popularTags = getTagsQuery.data?.getPopularTags?.map((tag) => tag.name) || [];

	// Methods
	const onNewTag = useCallback(
		async (newTag: string): Promise<void> => {
			try {
				await executeCreateTagMutation(
					{
						input: {
							name: newTag,
						},
					},
					{ additionalTypenames: ['Tag'] },
				);
				setSelectedTags((prev) => [...prev, newTag]);
			} catch (err) {
				logger.error({ err }, 'failed setting employee tags');

				showToast(messages.errorToast, true);
			}
		},
		[executeCreateTagMutation, showToast, messages],
	);

	// Computed Values
	const allTagOptions = allTags.map((tag) => ({
		label: tag,
		value: tag,
	}));
	const allTagNames = allTagOptions.map((t) => t.value);
	const availableTagOptions = allTagNames
		.filter((tn) => !selectedTags.includes(tn))
		.map((tagName) => ({ label: tagName, value: tagName }));

	return {
		addNewTagFetching: createTagMutation.fetching,
		addNewTagMutationError: createTagMutation.error,
		allTagOptions,
		allTags,
		availableTagOptions,
		fetchingTags: getTagsQuery.fetching,
		onNewTag,
		popularTags,
		selectedTags,
		setSelectedTags,
		tagsError: getTagsQuery.error,
	};
}
