import { ComplexAction } from '@sixriver/lighthouse-web-community';
import { useMemo, useRef, useState } from 'react';

import { OrderExpedited } from './OrderExpedited';
import { expediteJob } from '../../api';
import {
	GetOrderDocument,
	GetOrderQuery,
	GetOrderQueryVariables,
} from './graphql/GetOrder.w-api-graphql';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';

export function useExpediteJob(job: GetOrderQueryJob | null | undefined) {
	const [expediting, setExpediting] = useState(false);
	const wantsExpedite = useRef<boolean | null>(null);

	const { messages } = useLocalization();

	const expediteButtonContent = useMemo(() => {
		if (expediting) {
			if (wantsExpedite.current) {
				return messages.manualExpedite.action.expediting;
			}
			return messages.manualExpedite.action.unexpediting;
		}

		if (job?.manualExpedite) {
			return messages.manualExpedite.action.unexpedite;
		}

		return messages.manualExpedite.action.expedite;
	}, [
		expediting,
		job?.manualExpedite,
		messages.manualExpedite.action.expedite,
		messages.manualExpedite.action.unexpediting,
		messages.manualExpedite.action.expediting,
		messages.manualExpedite.action.unexpedite,
	]);

	const expediteJobButton: ComplexAction = {
		content: expediteButtonContent,
		disabled: expediting,
		onAction: () => executeExpediteJob(!job?.manualExpedite),
	};

	useWaitForWapiIngestion<GetOrderQuery, GetOrderQueryVariables>({
		enabled: expediting,
		onResponse: (data) => {
			if (data?.job?.manualExpedite === wantsExpedite.current) {
				setExpediting(false);
			}
		},
		onTimeout: () => {
			if (job?.manualExpedite !== wantsExpedite.current) {
				setExpediting(false);
			}
		},
		query: GetOrderDocument,
		timeout: 15_000,
		variables: {
			id: job?.id ?? '',
		},
	});

	const executeExpediteJob = async (shouldExpedite: boolean) => {
		if (expediting) {
			return;
		}

		wantsExpedite.current = shouldExpedite;
		setExpediting(true);
		await expediteJob.setQueryParams({ expedite: shouldExpedite, jobId: job?.id }).send({});
	};

	const expediteBanner = (
		<OrderExpedited
			isManuallyExpedited={!!job?.manualExpedite}
			onUnexpedite={() => executeExpediteJob(false)}
			expediting={expediting}
			paused={job?.manualPause}
		/>
	);

	return {
		expediteBanner,
		expediteJobButton,
	};
}
