import { UserRole } from '@sixriver/react-support';

import { Mfa } from './Mfa';
import { AuthorizedRoute } from '../../../routeGuards/AuthorizedRoute';

export function MfaRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<Mfa />
		</AuthorizedRoute>
	);
}
