import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import {
	GetShuttleAvailableContainersQueryNode,
	GetShuttleAvailableDestinationsQueryNode,
	GetShuttleJobsQueryNode,
} from '../../../graphql/derived';

export function UpdatedAtCell({
	node,
}: {
	node:
		| GetShuttleJobsQueryNode
		| GetShuttleAvailableContainersQueryNode
		| GetShuttleAvailableDestinationsQueryNode;
}) {
	const state = node?.state;
	return (
		<IndexTable.Cell>
			{state === 'running' && (
				<>
					<DateTime date={node?.lastEventPersistedAt} />
					<RelativeDateTime date={node?.lastEventPersistedAt} />
				</>
			)}
			{state === 'cancelled' && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{state === 'terminated' && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{state === 'complete' && (
				<>
					<DateTime date={node?.endedAt} />
					<RelativeDateTime date={node?.endedAt} />
				</>
			)}
			{state === 'ready' && <NoData />}
		</IndexTable.Cell>
	);
}
