import { Button, LegacyCard, LegacyStack } from '@sixriver/lighthouse-web-community';

import { DeviceEditFormInfo } from './Device.utils';
import { DeviceBadge } from './DeviceBadge';
import RelatedJobs from './RelatedJobs';
import RelatedOrders from './RelatedOrders';
import { DeviceType } from '../../api';
import { CardDetails } from '../../components/CardDetails';
import { DateTime } from '../../components/DateTime';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface DeviceActivityProps {
	info: Partial<DeviceEditFormInfo>;
	title?: string;
	findChuck?: boolean;
	flush?: boolean;
	sectioned?: boolean;
}
export function DeviceActivity({ info, title, findChuck, flush, sectioned }: DeviceActivityProps) {
	const isWorkAreasEnabled = useIsWorkAreasEnabled();

	const { messages } = useLocalization();
	const device = info.device;

	return (
		<LegacyCard sectioned={sectioned} title={title}>
			<LegacyCard.Section flush={flush}>
				<CardDetails
					primary={[
						{
							content: device ? <DeviceBadge device={device} /> : <></>,
							label: messages.status,
						},
						{
							content: device ? <DateTime date={device?.updatedAt} /> : <></>,
							label: messages.lastActive,
						},
						...(isWorkAreasEnabled
							? [
									{
										content: device?.currentPose?.mapChunk.displayName,
										label: messages.workArea,
									},
							  ]
							: []),

						...(info.model === DeviceType.TerminalOnWheels || info.model === DeviceType.Handheld
							? []
							: [
									{
										content: device ? <RelatedOrders device={device} /> : <></>,
										label: messages.orders,
									},
									{
										content: device ? <RelatedJobs device={device} /> : <></>,
										label: messages.jobs,
									},
							  ]),
					]}
				/>
			</LegacyCard.Section>
			{findChuck ? (
				<LegacyCard.Section>
					<LegacyStack alignment="baseline">
						{
							// TODO: gimli too
							info.model === DeviceType.Chuck ? (
								<Button plain url={`${routes.floorView()}/?chuck=${device?.id}`}>
									{messages.findThisChuck}
								</Button>
							) : null
						}
					</LegacyStack>
				</LegacyCard.Section>
			) : null}
		</LegacyCard>
	);
}
