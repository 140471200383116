import { AuthProvider } from '@sixriver/react-support';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { PropsWithChildren } from 'react';

import { ErrorBoundary } from '../ErrorBoundary';
import { GraphQLProvider } from '../GraphQLProvider';
import { LighthouseAppProvider } from '../LighthouseAppProvider';
import { PollingProvider } from '../PollingProvider/PollingProvider';
import { ServiceConfigProvider } from '../ServiceConfigProvider/ServiceConfigProvider';
import { ToastProvider } from '../ToastProvider';

const queryClient = new QueryClient();

export function AppProvider({ children }: PropsWithChildren<Record<never, any>>) {
	return (
		<LighthouseAppProvider>
			<ErrorBoundary>
				<PollingProvider>
					<ServiceConfigProvider>
						<GraphQLProvider>
							<QueryClientProvider client={queryClient}>
								<AuthProvider>
									<ToastProvider>{children}</ToastProvider>
								</AuthProvider>
							</QueryClientProvider>
						</GraphQLProvider>
					</ServiceConfigProvider>
				</PollingProvider>
			</ErrorBoundary>
		</LighthouseAppProvider>
	);
}
