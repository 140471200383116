import {
	LegacyCard,
	Layout,
	Modal,
	Page,
	PageActions,
	LegacyStack,
	Tag,
	TextStyle,
	Link,
	PageProps,
} from '@sixriver/lighthouse-web-community';
import { DateTime, UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Employee404 } from './Employee404';
import { EmployeeTimestampsCard } from './EmployeeTimestampsCard';
import { useDeactivateEmployeeMutation } from './graphql/DeactivateEmployee.f-api-graphql';
import { useGetEmployeeQuery } from './graphql/GetEmployee.f-api-graphql';
import { useReactivateEmployeeMutation } from './graphql/ReactivateEmployee.f-api-graphql';
import { CardDetails } from '../../components/CardDetails';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { NoData } from '../../components/NoData';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

// Mocked
export interface EmployeeEvent {
	date: Date;
	type: 'created' | 'updated';
	editedBy?: {
		name: string;
		id: string;
	};
}

export function Employee() {
	const { messages } = useLocalization();

	// State
	const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState<boolean>(false);
	const [isReactivating, setIsReactivating] = useState<boolean>(false);
	const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

	// Auth
	const { getUser } = useAuth();
	const user = getUser();
	const loggedInUserEmail = user?.email;

	// Toasts
	const { showToast } = useToast();

	// Routing
	const { employeeId = '' } = useParams<{
		employeeId: string;
	}>();
	const backAction: PageProps['backAction'] = {
		content: messages.employees,
		url: routes.employees(),
	};
	const navigate = useNavigate();

	// Queries
	const [getEmployeeQuery] = useGetEmployeeQuery({ variables: { id: employeeId } });
	const employee = getEmployeeQuery.data?.user;

	// Timestamps
	const timestamps: EmployeeEvent[] = [
		...(employee?.createdAt
			? [
					{
						date: new Date(employee.createdAt as string),
						// editedBy: {
						// 	id: '123',
						// 	name: 'Bob',
						// },
						type: 'created' as const,
					},
			  ]
			: []),
		...(employee?.updatedAt
			? [
					{
						date: new Date(employee?.updatedAt as string),
						// editedBy: {
						// 	id: '321',
						// 	name: 'Rob',
						// },
						type: 'updated' as const,
					},
			  ]
			: []),
	];

	// Methods
	const [, executeReactivateEmployeeMutation] = useReactivateEmployeeMutation();
	const [, executeDeactivateEmployeeMutation] = useDeactivateEmployeeMutation();
	const openDeactivateModal = () => setIsDeactivateModalOpen(true);

	const closeDeactivateModal = () => setIsDeactivateModalOpen(false);

	const reactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsReactivating(true);

		const { data, error } = await executeReactivateEmployeeMutation({ userId: employee.id });

		setIsReactivating(false);

		if (error || !data?.reactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeReactivated);
		navigate(routes.employees());
	};

	const deactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		if (loggedInUserEmail === employee.email) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsDeactivating(true);

		const { data, error } = await executeDeactivateEmployeeMutation({
			userId: employee.id,
		});

		setIsDeactivating(false);

		closeDeactivateModal();

		if (error || !data?.deactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeDeactivated);
		navigate(routes.inactiveEmployees());
	};

	if (getEmployeeQuery.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (getEmployeeQuery.error || !employee) {
		if (hasMissingEntityError(getEmployeeQuery.error)) {
			return <Employee404 id={employeeId} />;
		}

		return <ErrorPage combinedError={getEmployeeQuery.error} />;
	}

	return (
		<>
			<Page
				title={employee?.name || employee?.badge || employee?.email || '—'}
				backAction={backAction}
			>
				<LegacyStack distribution="trailing" alignment="trailing">
					<Link url={routes.editEmployee(employeeId.toString())} removeUnderline>
						{messages.editEmployee}
					</Link>
				</LegacyStack>

				<br />

				<Layout>
					<Layout.Section>
						{/* Employee Details */}
						<LegacyCard title={messages.employeeDetails} sectioned>
							<CardDetails
								primary={[
									{
										content: employee?.name || <NoData />,
										label: messages.fullName,
									},
									{
										content:
											messages.supportedLocales[
												(employee?.locale as keyof typeof messages.supportedLocales) || 'en-US'
											],
										label: messages.language,
									},
								]}
							/>
						</LegacyCard>

						{/* 6RS Device Credentials */}
						<LegacyCard title={messages.deviceAccess} sectioned>
							{employee?.badge ? (
								<CardDetails
									primary={[
										{
											content: employee?.badge || <NoData />,
											label: messages.badgeBarcode,
										},
										{
											content: employee?.lastLogin ? (
												<DateTime date={employee?.lastLogin} />
											) : (
												<NoData />
											),
											label: messages.lastLogin,
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</LegacyCard>

						{/* Bridge Credentials */}
						<LegacyCard title={messages.bridgeAccess} sectioned>
							{employee?.roles?.includes(UserRole.Admin) ? (
								<CardDetails
									primary={[
										{
											content: employee?.email || <NoData />,
											label: messages.email,
										},
										{
											content: '********',
											label: messages.password,
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</LegacyCard>

						<br />
					</Layout.Section>

					<Layout.Section secondary>
						{/* TAGS  */}
						<LegacyCard title={messages.tags} sectioned>
							<LegacyCard.Section flush>
								<LegacyStack>
									{(employee?.tags || []).map((tag) => {
										return <Tag key={tag.name}>{tag.name}</Tag>;
									})}
								</LegacyStack>
							</LegacyCard.Section>
						</LegacyCard>

						{/* TimeStamps */}
						<EmployeeTimestampsCard timestamps={timestamps} />
					</Layout.Section>
				</Layout>

				<PageActions
					secondaryActions={
						employee.isActive
							? [
									{
										content: messages.deactivateEmployee,
										destructive: true,
										disabled: loggedInUserEmail === employee.email,
										onAction: openDeactivateModal,
										outline: true,
									},
							  ]
							: [
									{
										content: messages.reactivateEmployee,
										destructive: false,
										loading: isReactivating,
										onAction: () => void reactivateEmployee(),
										outline: true,
									},
							  ]
					}
				/>
			</Page>
			<Modal
				title={messages.deactivateEmployee}
				open={isDeactivateModalOpen}
				onClose={closeDeactivateModal}
				primaryAction={{
					content: messages.deactivateEmployee,
					destructive: true,
					disabled: loggedInUserEmail === employee.email,
					loading: isDeactivating,
					onAction: () => void deactivateEmployee(),
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: closeDeactivateModal,
					},
				]}
			>
				<Modal.Section>
					<TextStyle>{messages.confirmDeactivateEmployee}</TextStyle>
				</Modal.Section>
			</Modal>
		</>
	);
}
