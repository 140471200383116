import { Modal, Page, PageActions, PageProps } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { StorageLocationForm } from './Location.form';
import { Location404 } from './Location404';
import { StorageLocationInput, StorageLocationType } from '../../api/fulfillment-api/types';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { useDeleteLocationMutation } from './graphql/DeleteLocation.f-api-graphql';
import { useEditLocationMutation } from './graphql/EditLocation.f-api-graphql';
import {
	GetStorageLocationDocument,
	GetStorageLocationQuery,
	GetStorageLocationQueryVariables,
	useGetStorageLocationQuery,
} from './graphql/GetStorageLocation.w-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function EditLocation(): JSX.Element {
	const navigate = useNavigate();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const [modalVisible, setModalVisible] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const { locationId = '' } = useParams<{ locationId: string }>();
	const backAction: PageProps['backAction'] = {
		content: messages.locations,
		url: routes.location(locationId),
	};
	// Queries
	const [locationByIdQuery] = useGetStorageLocationQuery({
		pause: !locationId,
		requestPolicy: 'network-only',
		variables: {
			id: locationId,
		},
	});

	// Mutations
	const [editLocationMutation, executeEditLocationMutation] = useEditLocationMutation();
	const [deleteLocationMutation, executeDeleteLocationMutation] = useDeleteLocationMutation();

	const [originalUpdatedAt, setOriginalUpdatedAt] = useState<string>();
	useWaitForWapiIngestion<GetStorageLocationQuery, GetStorageLocationQueryVariables>({
		enabled: !!editLocationMutation.data?.editLocation?.success,
		onResponse: (updatedData) => {
			if (!updatedData.storageLocation?.updatedAt) {
				return;
			}

			if (new Date(updatedData.storageLocation.updatedAt) > new Date(originalUpdatedAt!)) {
				navigate(routes.location(locationId));
			}
		},
		onTimeout: () => {
			showToast(messages.editLocationWapiIngestionError, true);
		},
		query: GetStorageLocationDocument,
		variables: {
			id: locationId,
		},
	});

	// Deleting
	useWaitForWapiIngestion<GetStorageLocationQuery, GetStorageLocationQueryVariables>({
		enabled: !!deleteLocationMutation.data?.deleteLocation?.success,
		onResponse: (updatedData) => {
			if (!updatedData.storageLocation) {
				navigate(routes.locations());
			}
		},
		onTimeout: () => {
			showToast(messages.deleteLocationWapiIngestionError, true);
		},
		query: GetStorageLocationDocument,
		variables: {
			id: locationId,
		},
	});

	const storageLocation = locationByIdQuery.data?.storageLocation;

	const onEdit = async (input: StorageLocationInput) => {
		setSubmitting(true);
		setOriginalUpdatedAt(storageLocation?.updatedAt);
		await executeEditLocationMutation({ input });
	};

	const onDelete = async () => {
		setDeleting(true);
		await executeDeleteLocationMutation({ id: locationId });
	};

	if (
		locationByIdQuery.fetching ||
		// Prevent a flash of an error page when the location is deleted but we're waiting
		// for WAPI ingestion to complete
		(!locationByIdQuery.data?.storageLocation &&
			deleteLocationMutation.data?.deleteLocation.success)
	) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (locationByIdQuery.error || !storageLocation) {
		if (hasMissingEntityError(locationByIdQuery.error)) {
			return <Location404 id={locationId} />;
		}
		return <ErrorPage combinedError={locationByIdQuery.error} />;
	}

	const subtitle =
		storageLocation.type in messages.locationTypes
			? messages.locationTypes[storageLocation.type as StorageLocationType]
			: messages.locationTypes.unknown;

	return (
		<Page fullWidth title={storageLocation.address} subtitle={subtitle} backAction={backAction}>
			<StorageLocationForm
				mode="edit"
				onSubmit={onEdit}
				error={editLocationMutation.error || deleteLocationMutation.error}
				submitting={submitting}
				data={
					{
						address: storageLocation?.address,
						containerTypeId: storageLocation?.containerType?.id,
						description: storageLocation?.description,
						// replace `null` with the empty string so it aligns with the visual "None"
						// option. The selection mapping is also setup in Location.form.tsx.
						externalAisleId: storageLocation?.externalAisleId ?? '',

						id: storageLocation?.id,

						type: storageLocation?.type as StorageLocationType,

						x: storageLocation?.coordinates?.x || 0,
						y: storageLocation?.coordinates?.y || 0,
						z: storageLocation?.coordinates?.z || 0,
					} as StorageLocationInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteLocation,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteLocation}
				onClose={() => !deleting && setModalVisible(false)}
				primaryAction={{
					content: messages.deleteLocation,
					destructive: true,
					loading: deleting,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						disabled: deleting,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteLocation}</Modal.Section>
			</Modal>
		</Page>
	);
}
