import { Badge } from '@sixriver/lighthouse-web-community';

import { GoalState } from '../../../api/warehouse-api/types';
import { NoData } from '../../../components/NoData';
import { useLocalization } from '../../../hooks/useLocalization';
import { logger } from '../../../utils';

const statusToBadgeStatus: Record<GoalState, 'info' | 'critical' | 'warning' | undefined> = {
	[GoalState.CancelRequested]: 'critical',
	[GoalState.Cancelled]: 'critical',
	[GoalState.Cancelling]: 'critical',
	[GoalState.Complete]: undefined,
	[GoalState.Created]: 'warning',
	[GoalState.Creating]: 'warning',
	[GoalState.Failed]: 'critical',
	[GoalState.Hold]: undefined,
	[GoalState.Paused]: 'warning',
	[GoalState.Ready]: 'warning',
	[GoalState.Running]: 'info',
	[GoalState.TerminateRequested]: 'critical',
	[GoalState.Terminated]: 'critical',
	[GoalState.Terminating]: 'critical',
};

export function ShuttleJobStatus({
	status,
	isDestination,
}: {
	status: GoalState | undefined;
	isDestination?: boolean;
}) {
	const { messages } = useLocalization();

	const labels: Record<GoalState, string> = {
		[GoalState.CancelRequested]: messages.goalStates.cancelRequested,
		[GoalState.Cancelled]: messages.canceled,
		[GoalState.Cancelling]: messages.goalStates.cancelling,
		[GoalState.Complete]: messages.completed,
		[GoalState.Created]: messages.unassigned,
		[GoalState.Creating]: messages.unassigned,
		[GoalState.Failed]: messages.goalStates.failed,
		[GoalState.Hold]: messages.queued,
		[GoalState.Paused]: isDestination ? messages.unassigned : messages.interrupted,
		[GoalState.Ready]: messages.unassigned,
		[GoalState.Running]: messages.inProgress,
		[GoalState.TerminateRequested]: messages.goalStates.terminateRequested,
		[GoalState.Terminated]: messages.canceled,
		[GoalState.Terminating]: messages.goalStates.terminating,
	};

	return status ? <Badge status={statusToBadgeStatus[status]}>{labels[status]}</Badge> : <NoData />;
}

const unassignedStates: ReadonlyArray<GoalState> = [
	GoalState.Creating,
	GoalState.Created,
	GoalState.Ready,
] as const;

export function ShuttleJobCombinedStatus({
	pickupStatus,
	pickupEnded,
	dropoffStatus,
	dropoffEnded,
}: {
	pickupStatus?: GoalState;
	pickupEnded?: boolean;
	dropoffStatus?: GoalState;
	dropoffEnded?: boolean;
}) {
	const { messages } = useLocalization();

	if (!pickupEnded) {
		if (!pickupStatus) {
			return <NoData />;
		} else if (unassignedStates.includes(pickupStatus)) {
			return <Badge status={statusToBadgeStatus.ready}>{messages.unassigned}</Badge>;
		} else if (pickupStatus === 'paused') {
			return <Badge status="warning">{messages.shuttle.pickupInterrupted}</Badge>;
		} else {
			return <Badge status="info">{messages.shuttle.pickupInProgress}</Badge>;
		}
	} else if (!dropoffEnded) {
		if (!dropoffStatus) {
			// WAT
			logger.error(
				{ dropoffEnded, dropoffStatus, pickupEnded, pickupStatus },
				'No shuttle dropoff status despite completed pickup',
			);
			return <NoData />;
		} else if (dropoffStatus === 'paused') {
			return <Badge status="warning">{messages.shuttle.dropoffInterrupted}</Badge>;
		} else {
			return <Badge status="info">{messages.shuttle.dropoffInProgress}</Badge>;
		}
	} else {
		const state = dropoffStatus || pickupStatus;
		if (!state) {
			// WAT
			logger.error(
				{ dropoffEnded, dropoffStatus, pickupEnded, pickupStatus },
				'No shuttle status despite completed pickup and dropoff',
			);
			return <NoData />;
		} else {
			return <ShuttleJobStatus status={state} />;
		}
	}
}
