import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Device404Props {
	variant: 'device' | 'mfpConfig';
	id: string;
}

export function Device404({ variant, id }: Device404Props) {
	const { messages, translate } = useLocalization();

	const message =
		variant === 'device' ? messages.device404.deviceMissing : messages.device404.configMissing;
	return (
		<NotFoundPage
			heading={messages.device404.title}
			message={translate(message, { id })}
			action={{
				content: messages.device404.action,
				url: routes.devices(),
			}}
		/>
	);
}
