import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface OutboundJob404Props {
	id: string;
}

export function OutboundJob404({ id }: OutboundJob404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.outboundJob404.title}
			message={translate(messages.outboundJob404.message, { id })}
			action={{
				content: messages.outboundJob404.action,
				url: routes.outboundJobs(),
			}}
		/>
	);
}
