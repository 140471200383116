import { CircleTickOutlineMinor, CircleDisableMinor } from '@sixriver/lighthouse-icons';
import {
	Layout,
	LegacyCard,
	Banner,
	Thumbnail,
	LegacyStack,
	TextContainer,
	Icon,
	PageProps,
} from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Product404 } from './Product404';
import {
	GetProductDocument,
	GetProductQuery,
	GetProductQueryVariables,
} from './graphql/GetProduct.w-api-graphql';
import { ProductKind } from '../../api/fulfillment-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { CardDetails } from '../../components/CardDetails';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import { transformImageUrl } from '../../utils';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function ProductDetails() {
	const { messages, translate, formatLength, formatWeight } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.products,
		url: routes.products(),
	};
	const { productId = '' } = useParams<{ productId: string }>();

	const [getProductQuery] = usePollingQuery<GetProductQuery, GetProductQueryVariables>({
		query: GetProductDocument,
		variables: {
			id: productId,
		},
	});
	const product = getProductQuery.data?.product;
	const imgPath = product?.image;
	const imgDesc = product?.description || '';
	const isContainer = product?.kind === ProductKind.Packaging;

	if (getProductQuery.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (getProductQuery.error || !product) {
		if (hasMissingEntityError(getProductQuery.error)) {
			return <Product404 id={productId} />;
		}

		return <ErrorPage combinedError={getProductQuery.error} />;
	}

	return (
		<AutoRefreshPage
			queries={[getProductQuery]}
			title={product?.customerIdentifier}
			backAction={backAction}
			secondaryActions={[
				{
					content: messages.edit,
					disabled: isContainer,
					url: routes.editProduct(product?.id),
				},
			]}
		>
			<Layout>
				<Layout.Section>
					<LegacyCard sectioned title={messages.productDetails}>
						<CardDetails
							loading={getProductQuery.fetching}
							primary={[
								{
									content: product?.customerIdentifier,
									label: messages.item,
								},
								{
									content: product?.name,
									label: messages.name,
								},
								{
									content: product?.description,
									label: messages.description,
								},
								{
									content: product?.scanValues.join(', ') || <NoData />,
									label: messages.identifiers,
								},
								{
									content: isContainer
										? translate(messages.productTypes.packaging)
										: translate(messages.productTypes.product),
									label: messages.productType,
								},
								{
									content: imgPath ? (
										<Thumbnail source={transformImageUrl(imgPath)} alt={imgDesc} size="large" />
									) : (
										<NoData />
									),
									label: messages.image,
								},
							]}
						/>
						{isContainer ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewContainer,
										url: routes.container(product?.id),
									}}
									status="info"
								>
									<p>{messages.productIsContainer}</p>
								</Banner>
							</>
						) : null}
					</LegacyCard>
				</Layout.Section>
				<Layout.Section oneHalf>
					<LegacyCard sectioned title={messages.dimensions}>
						<LegacyStack distribution="fillEvenly">
							<TextContainer>{messages.length}</TextContainer>
							<TextContainer>
								{isContainer ? 'N/A' : formatLength(product?.length ?? 0)}
							</TextContainer>
						</LegacyStack>
						<LegacyStack distribution="fillEvenly">
							<TextContainer>{messages.width}</TextContainer>
							<TextContainer>
								{isContainer ? 'N/A' : formatLength(product?.width ?? 0)}
							</TextContainer>
						</LegacyStack>
						<LegacyStack distribution="fillEvenly">
							<TextContainer>{messages.height}</TextContainer>
							<TextContainer>
								{isContainer ? 'N/A' : formatLength(product?.height ?? 0)}
							</TextContainer>
						</LegacyStack>
						<LegacyStack distribution="fillEvenly">
							<TextContainer>{messages.weight}</TextContainer>
							<TextContainer>
								{isContainer ? 'N/A' : formatWeight(product?.weight ?? 0)}
							</TextContainer>
						</LegacyStack>
					</LegacyCard>
				</Layout.Section>
				<Layout.Section oneHalf>
					<LegacyCard sectioned title={messages.skuAttributes}>
						{product?.attributes?.lot ? (
							<LegacyStack>
								<Icon source={CircleTickOutlineMinor} />
								<p>{messages.isLotTracked}</p>
							</LegacyStack>
						) : (
							<LegacyStack>
								<Icon source={CircleDisableMinor} />
								<p>{messages.isNotLotTracked}</p>
							</LegacyStack>
						)}
						{product?.attributes?.expiresAt ? (
							<LegacyStack>
								<Icon source={CircleTickOutlineMinor} />
								<p>{messages.isExpirationTracked}</p>
							</LegacyStack>
						) : (
							<LegacyStack>
								<Icon source={CircleDisableMinor} />
								<p>{messages.isNotExpirationTracked}</p>
							</LegacyStack>
						)}
					</LegacyCard>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
