import { ComplexAction } from '@sixriver/lighthouse-web-community';
import { useMemo, useRef, useState } from 'react';

import { OrderManuallyPaused } from './OrderManuallyPaused';
import { pauseJob } from '../../api';
import {
	GetOrderDocument,
	GetOrderQuery,
	GetOrderQueryVariables,
} from './graphql/GetOrder.w-api-graphql';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';

export function usePauseJob(job: GetOrderQueryJob | null | undefined) {
	const [pausing, setPausing] = useState(false);
	const wantsPause = useRef<boolean | null>(null);
	const { messages } = useLocalization();

	const pauseButtonContent = useMemo(() => {
		if (pausing) {
			if (wantsPause.current) {
				return messages.manualPause.action.pausing;
			}
			return messages.manualPause.action.unpausing;
		}

		if (job?.manualPause) {
			return messages.manualPause.action.unpause;
		}

		return messages.manualPause.action.pause;
	}, [
		job?.manualPause,
		messages.manualPause.action.pause,
		messages.manualPause.action.pausing,
		messages.manualPause.action.unpause,
		messages.manualPause.action.unpausing,
		pausing,
	]);

	const pauseJobButton: ComplexAction = {
		content: pauseButtonContent,
		disabled: pausing,
		onAction: () => executePauseJob(!job?.manualPause),
	};

	useWaitForWapiIngestion<GetOrderQuery, GetOrderQueryVariables>({
		enabled: pausing,
		onResponse: (data) => {
			if (data?.job?.manualPause === wantsPause.current) {
				setPausing(false);
			}
		},
		onTimeout: () => {
			if (job?.manualPause !== wantsPause.current) {
				setPausing(false);
			}
		},
		query: GetOrderDocument,
		timeout: 15_000,
		variables: {
			id: job?.id ?? '',
		},
	});

	const executePauseJob = async (shouldPause: boolean) => {
		if (pausing) {
			return;
		}

		wantsPause.current = shouldPause;
		setPausing(true);
		await pauseJob.setQueryParams({ jobId: job?.id, pause: shouldPause }).send({});
	};

	const pauseBanner = (
		<OrderManuallyPaused
			isManuallyPaused={!!job?.manualPause}
			onUnpause={() => executePauseJob(false)}
			pausing={pausing}
		/>
	);

	return {
		pauseBanner,
		pauseJobButton,
	};
}
