import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Employee404Props {
	id: string;
}

export function Employee404({ id }: Employee404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.employee404.title}
			message={translate(messages.employee404.message, { id })}
			action={{
				content: messages.employee404.action,
				url: routes.employees(),
			}}
		/>
	);
}
