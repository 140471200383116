import { Badge, Button, LegacyStack } from '@sixriver/lighthouse-web-community';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import {
	GetPickWavesCountDocument,
	GetPickWavesCountQuery,
	GetPickWavesCountQueryVariables,
} from './graphql/GetPickWavesCount.f-api-graphql';
import { usePollingQuery } from '../../hooks/usePollingQuery';

export function PickWavesLogButton() {
	const { messages } = useLocalization();

	const navigate = useNavigate();
	const onClick = useCallback(() => {
		navigate(routes.pickWaves());
	}, [navigate]);

	// Fetch the count of pick waves that have failed and have not been resolved
	const [getPickwavesCountQuery] = usePollingQuery<
		GetPickWavesCountQuery,
		GetPickWavesCountQueryVariables
	>({
		query: GetPickWavesCountDocument,
	});

	const count = getPickwavesCountQuery.data?.errorPickWavesCount.count ?? 0;

	return (
		<LegacyStack spacing="extraTight">
			<Button plain monochrome onClick={onClick}>
				{messages.pickWaveLog}
			</Button>
			<Badge status={count > 0 ? 'critical' : undefined}>{String(count)}</Badge>
		</LegacyStack>
	);
}
