import { Page, PageProps } from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo } from 'react';
import { Params, useNavigate, useParams } from 'react-router-dom';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getEmployeeFormDataFromExistingEmployee, getRolesFromFormData } from './Employee.utils';
import { Employee404 } from './Employee404';
import { useGetEmployeeQuery } from './graphql/GetEmployee.f-api-graphql';
import { useUpdateUserMutation } from './graphql/UpdateUser.f-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function EmployeeEdit() {
	const navigate = useNavigate();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const { employeeId = '' } = useParams<{ employeeId: string }>();
	const params = useParams<Params>();
	const backAction: PageProps['backAction'] = {
		content: messages.employee,
		url: routes.employee(params?.employeeId),
	};
	// Queries
	const [getEmployeeQuery] = useGetEmployeeQuery({ variables: { id: employeeId } });

	const employee = getEmployeeQuery.data?.user;
	const formDefaults: EmployeeFormData = useMemo(
		() => getEmployeeFormDataFromExistingEmployee(employee),
		[employee],
	);

	// Mutations
	const [updateUserMutation, executeUpdateUserMutation] = useUpdateUserMutation();

	// Methods
	const onEdit = useCallback(
		async (formData: EmployeeFormData) => {
			const { data } = await executeUpdateUserMutation({
				input: {
					badgeBarcode: formData.badgeBarcode || null,
					email: formData.email || null,
					id: employeeId,
					locale: formData.locale || null,
					name: formData.name || null,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					tags: formData.tags.map((tag) => ({ name: tag })),
				},
			});

			if (!data?.updateUser?.success || !data.updateUser?.referenceId) {
				showToast(messages.errorToast, true);
				return;
			}

			setTimeout(() => {
				navigate(routes.employee(data.updateUser.referenceId!));
			}, 500);
		},
		[executeUpdateUserMutation, employeeId, showToast, messages.errorToast, navigate],
	);

	if (getEmployeeQuery.fetching) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (getEmployeeQuery.error || !employee) {
		if (hasMissingEntityError(getEmployeeQuery.error)) {
			return <Employee404 id={employeeId} />;
		}

		return <ErrorPage combinedError={getEmployeeQuery.error} />;
	}

	return (
		<Page fullWidth title={formDefaults.name || messages.editEmployee} backAction={backAction}>
			<EmployeeForm
				mode="edit"
				onSubmit={onEdit}
				data={formDefaults}
				error={updateUserMutation.error}
			/>
		</Page>
	);
}
