import { IndexTable, LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';
import { NoData } from '@sixriver/react-support';
import { useState } from 'react';

import { PickWaveStatus } from '../../../api/fulfillment-api/types';
import { PickWaveFieldsFragment } from '../graphql/PickWaveFields.f-api-graphql';
import { isPickWaveOrderDropped } from '../helpers';

interface OrdersDroppedCellProps {
	pickWave: PickWaveFieldsFragment;
}

export function OrdersDroppedCell({ pickWave }: OrdersDroppedCellProps) {
	const [droppedOrders] = useState(
		pickWave.orders.edges?.filter((orderEdge) => isPickWaveOrderDropped(orderEdge.node)),
	);

	if (pickWave.status === PickWaveStatus.Success) {
		return (
			<IndexTable.Cell>
				<LegacyStack vertical spacing="extraTight">
					<NoData />
				</LegacyStack>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<LegacyStack vertical spacing="extraTight">
				<TextStyle variation={pickWave.resolvedAt ? undefined : 'negative'}>
					{droppedOrders?.length}
				</TextStyle>
				<TextStyle variation="subdued">{pickWave.orders.edges?.length} total</TextStyle>
			</LegacyStack>
		</IndexTable.Cell>
	);
}
