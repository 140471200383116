import { CirclePlusMinor, MagicMinor, PauseMinor } from '@sixriver/lighthouse-icons';
import { Badge, Tooltip, Icon, LegacyStack } from '@sixriver/lighthouse-web-community';

import { OrderExceptionIcon } from '../../components/OrderExceptionIcon/OrderExceptionIcon';
import { GetOrderQueryJob } from '../../graphql/derived';
import { useLocalization } from '../../hooks/useLocalization';
import { GetOrdersOrderFragment } from '../../pages/Orders/graphql/GetOrders.w-api-graphql';

interface Props {
	order?: GetOrdersOrderFragment | GetOrderQueryJob;
}

export function OrderStatusBadgeV2({ order }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!order) {
		return null;
	}

	const { workflows = [] } = order;
	let badge = <Badge status="warning">{messages.unassigned}</Badge>;

	if (order.manualExpedite) {
		badge = (
			<Badge status="info" icon={MagicMinor}>
				{messages.manualExpedite.badge}
			</Badge>
		);
	}

	if (order.manualPause) {
		badge = (
			<Badge status="critical" icon={PauseMinor}>
				{messages.manualPause.badge}
			</Badge>
		);
	}

	if (order.status === 'Ready' || order.status === 'InProgress') {
		badge = <Badge status="info">{messages.inProgress}</Badge>;
	}

	if (order.status === 'Assigned') {
		const sortWorkflow = workflows.find((workflow) => workflow.type === 'SORT');

		if (sortWorkflow?.status === 'ASSIGNED') {
			badge = <Badge status="info">{messages.assignedToWall}</Badge>;
		} else {
			badge = <Badge status="info">{messages.assigned}</Badge>;
		}
	}

	if (order.status === 'Done') {
		badge = <Badge>{messages.completed}</Badge>;
	}

	if (order.status === 'Canceled') {
		badge = <Badge>{messages.canceled}</Badge>;
	}

	if (order.status === 'Interrupted') {
		badge = <Badge>{messages.interrupted}</Badge>;
	}

	if (order.status === 'AwaitingUdi') {
		badge = <Badge>{messages.awaitingUDI}</Badge>;
	}

	if (order.status === 'Unknown') {
		badge = <Badge status="warning">{messages.unknown}</Badge>;
	}

	let iconAwaitingInventory;

	if (order.isAwaitingInventory) {
		iconAwaitingInventory = (
			<Tooltip content={messages.orderAwaitingInventory} dismissOnMouseOut>
				<Icon source={CirclePlusMinor} color="warning" />
			</Tooltip>
		);
	}

	return (
		<LegacyStack spacing="extraTight" wrap={false}>
			{badge}
			<OrderExceptionIcon order={order} />
			{iconAwaitingInventory}
		</LegacyStack>
	);
}
