import { ChevronUpMinor, ChevronDownMinor } from '@sixriver/lighthouse-icons';
import {
	LegacyCard,
	Collapsible,
	Heading,
	Icon,
	LegacyStack,
} from '@sixriver/lighthouse-web-community';

import styles from './Shuttle.module.css';

interface Props {
	title: string;
	onHeaderClick(): void;
	iconStyle?: React.CSSProperties;
	open: boolean;
	icon: string;
	color?: string;
}

export function CollapsibleShuttlePointCard({
	children,
	title,
	onHeaderClick,
	open,
	icon,
	color,
}: React.PropsWithChildren<Props>) {
	return (
		<div className={styles.cardOuter}>
			<LegacyCard>
				<header
					className={styles.collapsibleCardHeader}
					role="button"
					onClick={onHeaderClick}
					aria-expanded={open}
				>
					<LegacyStack distribution="equalSpacing">
						<svg width="24" height="24" style={{ color }}>
							<use xlinkHref={icon}></use>
						</svg>
						<Heading>{title}</Heading>
						<Icon source={open ? ChevronUpMinor : ChevronDownMinor} />
					</LegacyStack>
				</header>
				<Collapsible
					open={open}
					id={title.replace(' ', '-')}
					transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
					expandOnPrint
				>
					{children}
				</Collapsible>
			</LegacyCard>
		</div>
	);
}
