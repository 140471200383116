import type { LineLocationCapturedDataValue } from '@sixriver/inventory-movement-oas';
import { Badge, LegacyCard, LegacyStack } from '@sixriver/lighthouse-web-community';

import { CardDetails } from '../../components/CardDetails';
import { useLocalization } from '../../hooks/useLocalization';
import { ShuttleJobCombinedStatus, StorageAddressLink } from '../ShuttleJobs/ShuttleJobsTable';
import {
	GetShuttleJobLineFragment,
	GetShuttleJobQuery,
} from './graphql/GetShuttleJob.w-api-graphql';
import { ListItem } from '../../components/CardDetails/CardDetails';

export function Progress({ shuttleJob }: GetShuttleJobQuery) {
	const { messages } = useLocalization();

	// count the number of exceptions on each line
	const numberOfExceptions =
		shuttleJob?.lines.reduce((acc: number, line: any) => acc + numLineExceptions(line), 0) || 0;

	// while lines will _usually_ be in chronological order, we can't guarantee it.
	const lines = shuttleJob?.lines ?? [];
	lines.sort((a, b) => (new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1));

	const pickupLine = lines.find((l) => l.type === 'storage-to-container');
	const dropoffLines = lines.filter((l) => l.type === 'container-to-storage');
	const lastDropoffLine = dropoffLines[dropoffLines.length - 1];

	return (
		<LegacyCard sectioned title={messages.progress}>
			<CardDetails
				primary={[
					{
						content: (
							<ShuttleJobCombinedStatus
								pickupStatus={pickupLine?.state}
								pickupEnded={!!pickupLine?.endedAt}
								dropoffStatus={lastDropoffLine?.state}
								dropoffEnded={!!lastDropoffLine?.endedAt}
							/>
							// TODO: dropoffStatus/dropoffEnded may produce misleading results
							// if the last dropoff failed and it's waiting to find a new one.
						),
						label: messages.shuttle.status,
					},
					{
						content: <SALWithException line={pickupLine!} />,
						label: messages.shuttle.pickupFrom,
					},
					dropoffLines.map((dl) => ({
						content: <SALWithException line={dl} />,
						label: messages.shuttle.dropoffAt,
					})),
				]}
				secondary={[
					{
						content: <NumExceptionsBadge numberOfExceptions={numberOfExceptions} />,
						label: messages.exceptions,
					},
					...exceptionsSummary(lines, messages),
				]}
			/>
		</LegacyCard>
	);
}

function NumExceptionsBadge({ numberOfExceptions }: { numberOfExceptions: number }) {
	return (
		<Badge status={numberOfExceptions ? 'critical' : undefined}>
			{numberOfExceptions.toString()}
		</Badge>
	);
}

function lineLocation(line: GetShuttleJobLineFragment | undefined): string | undefined {
	// no imports from inventory-movement-oas here :(
	const rs = line?.inputs.reservationSources[0]; /* as ContainerLineLocationSource */
	if (!!rs && typeof rs === 'object' && rs.type === 'location' && typeof rs.address === 'string') {
		return rs.address;
	}
	return undefined;
}

function numLineExceptions(line: GetShuttleJobLineFragment | undefined) {
	// this counts each transfer that has any exception as one exception, since we
	// often bundle reasons for a single error to help categorization.
	const transfers = line?.outputs.transfers ?? [];
	return transfers.reduce((sum, t) => sum + (t.exceptions?.length ? 1 : 0), 0);
}

function SALWithException({ line }: { line: GetShuttleJobLineFragment }) {
	const numExceptions = numLineExceptions(line);
	if (!numExceptions) {
		return <StorageAddressLink address={lineLocation(line)} />;
	}
	return (
		<LegacyStack spacing="extraTight" wrap={false}>
			<StorageAddressLink address={lineLocation(line)} />
			<Badge status="critical">{numExceptions.toString()}</Badge>
		</LegacyStack>
	);
}

function exceptionsSummary(
	lines: GetShuttleJobLineFragment[],
	messages: ReturnType<typeof useLocalization>['messages'],
) {
	const ret: ListItem[] = [];

	for (const l of lines) {
		const numExceptions = numLineExceptions(l);
		if (!numExceptions) {
			continue;
		}
		const tl =
			l.type === 'storage-to-container' ? messages.shuttle.pickupFrom : messages.shuttle.dropoffAt;
		const lEx = l.outputs.transfers.flatMap((t) => t.exceptions ?? []);
		const lForkErrors = l.outputs.transfers
			.flatMap((t) => t.capturedData ?? [])
			.flatMap((cd) => cd.group as LineLocationCapturedDataValue[])
			.filter((g) => g.item === 'forklift:errorCode')
			.map((g) => g.value as string);
		const ex = lEx.concat(lForkErrors);
		// TODO: remove dupes?
		ret.push({
			content: <>{ex.join(', ')}</>,
			label: `${tl} ${lineLocation(l)}`,
		});
	}

	return ret;
}
