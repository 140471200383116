import { Exchange, OperationResult } from 'urql';
import { map, pipe, tap } from 'wonka';

/**
 * Custom exchange for urql to retain the last successful data for each operation.
 *
 * By default, urql clears the last successful data on an error.  In the event of
 * a transient network error or service restart, this leads to the UI losing context
 * from the last successful call and the user noticing the error.
 *
 * This exchange caches the last successful data for each operation key and adds
 * it to the result, allowing the UI to choose if it cares about the error or the data.
 *
 * This is default behaviour in some other GraphQL clients like Apollo.
 */
export const dataRetentionExchange: Exchange = ({ forward }) => {
	// Map to store the last successful data for each operation key
	const operationDataMap = new Map<number, any>();

	return (ops$) => {
		return pipe(
			forward(ops$),
			tap((result: OperationResult) => {
				if (!result.error) {
					// Store the data keyed by the operation's unique key
					operationDataMap.set(result.operation.key, result.data);
				}
			}),
			map((result: OperationResult) => {
				if (result.error && !result.data) {
					// On error, check if we have previous data for this operation
					const lastData = operationDataMap.get(result.operation.key);
					if (lastData) {
						// Return result with last known data and the error
						return { ...result, data: lastData };
					}
				}
				// Return the result as is if no cached data is found
				return result;
			}),
		);
	};
};
