import {
	LegacyCard,
	Page,
	Pagination,
	LegacyStack,
	PageProps,
} from '@sixriver/lighthouse-web-community';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PickWave404 } from './PickWave404';
import { PickWaveDetails } from './PickWaveDetails';
import { PickWaveOrdersFilter, PickWaveOrdersFilters } from './PickWaveOrdersFilters';
import { PickWaveOrdersTable } from './PickWaveOrdersTable';
import { PickWaveStatusBadge } from './PickWaveStatusBadge';
import { ResolvePickWaveButton } from './ResolvePickWaveButton';
import {
	GetPickWaveDocument,
	GetPickWaveQuery,
	GetPickWaveQueryVariables,
} from './graphql/GetPickWave.f-api-graphql';
import {
	GetPickWaveOrdersDocument,
	GetPickWaveOrdersQuery,
	GetPickWaveOrdersQueryVariables,
} from './graphql/GetPickWaveOrders.f-api-graphql';
import { useResolvePickWavesMutation } from './graphql/ResolvePickWaves.f-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';

const DEFAULT_FILTER: PickWaveOrdersFilter = { searchText: undefined, statuses: [] };

export function PickWave() {
	const { pickWaveId = '' } = useParams<{
		pickWaveId: string;
	}>();
	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.pickWaves,
		url: routes.pickWaves(),
	};
	const [getPickWaveQuery, refreshGetPickWaveQuery] = usePollingQuery<
		GetPickWaveQuery,
		GetPickWaveQueryVariables
	>({
		query: GetPickWaveDocument,
		variables: { id: pickWaveId },
	});
	const pickWave = getPickWaveQuery.data?.pickWave;

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	/**
	 * Pick Wave Orders Query
	 */
	const [ordersFilter, setOrdersFilters] = useState<PickWaveOrdersFilter>(DEFAULT_FILTER);
	const [getPickWaveOrdersQuery] = usePollingQuery<
		GetPickWaveOrdersQuery,
		GetPickWaveOrdersQueryVariables
	>({
		query: GetPickWaveOrdersDocument,
		variables: {
			...ordersFilter,
			after: paginationCursors[0],
			first: 50,
			pickWaveIds: [pickWaveId],
		},
	});
	const pickWaveOrders = useMemo(() => {
		return getPickWaveOrdersQuery.data?.pickWaveOrders.edges?.map((edge) => edge.node) ?? [];
	}, [getPickWaveOrdersQuery.data?.pickWaveOrders]);

	const getPickWaveOrdersPageInfo = useMemo(() => {
		return getPickWaveOrdersQuery.data?.pickWaveOrders.pageInfo;
	}, [getPickWaveOrdersQuery.data?.pickWaveOrders.pageInfo]);

	const handleOnNextPage = useCallback(() => {
		if (!getPickWaveOrdersPageInfo) {
			return;
		}
		const { endCursor } = getPickWaveOrdersPageInfo;
		if (endCursor) {
			setPaginationCursors((prev) => {
				return [endCursor].concat(prev);
			});
		}
	}, [getPickWaveOrdersPageInfo]);
	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	/**
	 * Resolve Pick Wave
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [_, executeResolvePickWaveMutation] = useResolvePickWavesMutation();
	const handleResolvePickWave = useCallback(async () => {
		if (!!pickWave) {
			const result = await executeResolvePickWaveMutation({ ids: [pickWave.id] });

			if (!result.error) {
				await refreshGetPickWaveQuery();
			}
		}
	}, [executeResolvePickWaveMutation, pickWave, refreshGetPickWaveQuery]);

	if (getPickWaveQuery.fetching) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (getPickWaveQuery.error || !pickWave) {
		if (getPickWaveQuery.error?.graphQLErrors.some((err) => err.message.includes('404'))) {
			return <PickWave404 id={pickWaveId} />;
		}
		return <ErrorPage combinedError={getPickWaveQuery.error} />;
	}

	if (!pickWave) {
		return <h1>{messages.pickWaveNotFound}</h1>;
	}

	return (
		<Page
			fullWidth
			backAction={backAction}
			title={pickWave.id}
			titleMetadata={<PickWaveStatusBadge pickWave={pickWave} />}
			primaryAction={<ResolvePickWaveButton pickWave={pickWave} onClick={handleResolvePickWave} />}
		>
			<PickWaveDetails pickWave={pickWave} />
			<LegacyCard title="Orders">
				<LegacyCard.Section>
					<PickWaveOrdersFilters filter={ordersFilter} onChange={setOrdersFilters} />
				</LegacyCard.Section>
				<PickWaveOrdersTable loading={getPickWaveOrdersQuery.fetching} orders={pickWaveOrders} />
				<LegacyCard.Section>
					<LegacyStack distribution="center">
						<Pagination
							hasNext={getPickWaveOrdersPageInfo?.hasNextPage}
							hasPrevious={paginationCursors.length > 0}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</LegacyStack>
				</LegacyCard.Section>
			</LegacyCard>
		</Page>
	);
}
