// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleAvailableDestinationQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetShuttleAvailableDestinationQuery = { __typename?: 'Query', shuttleAvailableDestination?: { __typename?: 'ShuttleAvailableDestination', id: string, type: string, state: Types.GoalState, externalId: string, createdAt?: any | null, endedAt?: any | null, lastEventPersistedAt: any, currentActor?: { __typename?: 'UserActor', user?: { __typename?: 'BasicActor', type: string, id: string } | null, device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'ShuttleAvailableDestinationInputs', usage: string, priority: number, customerData: any, storageLocationId: string, storageLocationAddress: string, containerCriteria?: any | null }, outputs: { __typename?: 'ShuttleAvailableDestinationOutputs', activeTask?: string | null, hasUnresolvedExceptions?: boolean | null } } | null };


export const GetShuttleAvailableDestinationDocument = gql`
    query wapi_GetShuttleAvailableDestination($id: String!) {
  shuttleAvailableDestination(id: $id) {
    id
    type
    state
    externalId
    createdAt
    endedAt
    lastEventPersistedAt
    currentActor {
      user {
        type
        id
      }
      device {
        type
        id
      }
    }
    inputs {
      usage
      priority
      customerData
      storageLocationId
      storageLocationAddress
      containerCriteria
    }
    outputs {
      activeTask
      hasUnresolvedExceptions
    }
  }
}
    `;

export function useGetShuttleAvailableDestinationQuery(options: Omit<Urql.UseQueryArgs<GetShuttleAvailableDestinationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleAvailableDestinationQuery, GetShuttleAvailableDestinationQueryVariables>({ query: GetShuttleAvailableDestinationDocument, ...options });
};