import { useAuth } from '@sixriver/react-support';
import { RouteProps, useLocation, useNavigate } from 'react-router-dom';

import { redirectUserAfterLogin } from '../../pages/Auth/redirect-user-after-login';

export function LoginRoute({ children }: RouteProps) {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	if (isAuthenticated()) {
		redirectUserAfterLogin(navigate, location);
	}

	return <>{children}</>;
}
