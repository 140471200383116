import { Banner, Button, Modal, TextStyle, Text } from '@sixriver/lighthouse-web-community';
import { useMemo } from 'react';

import { endOfLifeDate, ltsVersion, warningDate } from './lts-dates';
import { useLocalization } from '../../../hooks/useLocalization';

interface VersionModalProps {
	open: boolean;
	onClose: () => void;
}

export function VersionModal({ open, onClose }: VersionModalProps) {
	const { formatDate, translate, messages } = useLocalization();

	const now = useMemo(() => new Date(), []);

	const banner = useMemo(() => {
		if (now > endOfLifeDate) {
			return (
				<Banner status="critical">
					{translate(messages.supportModal.banner.unsupported, { version: ltsVersion })}
				</Banner>
			);
		}

		if (now > warningDate) {
			return (
				<Banner status="warning">
					{translate(messages.supportModal.banner.warning, { version: ltsVersion })}
				</Banner>
			);
		}

		return (
			<Banner status="success">
				{translate(messages.supportModal.banner.supported, { version: ltsVersion })}
			</Banner>
		);
	}, [
		messages.supportModal.banner.supported,
		messages.supportModal.banner.unsupported,
		messages.supportModal.banner.warning,
		now,
		translate,
	]);

	const formattedDate = formatDate(endOfLifeDate, { month: 'long' });

	return (
		<Modal open={open} onClose={onClose} title={`LTS ${ltsVersion}`}>
			<Modal.Section>{banner}</Modal.Section>
			<Modal.Section>
				{now >= endOfLifeDate && (
					<TextStyle>
						{translate(messages.supportModal.supportedUntilPast, {
							date: <time dateTime={endOfLifeDate.toISOString()}>{formattedDate}</time>,
						})}
					</TextStyle>
				)}
				{now < endOfLifeDate && (
					<TextStyle>
						{translate(messages.supportModal.supportedUntilFuture, {
							date: <time dateTime={endOfLifeDate.toISOString()}>{formattedDate}</time>,
						})}
					</TextStyle>
				)}
			</Modal.Section>
			<Modal.Section>
				<Button primary url="mailto:customersuccess-6rs@ocado.com">
					{messages.supportModal.contactUsToUpdate}
				</Button>
				<Text as="p" variant="bodySm">
					customersuccess-6rs@ocado.com
				</Text>
			</Modal.Section>
		</Modal>
	);
}
