import { LegacyStack } from '@sixriver/lighthouse-web-community';

import { Telemetry } from './Telemetry';
import { Battery } from '../../components/Battery';
import { Wifi } from '../../components/Wifi';
import { useBatteryThresholds } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import PICKER_IMG from '../../images/picker.svg';

interface Props {
	isPicking: boolean;
	isOffline: boolean;
	telemetry?: Telemetry;
}

export function ChuckIndications({ isPicking, isOffline, telemetry }: Props) {
	const { messages } = useLocalization();
	const batteryThresholds = useBatteryThresholds();

	return (
		<LegacyStack spacing="tight" alignment="leading">
			{isPicking ? <img src={PICKER_IMG} width="14" height="14" alt={messages.picking} /> : null}
			{!isOffline ? (
				<Wifi signal={telemetry?.wifiSignal} connected={telemetry?.wifiConnected} />
			) : null}
			{!isOffline ? (
				<Battery
					level={Math.floor(telemetry?.batteryLevel || 0)}
					highThreshold={batteryThresholds.viable || undefined}
					lowThreshold={batteryThresholds.critical || undefined}
					monochrome={true}
				/>
			) : null}
		</LegacyStack>
	);
}
