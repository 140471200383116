import { UserRole } from '@sixriver/react-support';

import { ProductDetails } from './Product';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function ProductRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}>
			<ProductDetails />
		</AuthorizedRoute>
	);
}
