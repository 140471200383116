import { GoalState } from '../../api/warehouse-api/types';

export enum ShuttleAvailableDestinationsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	// destinations are never queued (yet)
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const ShuttleAvailableDestinationsViewsViewStateMap: Record<
	ShuttleAvailableDestinationsViews,
	GoalState[]
> = {
	[ShuttleAvailableDestinationsViews.All]: [],
	[ShuttleAvailableDestinationsViews.Unassigned]: [
		GoalState.Creating,
		GoalState.Created,
		GoalState.Ready,
		// because goals can't go from running->ready, available destinations use
		// the paused state to represent being logically unassigned after an earlier
		// attempted use fails in the pickup stage
		GoalState.Paused,
	],
	// destinations are never queued (yet)
	[ShuttleAvailableDestinationsViews.InProgress]: [
		GoalState.Running,
		GoalState.TerminateRequested,
		GoalState.Terminating,
		GoalState.CancelRequested,
		GoalState.Cancelling,
	],
	[ShuttleAvailableDestinationsViews.Completed]: [GoalState.Complete],
	[ShuttleAvailableDestinationsViews.Cancelled]: [GoalState.Cancelled, GoalState.Terminated],
};
