// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetShuttleAvailableDestinationsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  states?: Types.InputMaybe<Array<Types.GoalState> | Types.GoalState>;
  orderBy?: Types.InputMaybe<Array<Types.ShuttleAvailableDestinationOrderBy> | Types.ShuttleAvailableDestinationOrderBy>;
}>;


export type GetShuttleAvailableDestinationsQuery = { __typename?: 'Query', shuttleAvailableDestinations: { __typename?: 'ShuttleAvailableDestinationConnection', edges: Array<{ __typename?: 'ShuttleAvailableDestinationEdge', node: { __typename?: 'ShuttleAvailableDestination', id: string, type: string, state: Types.GoalState, externalId: string, createdAt?: any | null, endedAt?: any | null, lastEventPersistedAt: any, currentActor?: { __typename?: 'UserActor', user?: { __typename?: 'BasicActor', type: string, id: string } | null, device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'ShuttleAvailableDestinationInputs', usage: string, priority: number, storageLocationId: string, storageLocationAddress: string }, outputs: { __typename?: 'ShuttleAvailableDestinationOutputs', activeTask?: string | null, hasUnresolvedExceptions?: boolean | null } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetShuttleAvailableDestinationsDocument = gql`
    query wapi_GetShuttleAvailableDestinations($after: String, $first: Int, $cursor: String, $limit: Float, $searchText: String, $states: [GoalState!], $orderBy: [ShuttleAvailableDestinationOrderBy!]) {
  shuttleAvailableDestinations(
    after: $after
    first: $first
    cursor: $cursor
    limit: $limit
    searchText: $searchText
    states: $states
    orderBy: $orderBy
  ) {
    edges {
      node {
        id
        type
        state
        externalId
        createdAt
        endedAt
        lastEventPersistedAt
        currentActor {
          user {
            type
            id
          }
          device {
            type
            id
          }
        }
        inputs {
          usage
          priority
          storageLocationId
          storageLocationAddress
        }
        outputs {
          activeTask
          hasUnresolvedExceptions
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetShuttleAvailableDestinationsQuery(options?: Omit<Urql.UseQueryArgs<GetShuttleAvailableDestinationsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetShuttleAvailableDestinationsQuery, GetShuttleAvailableDestinationsQueryVariables>({ query: GetShuttleAvailableDestinationsDocument, ...options });
};