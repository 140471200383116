import { usePrevious } from '@shopify/react-hooks';
import {
	AppliedFilterInterface,
	LegacyCard,
	EmptySearchResult,
	FilterInterface,
	Filters,
	IndexTable,
	Pagination,
	LegacyStack,
} from '@sixriver/lighthouse-web-community';
import { noop } from 'lodash';
import { useCallback, useEffect } from 'react';

import { LicensePlateCell } from './LicensePlateCell';
import {
	OrderByDirection,
	PageInfo,
	ShuttleAvailableContainerOrderBy,
	ShuttleAvailableContainerOrderByFields,
} from '../../../api/warehouse-api/types';
import { SortChoice, SortBy } from '../../../components/SortBy';
import { Tab, ViewTabs } from '../../../components/ViewTabs';
import { GetShuttleAvailableContainersQueryEdges } from '../../../graphql/derived';
import { MIN_QUERY_LENGTH } from '../../../helpers/table';
import { SetFilters, DEFAULT_QUERY_KEY, DEFAULT_VIEW_KEY } from '../../../hooks/useFilters';
import { useLocalization } from '../../../hooks/useLocalization';
import { StorageAddressCell } from '../../ShuttleJobs/ShuttleJobsTable';
import { CreatedAtCell } from '../../ShuttleJobs/ShuttleJobsTable/CreatedAtCell';
import { DeviceCell } from '../../ShuttleJobs/ShuttleJobsTable/DeviceCell';
import { ProgressCell } from '../../ShuttleJobs/ShuttleJobsTable/ProgressCell';
import { Heading } from '../../ShuttleJobs/ShuttleJobsTable/ShuttleJobsTable';
import { UpdatedAtCell } from '../../ShuttleJobs/ShuttleJobsTable/UpdatedAtCell';

function convertToOrderBy(sortStrings: string[]): ShuttleAvailableContainerOrderBy[] {
	return sortStrings.map((option) => {
		const [field, direction] = option.split(' ') as [
			ShuttleAvailableContainerOrderByFields,
			OrderByDirection,
		];
		return { direction, field };
	});
}

interface Props {
	data?: GetShuttleAvailableContainersQueryEdges;
	fetching?: boolean;
	pageInfo?: PageInfo;
	query?: string;
	queryKey?: string;
	selectedView?: string;
	setFilters: SetFilters;
	viewKey?: string;
	views: Tab[];
	selectedSort?: ShuttleAvailableContainerOrderBy[];
	onSortChange?: (value: ShuttleAvailableContainerOrderBy[]) => void;
	filters?: FilterInterface[];
	appliedFilters?: AppliedFilterInterface[];
	totalPages: number;
	setPaginationCursors: (value: string[]) => void;
	paginationCursors: string[];
}

export function ShuttleAvailableContainersTable({
	data = [],
	fetching,
	pageInfo,
	query,
	queryKey = DEFAULT_QUERY_KEY,
	selectedView,
	setFilters,
	viewKey = DEFAULT_VIEW_KEY,
	views,
	selectedSort = [],
	onSortChange = noop,
	filters,
	appliedFilters,
	totalPages,
	setPaginationCursors,
	paginationCursors,
}: Props) {
	const { messages } = useLocalization();

	const onClearAll = () => {
		if (setFilters) {
			setFilters([
				...(filters || []).map((filter) => ({ key: filter.key, value: '' })),
				{ key: queryKey, value: '' },
			]);
		}
	};

	const emptyStateMarkup = (
		<EmptySearchResult title={messages.shuttleJobsNotFound} withIllustration />
	);

	const sortChoices: SortChoice[] = [
		{
			label: messages.updatedAt,
			value: `${ShuttleAvailableContainerOrderByFields.UpdatedAt} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.createdAt,
			value: `${ShuttleAvailableContainerOrderByFields.CreatedAt} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.shuttle.sortOptions.containerIdDesc,
			value: `${ShuttleAvailableContainerOrderByFields.ContainerId} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.shuttle.sortOptions.containerIdAsc,
			value: `${ShuttleAvailableContainerOrderByFields.ContainerId} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.shuttle.sortOptions.addressAsc,
			value: `${ShuttleAvailableContainerOrderByFields.Address} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.shuttle.sortOptions.addressDesc,
			value: `${ShuttleAvailableContainerOrderByFields.Address} ${OrderByDirection.Desc}`,
		},
	];

	const prevQuery = usePrevious(query);
	const prevSelectedView = usePrevious(selectedView);
	const prevAppliedFilters = usePrevious(appliedFilters);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		window.scrollTo(0, 0);
		const endCursor = pageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors([endCursor].concat(paginationCursors));
		}
	}, [pageInfo?.endCursor, paginationCursors, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		window.scrollTo(0, 0);
		setPaginationCursors(paginationCursors.slice(1));
	}, [paginationCursors, setPaginationCursors]);

	// Reset pagination when changing views or filters
	useEffect(() => {
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (
			setPaginationCursors &&
			(prevSelectedView !== selectedView || hasQueryChanged || haveFiltersChanged)
		) {
			setPaginationCursors([]);
		}
	});

	const headings: [Heading, ...Heading[]] = [
		{ title: messages.licensePlate },
		{ title: messages.location },
		{ title: messages.createdAt },
		{ title: messages.updatedAt },
		{ title: messages.progress },
		{ title: messages.device },
	];

	const rows = data.map(({ node }, index) => {
		const {
			id,
			inputs: {
				containerLabels: { default: lpn },
			},
			createdAt,
			state,
			outputs: { hasUnresolvedExceptions },
		} = node;
		return (
			<IndexTable.Row id={id} key={id} position={index}>
				<LicensePlateCell id={id} containerId={lpn} />
				<StorageAddressCell
					address={node.inputs.storageLocationAddress}
					locationId={node.inputs.storageLocationId}
				/>
				<CreatedAtCell createdAt={createdAt} />
				<UpdatedAtCell node={node} />
				<ProgressCell
					status={state}
					exceptionMessage={hasUnresolvedExceptions ? messages.shuttle.hasExceptions : undefined}
				/>
				<DeviceCell node={node} />
			</IndexTable.Row>
		);
	});

	return (
		<LegacyCard>
			<LegacyCard.Section>
				<div style={{ paddingBottom: '2rem' }}>
					<ViewTabs
						onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
						selected={selectedView || views?.[0]?.id}
						tabs={views}
					/>
				</div>
				<Filters
					queryValue={query || undefined}
					queryPlaceholder={messages.shuttle.searchAvailableContainersPlaceholder}
					onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
					onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
					filters={filters || []}
					appliedFilters={appliedFilters}
					onClearAll={onClearAll}
				>
					<SortBy
						choices={sortChoices}
						selected={selectedSort.map((sort) => `${sort.field} ${sort.direction}`)}
						onChange={(selected) => onSortChange(convertToOrderBy(selected))}
					/>
				</Filters>
			</LegacyCard.Section>
			<IndexTable
				emptyState={emptyStateMarkup}
				headings={headings}
				itemCount={data.length}
				loading={fetching}
				selectable={false}
			>
				{rows}
			</IndexTable>
			<LegacyCard.Section>
				<LegacyStack distribution="center">
					<Pagination
						label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
						hasNext={pageInfo?.hasNextPage}
						hasPrevious={pageInfo?.hasPreviousPage}
						onNext={handleOnNextPage}
						onPrevious={handleOnPreviousPage}
					/>
				</LegacyStack>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
