import { Modal, Page, PageActions, PageProps } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Product404 } from './Product404';
import { ProductForm } from './ProductForm';
import { useDeleteProductMutation } from './graphql/DeleteProduct.f-api-graphql';
import { useEditProductMutation } from './graphql/EditProduct.f-api-graphql';
import {
	GetProductDocument,
	GetProductQuery,
	GetProductQueryVariables,
	useGetProductQuery,
} from './graphql/GetProduct.w-api-graphql';
import { ProductInput } from '../../api/fulfillment-api/types';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function EditProduct() {
	const navigate = useNavigate();
	const { showToast } = useToast();
	const { messages } = useLocalization();
	const [modalVisible, setModalVisible] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const { productId = '' } = useParams<{ productId: string }>();
	const backAction: PageProps['backAction'] = {
		content: messages.product,
		url: routes.product(productId),
	};
	const [getProductQuery] = useGetProductQuery({
		requestPolicy: 'network-only',
		variables: {
			id: productId,
		},
	});

	const [editProductMutation, executeEditProductMutation] = useEditProductMutation();
	const [deleteProductMutation, executeDeleteProductMutation] = useDeleteProductMutation();
	const [originalUpdatedAt, setOriginalUpdatedAt] = useState<string>();

	// Edits
	useWaitForWapiIngestion<GetProductQuery, GetProductQueryVariables>({
		enabled: !!editProductMutation.data?.editProduct?.success,
		onResponse: (updatedData) => {
			if (!updatedData.product?.updatedAt) {
				return;
			}

			if (new Date(updatedData.product.updatedAt) > new Date(originalUpdatedAt!)) {
				navigate(routes.product(productId));
			}
		},
		onTimeout: () => {
			showToast(messages.editProductWapiIngestionError, true);
		},
		query: GetProductDocument,
		variables: {
			id: productId ?? '',
		},
	});

	// Deletes (ensure the product is deleted before returning to the index so that it's not in the list)
	useWaitForWapiIngestion<GetProductQuery, GetProductQueryVariables>({
		enabled: !!deleteProductMutation.data?.deleteProduct?.success,
		onResponse: (updatedData) => {
			if (!updatedData.product) {
				navigate(routes.products());
			}
		},
		onTimeout: () => {
			showToast(messages.editProductWapiIngestionError, true);
		},
		query: GetProductDocument,
		variables: {
			id: productId ?? '',
		},
	});

	const onEdit = async (input: ProductInput) => {
		setSubmitting(true);
		setOriginalUpdatedAt(getProductQuery.data?.product?.updatedAt);
		await executeEditProductMutation({ input });
	};

	const onDelete = async () => {
		setDeleting(true);
		await executeDeleteProductMutation({ id: productId });
	};

	if (
		getProductQuery.fetching ||
		// Prevent a flash of an error page when the product is deleted but we're waiting
		// for WAPI ingestion to complete
		(!getProductQuery.data?.product && deleteProductMutation.data?.deleteProduct.success)
	) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (getProductQuery.error || !getProductQuery.data?.product) {
		if (hasMissingEntityError(getProductQuery.error)) {
			return <Product404 id={productId} />;
		}

		return <ErrorPage combinedError={getProductQuery.error} />;
	}

	return (
		<Page
			fullWidth
			title={getProductQuery.data?.product.customerIdentifier}
			backAction={backAction}
		>
			<ProductForm
				mode="edit"
				onSubmit={onEdit}
				error={editProductMutation.error || deleteProductMutation.error}
				submitting={submitting}
				data={
					{
						customerIdentifier: getProductQuery.data?.product.customerIdentifier || '',
						description: getProductQuery.data?.product.description || '',
						height: getProductQuery.data?.product.height || 0,
						id: getProductQuery.data?.product.id,
						image: getProductQuery.data?.product.image,
						length: getProductQuery.data?.product.length || 0,
						name: getProductQuery.data?.product.name || '',
						weight: getProductQuery.data?.product.weight || 0,
						width: getProductQuery.data?.product.width || 0,
					} as ProductInput
				}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteProduct,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				title={messages.deleteProduct}
				onClose={() => !deleting && setModalVisible(false)}
				primaryAction={{
					content: messages.deleteProduct,
					destructive: true,
					loading: deleting,
					onAction: () => void onDelete(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						disabled: deleting,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteProduct}</Modal.Section>
			</Modal>
		</Page>
	);
}
