import { GoalState } from '../../api/warehouse-api/types';

export enum ShuttleJobsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'In progress',
	Interrupted = 'Interrupted',
	Completed = 'Completed',
	Cancelled = 'Cancelled',
}

export const ShuttleJobsViewsViewStateMap: Record<ShuttleJobsViews, GoalState[]> = {
	[ShuttleJobsViews.All]: [],
	[ShuttleJobsViews.Unassigned]: [GoalState.Creating, GoalState.Created, GoalState.Ready],
	[ShuttleJobsViews.Interrupted]: [GoalState.Paused],
	[ShuttleJobsViews.InProgress]: [
		GoalState.Running,
		GoalState.TerminateRequested,
		GoalState.Terminating,
		GoalState.CancelRequested,
		GoalState.Cancelling,
	],
	[ShuttleJobsViews.Completed]: [GoalState.Complete],
	[ShuttleJobsViews.Cancelled]: [GoalState.Cancelled, GoalState.Terminated],
};
