import { Modal, Page, PageActions, PageProps } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { JobFlowRule404 } from './JobFlowRule404';
import JobFlowRuleForm from './JobFlowRuleForm';
import { JobFlowRuleInput } from '../../api/fulfillment-api/types';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { useCloseJobFlowRuleMutation } from './graphql/CloseJobFlowRule.f-api-graphql';
import { useEditJobFlowRuleMutation } from './graphql/EditJobFlowRule.f-api-graphql';
import { useGetJobFlowRuleQuery } from './graphql/GetJobFlowRule.f-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';

export function EditJobFlowRule() {
	const { jobFlowRuleId = '' } = useParams<{ jobFlowRuleId: string }>();

	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: jobFlowRuleId,
		url: routes.jobFlowRule(jobFlowRuleId),
	};
	const navigate = useNavigate();

	const { showToast } = useToast();

	const [modalVisible, setModalVisible] = useState(false);

	const [getJobFlowRuleQuery] = useGetJobFlowRuleQuery({
		variables: {
			id: jobFlowRuleId,
		},
	});

	const [editJobFlowRuleMutation, executeEditJobFlowRuleMutation] = useEditJobFlowRuleMutation();

	const [closeJobFlowRuleMutation, executeCloseJobFlowRuleMutation] = useCloseJobFlowRuleMutation();

	const onSubmit = async (input: JobFlowRuleInput) => {
		const { error } = await executeEditJobFlowRuleMutation({ input });

		if (!error) {
			showToast(messages.dataSaved);

			navigate(routes.jobFlowRule(jobFlowRuleId));
		}
	};

	const deleteJobFlowRule = async () => {
		const { error } = await executeCloseJobFlowRuleMutation({ id: jobFlowRuleId });

		setModalVisible(false);

		if (error) {
			showToast(messages.dataNotSaved, true);
			return;
		}

		navigate(routes.jobFlowRules());
	};

	const rule = getJobFlowRuleQuery.data?.jobFlowRule;

	if (getJobFlowRuleQuery.fetching) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (!rule) {
		return <JobFlowRule404 id={jobFlowRuleId} />;
	}

	if (getJobFlowRuleQuery.error || closeJobFlowRuleMutation.error) {
		return (
			<ErrorPage combinedError={getJobFlowRuleQuery.error || closeJobFlowRuleMutation.error} />
		);
	}

	const input: JobFlowRuleInput = {
		id: rule.id,
		rule: rule.rule,
		ruleDescription: rule.ruleDescription,
		ruleSubType: rule.ruleSubType,
	};

	return (
		<Page fullWidth title={rule.ruleDescription} backAction={backAction}>
			<JobFlowRuleForm
				mode="edit"
				data={input}
				error={editJobFlowRuleMutation.error}
				onSubmit={onSubmit}
			/>
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteJobFlowRule,
						destructive: true,
						onAction: () => setModalVisible(true),
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteJobFlowRule}
				primaryAction={{
					content: messages.deleteJobFlowRule,
					destructive: true,
					loading: closeJobFlowRuleMutation.fetching,
					onAction: () => void deleteJobFlowRule(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteJobFlowRule}</Modal.Section>
			</Modal>
		</Page>
	);
}
