import { ArrowLeftMinor } from '@sixriver/lighthouse-icons';
import { Heading, Icon, LegacyStack } from '@sixriver/lighthouse-web-community';
import { PropsWithChildren } from 'react';

import sidePaneStyles from './SidePane.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import closeImg from '../../images/close.svg';

type SidePaneProps = PropsWithChildren<{
	title: React.ReactNode;
	onDismiss?: () => void;
	onBack?: () => void;
	actions?: React.ReactNode;
}>;

export const SidePane = ({ children, title, onDismiss, onBack, actions }: SidePaneProps) => {
	const { messages } = useLocalization();

	return (
		<div className={sidePaneStyles.outer}>
			<div className={sidePaneStyles.head}>
				<LegacyStack distribution="equalSpacing" alignment="center">
					<LegacyStack>
						{onBack && (
							<button className={sidePaneStyles.back} onClick={onBack}>
								<Icon source={ArrowLeftMinor} />
							</button>
						)}
						<Heading>{title}</Heading>
					</LegacyStack>
					{onDismiss && (
						<button className={sidePaneStyles.close} onClick={onDismiss}>
							<img src={closeImg} width="16" height="16" alt={messages.close} />
						</button>
					)}
				</LegacyStack>
			</div>
			<div className={sidePaneStyles.body}>{children}</div>
			<div className={sidePaneStyles.actions}>{actions}</div>
		</div>
	);
};
