import { Layout, Page, LegacyStack, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Details } from './Details';
import { Progress } from './Progress';
import { ShuttleJob404 } from './ShuttleJob404';
import { useGetShuttleJobQuery } from './graphql/GetShuttleJob.w-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';
import { ShuttleJobStatus } from '../ShuttleJobs/ShuttleJobsTable/ShuttleJobStatus';

export function ShuttleJob() {
	const { shuttleJobId = '' } = useParams<{ shuttleJobId: string }>();
	const { messages } = useLocalization();
	const backAction: PageProps['backAction'] = {
		content: messages.shuttleJobs,
		url: routes.shuttleJobs(),
	};
	// query logic
	const [getShuttleJobQuery] = useGetShuttleJobQuery({
		variables: {
			id: shuttleJobId,
		},
	});

	const { shuttleJob } = getShuttleJobQuery.data || {};

	if (getShuttleJobQuery.fetching) {
		return <LoadingPage backAction={backAction} />;
	}

	if (getShuttleJobQuery.error || !shuttleJob) {
		if (hasMissingEntityError(getShuttleJobQuery.error)) {
			return <ShuttleJob404 id={shuttleJobId} />;
		}
		return <ErrorPage combinedError={getShuttleJobQuery.error} />;
	}

	return (
		<Page
			title={shuttleJob?.inputs.containerLabels.default || ''}
			backAction={backAction}
			titleMetadata={
				<LegacyStack spacing="extraTight">
					<ShuttleJobStatus status={shuttleJob?.state} />
					{/* {numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null} */}
				</LegacyStack>
			}
		>
			<Layout>
				<Layout.Section>
					<Details shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<Progress shuttleJob={shuttleJob} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
