import { IndexTable, LegacyStack } from '@sixriver/lighthouse-web-community';

import { OrderStatusBadgeV2 } from '../../../components/OrderStatusBadge/OrderStatusBadgeV2';
import { ResolutionCountText } from '../../../components/ResolutionCountText';
import { GetOrdersOrderFragment } from '../graphql/GetOrders.w-api-graphql';

interface StatusCellProps {
	order: GetOrdersOrderFragment;
}

export function StatusCell({ order }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<LegacyStack vertical spacing="extraTight">
				<OrderStatusBadgeV2 order={order} />
				<ResolutionCountText order={order} />
			</LegacyStack>
		</IndexTable.Cell>
	);
}
