import { Loading, Page, SkeletonDisplayText, Spinner } from '@sixriver/lighthouse-web-community';
import { useNavigate } from 'react-router-dom';

import { useServiceConfig } from '../../hooks/useConfig';
import * as routes from '../../routes';

export type FeatureFlaggedRouteOpts = React.PropsWithChildren<{
	flag: string;
}>;

export function FeatureFlaggedRoute({ children, flag }: FeatureFlaggedRouteOpts) {
	const { serviceConfig, fetching } = useServiceConfig();
	const navigate = useNavigate();

	if (fetching) {
		return (
			<Page
				title={(<SkeletonDisplayText size="extraLarge" />) as unknown as string}
				secondaryActions={<Spinner size="small" />}
			>
				<Loading />
			</Page>
		);
	}

	if (!serviceConfig?.managementConsole?.experimentalFlags?.includes(flag)) {
		navigate(routes.orders());
		return null;
	}

	return <>{children}</>;
}
