import * as Sentry from '@sentry/react';
import { LegacyCard, EmptyState } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';

import { ErrorDetails } from './ErrorDetails';
import { useLocalization } from '../../hooks/useLocalization';
import { useMount } from '../../hooks/useMount';
import offlineImage from '../../images/offline.svg';

export interface NetworkErrorProps {
	error: Error;
}

export function NetworkError({ error }: NetworkErrorProps) {
	const { messages } = useLocalization();
	const [sentryId, setSentryId] = useState<string | undefined>();

	useMount(() => {
		const sentryId = Sentry.captureEvent({
			extra: {
				error,
			},
			level: 'error',
			message: 'Network error',
		});

		setSentryId(sentryId);
	});

	return (
		<>
			<LegacyCard>
				<EmptyState
					heading={messages.networkError.heading}
					action={{
						content: messages.reloadPage,
						onAction: () => {
							window.location.reload();
						},
					}}
					image={offlineImage}
				>
					{messages.networkError.message}
				</EmptyState>
			</LegacyCard>
			<ErrorDetails error={error} sentryId={sentryId} />
		</>
	);
}
