import { AlertMinor } from '@sixriver/lighthouse-icons';
import {
	LegacyCard,
	Button,
	Link,
	Icon,
	Tooltip,
	LegacyStack,
} from '@sixriver/lighthouse-web-community';

import * as routes from '../../routes';
import { GetShuttleAvailableDestinationQuery } from './graphql/GetShuttleAvailableDestination.w-api-graphql';
import { GoalState } from '../../api/warehouse-api/types';
import { CardDetails } from '../../components/CardDetails';
import { CopyButton } from '../../components/CopyButton';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { RelativeDateTime } from '../../components/RelativeDateTime';
import { getMfpUrl } from '../../helpers/mfp';
import { useLocalization } from '../../hooks/useLocalization';
import { StorageAddressLink } from '../ShuttleJobs/ShuttleJobsTable';

export function Details({ shuttleAvailableDestination }: GetShuttleAvailableDestinationQuery) {
	const { messages } = useLocalization();

	const device = shuttleAvailableDestination?.currentActor?.device;

	const deviceInfo: { deviceId: string; deviceType: string } | undefined =
		device?.type === 'Mfp'
			? {
					deviceId: device.id,
					deviceType: 'mfp',
			  }
			: undefined;

	const uniqueChuck = deviceInfo?.deviceId;
	const address = shuttleAvailableDestination?.inputs.storageLocationAddress;
	const locationId = shuttleAvailableDestination?.inputs.storageLocationId;
	let activeTask = shuttleAvailableDestination?.outputs.activeTask;
	// oops, if we are in paused the active task is obsolete, but schemas don't
	// yet allow the handler to actually set it null
	if (shuttleAvailableDestination?.state === GoalState.Paused) {
		activeTask = null;
	}

	return (
		<LegacyCard title={messages.details}>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: <StorageAddressLink address={address} locationId={locationId} />,
							label: messages.destinationLocation,
						},
					]}
					secondary={[
						{
							content: (
								<>
									<DateTime date={shuttleAvailableDestination?.createdAt} />
									<RelativeDateTime date={shuttleAvailableDestination?.createdAt} />
								</>
							),
							label: messages.createdAt,
						},
						{
							content: <DateTime date={shuttleAvailableDestination?.lastEventPersistedAt} />,
							label: messages.updatedAt,
						},
						{
							content: <DateTime date={shuttleAvailableDestination?.endedAt} />,
							label: messages.completedAt,
						},
					]}
				/>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: uniqueChuck || <NoData />,
							label: messages.chuck,
						},
					]}
				/>
				<br />
				{/* TODO: account for finding multiple devices */}
				<Button
					plain
					url={getMfpUrl(uniqueChuck && uniqueChuck[0])}
					disabled={!uniqueChuck?.length}
				>
					{messages.findThisChuck}
				</Button>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							content: shuttleAvailableDestination?.id ? (
								<CopyButton text={shuttleAvailableDestination.id} />
							) : null,
							label: messages.uniqueJobId,
						},
					]}
				/>
			</LegacyCard.Section>
			<LegacyCard.Section>
				<CardDetails
					primary={[
						{
							// TODO: add badge for dropoff status?
							content: activeTask ? (
								<LegacyStack spacing="extraTight" wrap={false}>
									<Link url={routes.shuttleJob(activeTask)}>{activeTask}</Link>
									{shuttleAvailableDestination?.outputs.hasUnresolvedExceptions ? (
										<Tooltip content={messages.shuttle.hasExceptions} dismissOnMouseOut>
											<Icon source={AlertMinor} color="warning" />
										</Tooltip>
									) : null}
								</LegacyStack>
							) : (
								<NoData />
							),
							label: messages.shuttleJob,
						},
						// TODO: find paired container(s) and link to that/them?
					]}
				/>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
