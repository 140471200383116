import { EditMinor } from '@sixriver/lighthouse-icons';
import { Badge, LegacyCard, Layout, PageProps } from '@sixriver/lighthouse-web-community';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ConfirmRemoveMfaModal } from './ConfirmRemoveMfaModal';
import { EditNotesModal } from './EditNotesModal';
import { LicenseDetail } from './LicenseDetail';
import { Mfa404 } from './Mfa404';
import { NoLicense } from './NoLicense';
import { PastReservations } from './PastReservations';
import { MfaPreview } from './Preview/Preview';
import { AutoRefreshPage } from '../../../components/AutoRefreshPage/AutoRefreshPage';
import { CardDetails } from '../../../components/CardDetails';
import { DateTime } from '../../../components/DateTime';
import { LoadingPage } from '../../../components/LoadingPage/LoadingPage';
import { NoData } from '../../../components/NoData';
import { TimezoneFooter } from '../../../components/TimezoneFooter';
import { useInterval } from '../../../hooks/useInterval';
import { useLocalization } from '../../../hooks/useLocalization';
import { usePolling } from '../../../hooks/usePolling';
import { useMfas } from '../providers/Mfas';
import { useReservations } from '../providers/Reservations';
import { useResources } from '../providers/Resources';
import * as mfaRoutes from '../routes';

export function Mfa(): JSX.Element {
	const { mfaId = '' } = useParams<{ mfaId: string }>();

	const navigate = useNavigate();
	const { messages } = useLocalization();

	const backAction: PageProps['backAction'] = {
		content: messages.mfas.title,
		url: mfaRoutes.mfas(),
	};
	const { fetchResources, resources } = useResources();
	const { mfas, deleteMfa, fetchMfas } = useMfas();
	const { queryPollInterval } = usePolling();
	const {
		expired,
		reservations,
		expireReservation,
		fetchActiveReservations,
		fetchExpiredReservations,
	} = useReservations();

	useInterval(queryPollInterval, async () => {
		await Promise.all([
			fetchMfas(),
			fetchResources(),
			fetchActiveReservations(),
			fetchExpiredReservations(mfaId, 100),
		]);
	});

	useEffect(() => {
		void fetchMfas();
		void fetchResources();
		void fetchActiveReservations();
		void fetchExpiredReservations(mfaId, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [editNotesOpen, setEditNotesOpen] = useState(false);
	const [confirmRemovalOpen, setConfirmRemovalOpen] = useState(false);

	const mfa = mfas?.find((m) => m.deviceId === mfaId);
	const reservation = reservations?.find((r) => r.deviceId === mfaId);

	const onConfirmRemoval = useCallback(async () => {
		if (reservation) {
			// Expire any active reservation associated with this device
			await expireReservation(reservation);
		}

		if (mfa) {
			// Delete the mfa from Gatekeeper
			await deleteMfa(mfa);
		}

		navigate('/mfas');
	}, [deleteMfa, expireReservation, navigate, mfa, reservation]);

	if (mfas === null || reservations === null) {
		return <LoadingPage backAction={backAction} />;
	}

	if (!mfa) {
		return <Mfa404 id={mfaId} />;
	}

	return (
		<AutoRefreshPage
			discriminatorData={{ mfas, reservations, resources }}
			title={mfa?.name || ''}
			backAction={backAction}
		>
			<ConfirmRemoveMfaModal
				open={confirmRemovalOpen}
				mfa={mfa}
				onDismiss={() => setConfirmRemovalOpen(false)}
				onConfirm={onConfirmRemoval}
			/>
			<EditNotesModal
				open={editNotesOpen}
				mfa={mfa}
				onDismiss={() => setEditNotesOpen(false)}
				onEdited={() => setEditNotesOpen(false)}
			/>
			<Layout>
				<Layout.Section oneHalf>
					<Layout>
						<Layout.Section>
							<LegacyCard
								title={messages.mfa.title}
								primaryFooterAction={{
									content: messages.mfa.removeLabel,
									destructive: true,
									onAction: () => setConfirmRemovalOpen(true),
									outline: true,
								}}
								secondaryFooterActions={[
									{
										content: messages.mfa.edit,
										icon: EditMinor,
										onAction: () => setEditNotesOpen(true),
										plain: true,
									},
								]}
							>
								<LegacyCard.Section>
									<CardDetails
										primary={[
											{
												content: mfa.name,
												label: messages.mfa.list.name,
											},
											{
												content: <DateTime key={`createdAt-${mfaId}`} date={mfa.createdAt} />,
												label: messages.mfa.list.addedAt,
											},
											{
												content: reservation ? (
													<Badge status="info">{messages.mfa.list.status.inUse}</Badge>
												) : (
													<NoData />
												),
												label: messages.mfa.list.status.label,
											},
											{
												content: mfa.notes,
												label: messages.mfa.list.notes,
											},
										]}
									/>
								</LegacyCard.Section>
							</LegacyCard>
						</Layout.Section>
						<Layout.Section>
							{reservation ? (
								<LicenseDetail reservation={reservation} />
							) : (
								<NoLicense mfaId={mfaId} />
							)}
						</Layout.Section>
					</Layout>
				</Layout.Section>
				<Layout.Section oneHalf>
					<MfaPreview reservationId={reservation?.id} />
				</Layout.Section>
				{expired?.length && expired?.length > 0 ? (
					<Layout.Section>
						<LegacyCard title={messages.mfa.license.list.recent}>
							<LegacyCard.Section>
								<PastReservations reservations={expired} />,
							</LegacyCard.Section>
						</LegacyCard>
					</Layout.Section>
				) : (
					<></>
				)}
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
