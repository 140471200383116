import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function useQueryState<S>(
	key: string,
	defaultValue: S,
	parse?: (value: string) => S,
	serialize?: (value: S) => string | undefined,
): [S, (value: S) => void] {
	const location = useLocation();
	const navigate = useNavigate();
	const isArray = Array.isArray(defaultValue);
	const queryParams = new URLSearchParams(location.search);
	const queryParam = isArray ? queryParams.getAll(key) : queryParams.get(key);
	const value = parse && typeof queryParam === 'string' ? parse(queryParam) : queryParam;
	const setQuery = useCallback(
		(value: any) => {
			const queryParams = new URLSearchParams(location.search);
			queryParams.delete(key);

			if (Array.isArray(value)) {
				value.forEach((v: S) => queryParams.append(key, String(v)));
			} else if (value !== undefined) {
				const resolvedValue = serialize ? serialize(value) : value;

				if (resolvedValue !== undefined) {
					queryParams.set(key, resolvedValue);
				}
			}

			navigate(`${location.pathname}?${queryParams.toString()}`);
		},
		[navigate, key, location.pathname, location.search, serialize],
	);
	return [(value ?? defaultValue) as S, setQuery];
}

export function useArrayQueryState<T = string[]>(key: string, defaultState: T) {
	return useQueryState<T>(key, defaultState);
}

export function useArrayOfObjectsQueryState<T>(
	key: string,
	defaultValue: T,
	parse: (value: string, fields: string[]) => T,
	serialize: (value: T, fields: string[]) => string,
	objectFieldsInOrder: string[],
): [T, (value: T) => void] {
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(window.location.search);
	const queryParam = queryParams.getAll(key);
	const value = queryParam.map((p) => parse(p, objectFieldsInOrder));

	const setValue = useCallback(
		(newValue: any) => {
			const queryParams = new URLSearchParams(window.location.search);
			queryParams.delete(key);
			newValue.forEach((v: any) => queryParams.append(key, serialize(v, objectFieldsInOrder)));
			navigate({ search: queryParams.toString() }, { replace: true });
		},
		[key, objectFieldsInOrder, navigate, serialize],
	);

	return [(value.length > 0 ? value : defaultValue) as T, setValue];
}

export function useSortQueryState<T = { direction: string; field: string }[]>(
	key: string,
	defaultValue: T,
) {
	return useArrayOfObjectsQueryState<T>(key, defaultValue, parseObject, serializeObject, [
		'field',
		'direction',
	]);
}

export function useDateQueryState<T = Date>(key: string, defaultState: T) {
	return useQueryState<T>(key, defaultState, parseDate as any, serializeDate as any);
}

export function useNumberQueryState(key: string, defaultState: number) {
	return useQueryState<number>(key, defaultState, parseNumber as any, serializeNumber as any);
}

function serializeDate(date?: Date): string | undefined {
	return date ? date.toISOString() : undefined;
}

function parseDate(value: string): Date {
	return new Date(value);
}

function serializeNumber(number?: number): string | undefined {
	return number !== undefined ? number.toString() : undefined;
}

function parseNumber(value: string): number {
	return parseFloat(value);
}

function serializeObject(obj: any, fieldsWithOrder: string[]): string {
	return fieldsWithOrder.map((key) => obj[key]).join(' ');
}

function parseObject(value: string, fieldsWithOrder: string[]): any {
	const values = value.split(' ');
	const sortObject: any = {};
	fieldsWithOrder.forEach((key, i) => {
		sortObject[key] = values[i];
	});
	return sortObject;
}
