import { Button, LegacyStack } from '@sixriver/lighthouse-web-community';

import cardStyles from './ShuttleCard.module.css';
import { useLocalization } from '../../../hooks/useLocalization';

interface ShuttleCardFooterProps {
	modified: boolean;
	onSave: () => void;
	onDelete: () => void;
	valid: boolean;
	canDelete: boolean;
}

export function ShuttleCardFooter({
	modified,
	onSave,
	onDelete,
	canDelete,
	valid,
}: ShuttleCardFooterProps) {
	const { messages } = useLocalization();
	return (
		<footer className={cardStyles.footer}>
			<LegacyStack vertical>
				<LegacyStack distribution="trailing" alignment="center">
					{canDelete && (
						<Button plain onClick={onDelete} destructive>
							{messages.delete}
						</Button>
					)}
					<Button primary onClick={onSave} disabled={!valid || !modified}>
						{messages.shuttleRouteSave}
					</Button>
				</LegacyStack>
			</LegacyStack>
		</footer>
	);
}
