import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import { useGetLocationsQuery } from './graphql/GetLocations.w-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import * as routes from '../../routes';

export function LocationByAddress() {
	const { address = '' } = useParams<{ address: string }>();
	const navigate = useNavigate();

	// we don't enable polling for this one, because we are going to either show
	// an error or redirect based on the result.
	const [result] = useGetLocationsQuery({ variables: { searchText: address } });

	// uncomment this and add a check for `delayed` to the result nav to test the
	// loading page rendering, since it usually is only visible on screen for at
	// most a single frame.
	/*
	const [delayed, setDelayed] = useState(false);
	useEffect(() => {
		const id = setTimeout(() => setDelayed(true), 500);
		return () => clearTimeout(id);
	});
	*/

	// don't navigate during render, esp. in case the result data is cached and
	// comes back in the first pass.
	useEffect(() => {
		if (result.fetching || result.error) {
			return;
		}
		// because we are using the full text search, we _could_ get some
		// false-positives, filter those out.
		const locations = result.data?.locations?.edges
			?.map((edge) => edge.node)
			.filter((node) => node.address === address);
		if (locations?.length === 1) {
			// redirect to the page by UUID, replacing this interstitial page in the
			// history
			navigate(routes.location(locations[0].id), { replace: true });
		} else {
			// zero or multiple or all false-positive results, go to the search page
			// to show them. intentionally NOT showing an error page if there are no
			// results, as that is opaque (doesn't show the error message) and
			// unhelpful for the user.
			navigate(
				{
					pathname: routes.locations(),
					search: createSearchParams({ searchText: address }).toString(),
				},
				{ replace: true },
			);
		}
	}, [result, address, navigate]);

	if (result.error) {
		return <ErrorPage combinedError={result.error} />;
	} else {
		// return a spinner until we get results
		return <LoadingPage fullWidth />;
	}
}
