import { LegacyCard, DescriptionList } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';

import styles from './LocationInfo.module.css';
import { GetStorageLocationQuery } from './graphql/GetStorageLocation.w-api-graphql';
import { StorageLocationType } from '../../api/warehouse-api/types';
import { NoData } from '../../components/NoData';
import { useExperimentalFlags } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface Props {
	location?: GetStorageLocationQuery['storageLocation'];
	wideList?: boolean;
	isWorkAreaEnabled?: boolean;
}

export function LocationInfo({ location, wideList }: Props) {
	const { messages, formatVolume, formatLength, formatNumber } = useLocalization();

	const { isUserAllowed } = useAuth();
	const experimentalFlags = useExperimentalFlags();

	if (!location) {
		return null;
	}

	const { length, width, height, cubeVolume } = location.containerType || {};
	const { x, y, z } = location.coordinates || {};

	return (
		<LegacyCard
			title={messages.locationInformation}
			actions={[
				...(experimentalFlags.includes('MANAGE_STORAGE_LOCATIONS')
					? [
							{
								content: messages.edit,
								// disable editing unknown storage location b/c the only way a storage
								// location can enter an unknown state is if someone manually modifies
								// location type in the database. disabling gives us more safety
								// because if this location was modified outside our APIs we want to
								// prevent further modification.
								disabled:
									!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) ||
									location?.type === StorageLocationType.Unknown,

								url: routes.editLocation(location.id),
							},
					  ]
					: []),
			]}
		>
			<LegacyCard.Section>
				<div className={wideList ? styles.wideDescriptionListTerm : styles.narrowDescriptionList}>
					<DescriptionList
						spacing="tight"
						items={[
							{
								description: location.address,
								term: messages.address,
							},
							{
								description: location.externalAisleId?.trim() || <NoData />,
								term: messages.aisleId,
							},
							{
								description: location.description || <NoData />,
								term: messages.description,
							},
							{
								description: location.requiredReach || <NoData />,
								term: messages.reach,
							},
							{
								description: location.type ? (
									messages.locationTypes[location.type as StorageLocationType]
								) : (
									<NoData />
								),
								term: messages.locationType,
							},
							{
								description: location.containerType?.name || <NoData />,
								term: messages.capacityType,
							},
							{
								description: length ? formatLength(length) : <NoData />,
								term: messages.length,
							},
							{
								description: width ? formatLength(width) : <NoData />,
								term: messages.width,
							},
							{
								description: height ? formatLength(height) : <NoData />,
								term: messages.height,
							},
							{
								description: cubeVolume ? formatVolume(cubeVolume) : <NoData />,
								term: messages.volume,
							},
							{
								description: `${formatNumber(x)} mm, ${formatNumber(y)} mm, ${z}`,
								term: messages.coordinates,
							},
						]}
					/>
				</div>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
