import { GetContainerQueryContainerFragment } from './graphql/GetContainer.w-api-graphql';
import {
	UpdateContainerInput,
	ContainerType as FapiContainerType,
} from '../../api/fulfillment-api/types';

export function wapiContainerToFapiInput(
	container: GetContainerQueryContainerFragment,
): UpdateContainerInput {
	return {
		...container,
		columns: container?.columns ?? undefined,
		height: container?.height ?? 1,
		length: container?.length ?? 1,
		materialType: container?.attributes?.packageMaterialType ?? undefined,
		rows: container?.rows ?? undefined,
		type: container?.type as unknown as FapiContainerType,
		weight: container?.weight ?? 1,
		width: container?.width ?? 1,
	};
}
