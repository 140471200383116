import { LegacyCard, Select, LegacyStack, TextField } from '@sixriver/lighthouse-web-community';
import { useEffect, useState } from 'react';

import { DeviceEditFormData, DeviceEditFormInfo } from './Device.utils';
import { DeviceType } from '../../api';
import { useModelConfigs, useSiteConfig } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';

interface DeviceProfileProps {
	editForm: (partialInput: Partial<DeviceEditFormData>) => void;
	info: DeviceEditFormInfo;
	input: DeviceEditFormData;
	validations: Partial<Record<keyof DeviceEditFormData, string>> | undefined;
}

export function DeviceProfile({ info, editForm, input, validations }: DeviceProfileProps) {
	const { messages } = useLocalization();

	const [selectedOpts, setSelectOpts] = useState<string[]>([]);
	const [modelConfigsMap, setModelConfigsMap] = useState<Record<string, string>>({});

	const { data: siteConfig } = useSiteConfig();
	const enabledModelIds = siteConfig?.globalConfig.bridgeUi?.enabledModelConfigs;

	const { data: modelConfigs } = useModelConfigs();

	useEffect(() => {
		const filteredModelConfigs = modelConfigs?.filter((mc) => enabledModelIds?.includes(mc.id));

		const newModelConfigsMap: Record<string, string> = {};

		filteredModelConfigs?.forEach((mc) => {
			newModelConfigsMap[mc.id] = mc.name;
			newModelConfigsMap[mc.name] = mc.id;
		});

		setModelConfigsMap(newModelConfigsMap);
	}, [enabledModelIds, modelConfigs]);

	useEffect(() => {
		const newSelectOpts: string[] = [];
		enabledModelIds?.forEach((id) => {
			if (modelConfigsMap[id]) {
				newSelectOpts.push(modelConfigsMap[id]);
			}
		});

		setSelectOpts(newSelectOpts);
	}, [modelConfigsMap, enabledModelIds]);

	return (
		<LegacyCard sectioned>
			<LegacyCard.Section fullWidth flush>
				<LegacyStack vertical>
					<TextField
						autoComplete="off"
						name="name"
						label={messages.name}
						error={validations?.name}
						value={input.name}
						disabled={info.model !== DeviceType.Chuck}
						onChange={(name) => editForm({ name })}
					/>
					<TextField
						autoComplete="off"
						name="model"
						label={messages.model}
						value={info.model}
						disabled
					/>
					<TextField
						autoComplete="off"
						name="network"
						label={messages.network}
						value={info.network}
						disabled
					/>
					{selectedOpts.length > 0 ? (
						<Select
							label={messages.formDeviceEdit.section.deviceProfile.activeWorkArea}
							options={selectedOpts}
							onChange={(modelConfigName) =>
								editForm({ modelConfigId: modelConfigsMap[modelConfigName] })
							}
							value={modelConfigsMap[input.modelConfigId]}
						/>
					) : (
						<></>
					)}
				</LegacyStack>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
