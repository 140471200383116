/* eslint-disable no-console */
import { ReactNode, createContext, useContext, useState } from 'react';

import { Device, Mfa } from './Mfa';
import { logger } from '../../../../utils';

interface MfaContextValue {
	mfas: Mfa[] | null;
	fetchMfas: () => Promise<void>;
	deleteMfa: (mfa: Mfa) => Promise<void>;
	updateMfaNotes: (mfa: Mfa, notes: string) => Promise<void>;
}

export const MfaContext = createContext<MfaContextValue>({
	deleteMfa: async () => undefined,
	fetchMfas: async () => undefined,
	mfas: [],
	updateMfaNotes: async () => undefined,
});

export const useMfas = () => useContext(MfaContext);

export const MfaContextProvider = ({ children }: { children: ReactNode }) => {
	const [mfas, setMfas] = useState<Mfa[] | null>(null);

	const fetchMfas = async () => {
		try {
			const response = await fetch(`${import.meta.env.VITE_GATEKEEPER_API_URL}/devices`);

			if (response.ok) {
				const devices = await response.json();
				setMfas(devices.filter((d: Device): d is Mfa => d.deviceType === 'handheld'));
			}
		} catch (err) {
			logger.error({ err }, 'failed fetching mfa devices');
		}
	};

	const updateMfaNotes = async (mfa: Mfa, notes: string) => {
		try {
			console.log('Updating', mfa.deviceId, notes);

			const res = await fetch(
				`${import.meta.env.VITE_GATEKEEPER_API_URL}/devices/${mfa.deviceId}`,
				{
					body: JSON.stringify({ ...mfa, notes }),
					headers: {
						'Content-Type': 'application/json',
					},
					method: 'PATCH',
				},
			);

			if (res.ok) {
				const updated = await res.json();
				setMfas(mfas?.map((m) => (m.deviceId === mfa.deviceId ? updated : m)) ?? []);
			}
		} catch (err) {
			logger.error({ err }, 'failed updating mfa notes');
		}
	};

	const deleteMfa = async (mfa: Mfa) => {
		try {
			await fetch(`${import.meta.env.VITE_GATEKEEPER_API_URL}/devices/${mfa.deviceId}`, {
				method: 'DELETE',
			});
			setMfas(mfas?.filter((m) => m.deviceId !== mfa.deviceId) ?? []);
		} catch (err) {
			logger.error({ err }, 'failed deleting mfa device');
		}
	};

	return (
		<MfaContext.Provider
			value={{
				deleteMfa,
				fetchMfas,
				mfas,
				updateMfaNotes,
			}}
		>
			{children}
		</MfaContext.Provider>
	);
};
