// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPutawaysCountQueryVariables = Types.Exact<{
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  states?: Types.InputMaybe<Array<Types.GoalState> | Types.GoalState>;
  exceptionReasons?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  hasExceptions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  orderBy?: Types.InputMaybe<Array<Types.OrderBy> | Types.OrderBy>;
}>;


export type GetPutawaysCountQuery = { __typename?: 'Query', countPutaways?: { __typename?: 'PutawayCount', unassigned: number, inProgress: number, interrupted: number, completed: number, cancelled: number } | null };


export const GetPutawaysCountDocument = gql`
    query wapi_GetPutawaysCount($cursor: String, $limit: Float, $searchText: String, $states: [GoalState!], $exceptionReasons: [String!], $hasExceptions: Boolean, $orderBy: [OrderBy!]) {
  countPutaways(
    cursor: $cursor
    limit: $limit
    searchText: $searchText
    states: $states
    exceptionReasons: $exceptionReasons
    hasExceptions: $hasExceptions
    orderBy: $orderBy
  ) {
    unassigned
    inProgress
    interrupted
    completed
    cancelled
  }
}
    `;

export function useGetPutawaysCountQuery(options?: Omit<Urql.UseQueryArgs<GetPutawaysCountQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPutawaysCountQuery, GetPutawaysCountQueryVariables>({ query: GetPutawaysCountDocument, ...options });
};