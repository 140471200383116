// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetStorageLocationQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetStorageLocationQuery = { __typename?: 'Query', storageLocation?: { __typename?: 'StorageLocation', id: string, address: string, externalAisleId?: string | null, type: string, description: string, requiredReach?: number | null, updatedAt: any, containerType?: { __typename?: 'ContainerType', id: string, name: string, width?: number | null, height?: number | null, length?: number | null, cubeVolume?: number | null } | null, coordinates?: { __typename?: 'Coordinates', x: number, y: number, z: number } | null } | null };


export const GetStorageLocationDocument = gql`
    query wapi_GetStorageLocation($id: String!) {
  storageLocation(id: $id) {
    id
    address
    externalAisleId
    type
    description
    requiredReach
    containerType {
      id
      name
      width
      height
      length
      cubeVolume
    }
    coordinates {
      x
      y
      z
    }
    updatedAt
  }
}
    `;

export function useGetStorageLocationQuery(options: Omit<Urql.UseQueryArgs<GetStorageLocationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStorageLocationQuery, GetStorageLocationQueryVariables>({ query: GetStorageLocationDocument, ...options });
};