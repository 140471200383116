import { LegacyCard, LegacyStack, Tooltip } from '@sixriver/lighthouse-web-community';

import { EndOfSupport } from './EndOfSupport';
import { LtsNumber } from './LtsNumber';
import { VersionNumber } from './VersionNumber';
import styles from '../VersionSupport.module.css';

interface CurrentVersionCardProps {
	onClick: () => void;
}

export function CurrentVersionCard({ onClick }: CurrentVersionCardProps) {
	return (
		<div
			onClick={onClick}
			className={styles.versionContent}
			role="button"
			aria-label="Update"
			tabIndex={0}
		>
			<LegacyCard>
				<LegacyCard.Section>
					<Tooltip content={<EndOfSupport />}>
						<LegacyStack spacing="extraTight" vertical>
							<LtsNumber />
							<VersionNumber />
						</LegacyStack>
					</Tooltip>
				</LegacyCard.Section>
			</LegacyCard>
		</div>
	);
}
