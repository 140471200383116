import { CombinedError } from 'urql';

import { WapiErrorCodes } from '../api/warehouse-api/types';

/**
 * Given an error returned from urql, checks to see if it contains a WapiEntityNotFound error code.
 *
 * Note - intentionally distrusting the type of the error object given
 * it's an error and something could have gone wrong.
 */
export function hasMissingEntityError(error?: CombinedError) {
	return !!error?.graphQLErrors?.some(
		(error) => error?.extensions?.errorCode === WapiErrorCodes.WapiEntityNotFound,
	);
}
