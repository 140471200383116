import { ChevronRightMinor } from '@sixriver/lighthouse-icons';
import {
	LegacyCard,
	DisplayText,
	Icon,
	LegacyStack,
	TextStyle,
} from '@sixriver/lighthouse-web-community';

import styles from './Toteboard.module.css';

interface ToteboardProps {
	items: Array<{
		label: string | React.ReactNode;
		tooltip?: string | React.ReactNode;
		primaryValue: React.ReactNode;
		secondaryValue?: JSX.Element;
		action?: () => void;
		underline?: boolean;
		icon?: boolean;
	}>;
}

export function Toteboard({ items }: ToteboardProps) {
	return (
		<div className={styles.toteboard}>
			{items.map((item) => {
				return (
					<div
						key={item.label as string}
						onClick={item.action}
						className={item.action ? styles.toteboardClickable : ''}
					>
						<LegacyCard>
							<LegacyCard.Section flush fullWidth>
								<LegacyStack wrap={false} vertical={false} alignment="center">
									<LegacyStack.Item fill>
										<LegacyStack vertical spacing="tight" wrap>
											<LegacyStack spacing="extraTight">
												<p className={item.underline ? styles.label : ''}>{item.label}</p>
												{item.tooltip}
											</LegacyStack>
											<LegacyStack alignment="baseline" wrap>
												<DisplayText size="medium">{item.primaryValue}</DisplayText>
												{item.secondaryValue && (
													<TextStyle variation="subdued">{item.secondaryValue}</TextStyle>
												)}
											</LegacyStack>
										</LegacyStack>
									</LegacyStack.Item>
									<LegacyStack.Item fill>
										<LegacyStack distribution="trailing">
											{item.icon && <Icon source={ChevronRightMinor} color="base" />}
										</LegacyStack>
									</LegacyStack.Item>
								</LegacyStack>
							</LegacyCard.Section>
						</LegacyCard>
					</div>
				);
			})}
		</div>
	);
}
