import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface ShuttleAvailableDestination404Props {
	id: string;
}

export function ShuttleAvailableDestination404({ id }: ShuttleAvailableDestination404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.shuttleAvailableDestination404.title}
			message={translate(messages.shuttleAvailableDestination404.message, { id })}
			action={{
				content: messages.shuttleAvailableDestination404.action,
				url: routes.shuttleAvailableDestinations(),
			}}
		/>
	);
}
