// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/edge/types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ResolveMessagePayloadQueryVariables = Types.Exact<{
  messageId: Types.Scalars['String']['input'];
}>;


export type ResolveMessagePayloadQuery = { __typename?: 'Query', getMessage: { __typename?: 'Message', resolvedPayload?: any | null } };


export const ResolveMessagePayloadDocument = gql`
    query edge_ResolveMessagePayload($messageId: String!) {
  getMessage(messageId: $messageId) {
    resolvedPayload
  }
}
    `;

export function useResolveMessagePayloadQuery(options: Omit<Urql.UseQueryArgs<ResolveMessagePayloadQueryVariables>, 'query'>) {
  return Urql.useQuery<ResolveMessagePayloadQuery, ResolveMessagePayloadQueryVariables>({ query: ResolveMessagePayloadDocument, ...options });
};