import { useDebouncedValue } from '@shopify/react-hooks';
import { AddMajor, IncomingMajor } from '@sixriver/lighthouse-icons';
import {
	Badge,
	Button,
	LegacyCard,
	LegacyStack,
	Layout,
	Text,
	Icon,
	FilterInterface,
	ChoiceList,
} from '@sixriver/lighthouse-web-community';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssignLicenseModal } from './AssignLicense/AssignLicense';
import { useMfas } from './providers/Mfas';
import { useReservations } from './providers/Reservations';
import { useResources } from './providers/Resources';
import * as mfaRoutes from './routes';
import { DeviceOrderByFields, OrderByDirection } from '../../api/fulfillment-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { Column, DataTable } from '../../components/DataTable';
import { DateTime } from '../../components/DateTime';
import { NoData } from '../../components/NoData';
import { TimezoneFooter } from '../../components/TimezoneFooter';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useInterval } from '../../hooks/useInterval';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';

const filterNameAsc = DeviceOrderByFields.Name + ' ' + OrderByDirection.Asc;
const filterNameDec = DeviceOrderByFields.Name + ' ' + OrderByDirection.Desc;
const STATUS_FILTER_KEY = 'status';

enum MfaStatus {
	Active = 'active',
	Idle = 'idle',
}

export function Mfas(): JSX.Element {
	const { queryPollInterval } = usePolling();
	const [assignLicenseOpen, setAssignLicenseOpen] = useState(false);

	const navigate = useNavigate();
	const { messages } = useLocalization();

	// Filters
	const {
		query,
		sort = DeviceOrderByFields.Name + ' ' + OrderByDirection.Asc,
		[STATUS_FILTER_KEY]: stateFilterValue,
	} = useFilters([STATUS_FILTER_KEY]);

	const setFilters = useSetFilters();
	const searchText = useDebouncedValue(query || '');

	const selectedStates = stateFilterValue ? stateFilterValue.split(' ') : undefined;
	const filters: FilterInterface[] = [
		{
			filter: (
				<ChoiceList
					title={messages.mfas.filters.status}
					titleHidden
					choices={[
						{ label: messages.mfas.filters.inUse, value: MfaStatus.Active },
						{ label: messages.mfas.filters.idle, value: MfaStatus.Idle },
					]}
					selected={selectedStates || []}
					onChange={(selected) => {
						setFilters([{ key: STATUS_FILTER_KEY, value: selected.join(' ') }]);
					}}
				/>
			),
			key: STATUS_FILTER_KEY,
			label: messages.mfas.filters.status,
			shortcut: true,
		},
	];
	const appliedFilters = [
		...(selectedStates
			? [
					{
						key: STATUS_FILTER_KEY,
						label: selectedStates
							.map((state) => {
								switch (state) {
									case MfaStatus.Active:
										return messages.mfas.filters.inUse;
									case MfaStatus.Idle:
										return messages.mfas.filters.idle;
									default:
										return messages.unknown;
								}
							})
							.join(', '),
						onRemove: () => {
							setFilters([{ key: STATUS_FILTER_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.nameAsc,
			value: filterNameAsc,
		},
		{
			label: messages.sortOptions.nameDesc,
			value: filterNameDec,
		},
	];

	// Data
	const { mfas, fetchMfas } = useMfas();
	const { resources, fetchResources } = useResources();
	const { reservations, fetchActiveReservations } = useReservations();

	useInterval(queryPollInterval, async () => {
		await Promise.all([fetchMfas(), fetchResources(), fetchActiveReservations()]);
	});

	useEffect(() => {
		void fetchMfas();
		void fetchResources();
		void fetchActiveReservations();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns: Column[] = [
		{ heading: messages.mfas.installed.name, type: 'text' },
		{ heading: messages.mfas.installed.notes, type: 'text' },
		{ heading: messages.mfas.installed.dateAdded, type: 'text' },
		{ heading: messages.mfas.installed.status, type: 'text' },
	];
	const rows = useMemo(
		() =>
			(mfas || [])
				.filter((mfa) => mfa.name.includes(searchText))
				.filter((mfa) => {
					if (stateFilterValue) {
						const inUse = reservations?.some((r) => r.deviceId === mfa.deviceId);
						return stateFilterValue === MfaStatus.Active ? inUse : !inUse;
					}
					return true;
				})
				.sort((a, b) => {
					// Sort by name
					if (sort === filterNameAsc) {
						return a.name.localeCompare(b.name);
					} else if (sort === filterNameDec) {
						return b.name.localeCompare(a.name);
					}

					return 1;
				})
				.map((mfa) => {
					return [
						<Button
							key={mfa.deviceId}
							plain
							onClick={() => {
								navigate(mfaRoutes.mfa(mfa.deviceId));
							}}
						>
							{mfa.name ?? mfa.deviceId}
						</Button>,
						mfa.notes ?? <NoData />,
						<DateTime key={`createdAt-${mfa.deviceId}`} date={mfa.createdAt} />,
						reservations?.some((r) => r.deviceId === mfa.deviceId) ? (
							<Badge status="info">{messages.mfas.filters.inUse}</Badge>
						) : (
							<NoData />
						),
					];
				}),
		[navigate, messages.mfas.filters.inUse, mfas, reservations, searchText, sort, stateFilterValue],
	);

	const vHandheldResources = resources?.filter((r) => r.type === 'vhandheld');
	const vHandheldReservations = reservations?.filter((r) =>
		vHandheldResources?.some((res) => res.id === r.resource.id),
	);
	const totalLicenses = vHandheldResources?.length ?? 0;
	const availableLicenses = totalLicenses - (vHandheldReservations?.length ?? 0);

	return (
		<AutoRefreshPage
			discriminatorData={{ mfas, reservations, resources }}
			fullWidth
			title={messages.mfas.title}
		>
			<Layout>
				<AssignLicenseModal
					open={assignLicenseOpen}
					onDismiss={() => setAssignLicenseOpen(false)}
				/>
				<Layout.Section>
					<Text variant="headingMd" as="h2">
						{messages.mfas.licenses.title}
					</Text>
				</Layout.Section>
				<Layout.Section>
					<LegacyStack>
						<LegacyStack.Item>
							<LegacyCard title={messages.mfas.licenses.inUse}>
								<LegacyCard.Section>
									<Text variant="headingLg" as="p">
										{reservations?.length ?? 0}
									</Text>
								</LegacyCard.Section>
							</LegacyCard>
						</LegacyStack.Item>
						<LegacyStack.Item>
							<LegacyCard title={messages.mfas.licenses.available}>
								<LegacyCard.Section>
									<Text variant="headingLg" as="p">
										{availableLicenses}
									</Text>
								</LegacyCard.Section>
							</LegacyCard>
						</LegacyStack.Item>
						<LegacyStack.Item>
							<LegacyCard title={messages.mfas.licenses.totalPurchased}>
								<LegacyCard.Section>
									<Text variant="headingLg" as="p">
										{totalLicenses}
									</Text>
								</LegacyCard.Section>
							</LegacyCard>
						</LegacyStack.Item>
					</LegacyStack>
				</Layout.Section>
				<Layout.Section>
					<LegacyStack distribution="equalSpacing" alignment="center" spacing="extraLoose">
						<Text variant="headingMd" as="h2">
							{messages.mfas.installed.title}
						</Text>
						<LegacyStack>
							<Button
								plain
								monochrome
								removeUnderline
								icon={<Icon source={IncomingMajor} />}
								onClick={() => setAssignLicenseOpen(true)}
							>
								{messages.mfas.assignLicense}
							</Button>
							<Button
								plain
								monochrome
								removeUnderline
								icon={<Icon source={AddMajor} />}
								url={mfaRoutes.addMfa()}
							>
								{messages.mfas.registerNew}
							</Button>
						</LegacyStack>
					</LegacyStack>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						query={query}
						queryPlaceholder={messages.filterDevices}
						sortChoices={sortChoices}
						sortValue={sort}
						appliedFilters={appliedFilters}
						filters={filters}
						setFilters={setFilters}
						loading={mfas === null}
						emptyStateHeading={messages.devicesNotFound}
						columns={columns}
						rows={rows}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
