import { ComplexAction, EmptyState, LegacyCard } from '@sixriver/lighthouse-web-community';

import { useLocalization } from '../../hooks/useLocalization';
import faultImage from '../../images/fault.svg';

export interface UnknownCombinedErrorProps {
	action?: ComplexAction;
}

export function EmptyError({ action }: UnknownCombinedErrorProps) {
	const { messages } = useLocalization();

	return (
		<>
			<LegacyCard>
				<EmptyState
					heading={messages.emptyError.heading}
					action={{
						content: messages.reloadPage,
						onAction: () => {
							window.location.reload();
						},
					}}
					image={faultImage}
					secondaryAction={action}
				>
					{messages.emptyError.message}
				</EmptyState>
			</LegacyCard>
		</>
	);
}
