import { LegacyCard, LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';

import { EmployeeEvent } from './Employee';
import { useLocalization } from '../../hooks/useLocalization';

export function EmployeeTimestampsCard({ timestamps }: { timestamps: EmployeeEvent[] }) {
	const { messages, formatDateTime } = useLocalization();

	// Render
	return (
		<LegacyCard title={messages.timestamps} sectioned>
			<LegacyCard.Section flush>
				<LegacyStack vertical spacing="loose">
					{timestamps.map((event) => {
						return (
							<LegacyStack
								vertical
								key={`${event.type}-${event.date.toISOString()}`}
								spacing="tight"
							>
								<TextStyle variation="strong">
									{event.type === 'created' ? messages.createdAt : messages.updatedAt}
								</TextStyle>
								<TextStyle>{formatDateTime(event.date)}</TextStyle>
							</LegacyStack>
						);
					})}
				</LegacyStack>
			</LegacyCard.Section>
		</LegacyCard>
	);
}
