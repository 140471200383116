// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import { PickWaveFieldsFragmentDoc } from './PickWaveFields.f-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPickWaveQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type GetPickWaveQuery = { __typename?: 'Query', pickWave?: { __typename?: 'PickWave', id: string, createdAt: any, resolvedAt?: any | null, status: Types.PickWaveStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, orders: { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } } | null };


export const GetPickWaveDocument = gql`
    query fapi_GetPickWave($id: String!) {
  pickWave(id: $id) {
    ...PickWaveFields
  }
}
    ${PickWaveFieldsFragmentDoc}`;

export function useGetPickWaveQuery(options: Omit<Urql.UseQueryArgs<GetPickWaveQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPickWaveQuery, GetPickWaveQueryVariables>({ query: GetPickWaveDocument, ...options });
};