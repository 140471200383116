import { CircleDisabledMajor } from '@sixriver/lighthouse-icons';
import { Banner, Modal, Page, PageActions, PageProps } from '@sixriver/lighthouse-web-community';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ContainerForm } from './Container.form';
import { Container404 } from './Container404';
import { wapiContainerToFapiInput } from './wapi-container-to-fapi-input';
import { AddContainerInput } from '../../api/fulfillment-api/types';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { fetchAllocationRules } from '../../helpers/allocation-rules';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import * as routes from '../../routes';
import { logger } from '../../utils';
import { useDeleteContainerMutation } from './graphql/DeleteContainer.f-api-graphql';
import { useGetContainerQuery } from './graphql/GetContainer.w-api-graphql';
import {
	GetContainerDocument,
	GetContainerQuery,
	GetContainerQueryVariables,
} from './graphql/GetContainer.w-api-graphql';
import { useUpdateContainerMutation } from './graphql/UpdateContainer.f-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function ContainerEdit(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const navigate = useNavigate();
	const { containerId = '' } = useParams<{ containerId: string }>();
	const backAction: PageProps['backAction'] = { url: routes.container(containerId) };

	// Toasts
	const { showToast } = useToast();

	// Queries
	const [getContainerQuery] = useGetContainerQuery({
		pause: !containerId,
		requestPolicy: 'network-only', // Do not run this query unless a container id was provided
		variables: {
			id: containerId,
		},
	});

	const container = getContainerQuery.data?.getContainer;

	// Mutations
	const [updateContainerMutation, executeUpdateContainerMutation] = useUpdateContainerMutation();
	const [deleteContainerMutation, executeDeleteContainerMutation] = useDeleteContainerMutation();

	// Updating
	const [originalUpdatedAt, setOriginalUpdatedAt] = useState<string>();
	useWaitForWapiIngestion<GetContainerQuery, GetContainerQueryVariables>({
		enabled: !!updateContainerMutation.data?.updateContainer?.success,
		onResponse: (updatedData) => {
			if (!updatedData.getContainer?.updatedAt) {
				return;
			}

			if (new Date(updatedData.getContainer.updatedAt) > new Date(originalUpdatedAt!)) {
				navigate(routes.container(containerId));
			}
		},
		onTimeout: () => {
			showToast(messages.editContainerWapiIngestionError, true);
		},
		query: GetContainerDocument,
		variables: {
			id: containerId,
		},
	});

	// Deleting
	useWaitForWapiIngestion<GetContainerQuery, GetContainerQueryVariables>({
		enabled: !!deleteContainerMutation.data?.deleteContainer?.success,
		onResponse: (updatedData) => {
			if (!updatedData.getContainer) {
				navigate(routes.containers());
			}
		},
		onTimeout: () => {
			showToast(messages.deleteContainerWapiIngestionError, true);
		},
		query: GetContainerDocument,
		variables: {
			id: containerId,
		},
	});

	// State
	const [modalVisible, setModalVisible] = useState(false);
	const [modalEditWarningVisible, setModalEditWarningVisible] = useState(false);
	const [pendingData, setPendingData] = useState<AddContainerInput | null>(null);
	const [associatedAllocationRulesWarning, setAssociatedAllocationRulesWarning] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	// Methods
	const onSubmit = useCallback(
		async (input) => {
			setSubmitting(true);
			setOriginalUpdatedAt(container?.updatedAt || '');
			await executeUpdateContainerMutation({
				input: {
					...input,
					id: containerId,
				},
			});
		},
		[container?.updatedAt, executeUpdateContainerMutation, containerId],
	);

	const onSave = useCallback(
		async (input) => {
			if (input.externalId !== container?.externalId) {
				const hasRules = await hasAssociatedAllocationRules(container?.externalId || '');
				if (hasRules) {
					setModalEditWarningVisible(true);
					setPendingData(input);
					return;
				}
			}

			onSubmit(input);
		},
		[onSubmit, container],
	);

	const onDeleteContainer = useCallback(async () => {
		const hasRules = await hasAssociatedAllocationRules(container?.externalId || '');
		if (hasRules) {
			setAssociatedAllocationRulesWarning(true);
		}

		setModalVisible(true);
	}, [container]);

	const deleteContainer = async () => {
		setDeleting(true);
		await executeDeleteContainerMutation({ id: containerId });
	};

	const hasAssociatedAllocationRules = async (externalId: string): Promise<boolean> => {
		try {
			const allocationRules = await fetchAllocationRules();

			return allocationRules.some((rule) => rule.criteria.containerType === externalId);
		} catch (err) {
			logger.error({ err }, 'failed fetching allocation rules');

			// Be cautious and assume there are associated rules if we can't fetch them
			return true;
		}
	};

	// Render
	if (
		getContainerQuery.fetching ||
		// Prevent a flash of an error page when the container is deleted but we're waiting
		// for WAPI ingestion to complete
		(!getContainerQuery.data && deleteContainerMutation.data?.deleteContainer.success)
	) {
		return <LoadingPage backAction={backAction} fullWidth />;
	}

	if (getContainerQuery.error || !container) {
		if (hasMissingEntityError(getContainerQuery.error)) {
			return <Container404 id={containerId} />;
		}

		return <ErrorPage combinedError={getContainerQuery.error} />;
	}

	const formDefaults = wapiContainerToFapiInput(container);

	formDefaults.description;

	return (
		<Page
			title={container.name}
			backAction={backAction}
			fullWidth
			subtitle={messages.containerTypes[container.type || 'unknown']}
		>
			<ContainerForm
				mode="edit"
				data={formDefaults}
				error={updateContainerMutation.error || deleteContainerMutation.error}
				submitting={submitting}
				onSubmit={onSave}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteContainer,
						destructive: true,
						onAction: onDeleteContainer,
						outline: true,
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteContainer}
				primaryAction={{
					content: messages.deleteContainer,
					destructive: true,
					loading: deleting,
					onAction: () => void deleteContainer(),
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						disabled: deleting,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				{associatedAllocationRulesWarning ? (
					<Modal.Section>
						<Banner
							status="warning"
							icon={CircleDisabledMajor}
							title={messages.associatedAllocationRules}
						>
							{messages.containerHasAssociatedAllocationRules}
						</Banner>
					</Modal.Section>
				) : null}
				<Modal.Section>{messages.confirmDeleteContainer}</Modal.Section>
			</Modal>
			<Modal
				open={modalEditWarningVisible}
				onClose={() => !deleting && setModalEditWarningVisible(false)}
				title={messages.associatedAllocationRules}
				primaryAction={{
					content: messages.proceed,
					destructive: true,
					loading: submitting,
					onAction: () => {
						onSubmit(pendingData);

						setPendingData(null);
					},
				}}
				secondaryActions={[
					{
						content: messages.discard,
						disabled: submitting,
						onAction: () => setModalEditWarningVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.containerHasAssociatedAllocationRules}</Modal.Section>
			</Modal>
		</Page>
	);
}
