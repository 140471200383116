import { Page } from '@sixriver/lighthouse-web-community';
import { useNavigate } from 'react-router-dom';

import { ProductForm } from './ProductForm';
import { useAddProductMutation } from './graphql/AddProduct.f-api-graphql';
import {
	GetProductDocument,
	GetProductQuery,
	GetProductQueryVariables,
} from './graphql/GetProduct.w-api-graphql';
import { ProductInput } from '../../api/fulfillment-api/types';
import { useLocalization } from '../../hooks/useLocalization';
import { useToast } from '../../hooks/useToast';
import { useWaitForWapiIngestion } from '../../hooks/useWaitForWapiIngestion';
import * as routes from '../../routes';

export function AddProduct(): JSX.Element {
	const navigate = useNavigate();
	const { messages } = useLocalization();
	const { showToast } = useToast();

	const [addProductMutation, executeAddProductMutation] = useAddProductMutation();

	useWaitForWapiIngestion<GetProductQuery, GetProductQueryVariables>({
		enabled: !!addProductMutation.data?.addProduct?.success,
		onResponse: (data) => {
			const wapiId = data?.product?.id;
			if (wapiId) {
				navigate(routes.product(wapiId));
			}
		},
		onTimeout: () => {
			showToast(messages.addProductWapiIngestionError, true);
		},
		query: GetProductDocument,
		variables: {
			id: addProductMutation.data?.addProduct?.referenceId ?? '',
		},
	});

	const onSubmit = async (input: ProductInput) => {
		await executeAddProductMutation({ input });
	};

	return (
		<Page
			fullWidth
			title={messages.addProduct}
			backAction={{ content: messages.products, url: routes.products() }}
		>
			<ProductForm
				mode="add"
				onSubmit={onSubmit}
				error={addProductMutation.error}
				submitting={
					!!(addProductMutation.fetching || addProductMutation.data?.addProduct?.referenceId)
				}
				data={
					{
						customerIdentifier: '',
						description: '',
						height: 1,
						image: null,
						length: 1,
						name: '',
						weight: 1,
						width: 1,
					} as ProductInput
				}
			/>
		</Page>
	);
}
