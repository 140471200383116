import * as Sentry from '@sentry/react';
import { ComplexAction, EmptyState, Page } from '@sixriver/lighthouse-web-community';
import { ReactNode, useEffect } from 'react';

import styles from './NotFoundPage.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import noDataImage from '../../images/no-data.svg';

interface NotFoundPageProps {
	heading: string;
	message: ReactNode;
	action: ComplexAction;
}

export function NotFoundPage({ heading, message, action }: NotFoundPageProps) {
	const { messages } = useLocalization();

	useEffect(() => {
		Sentry.captureEvent({
			extra: {
				heading,
				message,
			},
			level: 'warning',
			message: 'Not found page rendered',
		});
		// Only log once
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.container}>
			<Page>
				<EmptyState
					image={noDataImage}
					heading={heading}
					action={action}
					secondaryAction={{
						content: messages.checkAgain,
						onAction: () => window.location.reload(),
					}}
				>
					{message}
				</EmptyState>
			</Page>
		</div>
	);
}
