import { useEffect, useRef, useState } from 'react';
import { AnyVariables, useQuery, UseQueryArgs } from 'urql';

type UseWaitForWapiIngestionOpts<Data, Variables extends AnyVariables> = UseQueryArgs<
	Variables,
	Data
> & {
	enabled: boolean;
	onResponse: (data: Data) => void;
	timeout?: number;
	pollInterval?: number;
	onTimeout: () => void;
};

/**
 * Poll WAPI (or any other query) once a condition is met.  Passes
 * the data to the onResponse callback when the query completes.
 *
 * Calls onTimeout if the query does not complete within the timeout,
 * however continues to poll until the timeout is reached.
 */
export function useWaitForWapiIngestion<Data, Variables extends AnyVariables>(
	opts: UseWaitForWapiIngestionOpts<Data, Variables>,
) {
	const {
		enabled,
		onResponse,
		timeout = 5_000,
		pollInterval = 1_000,
		onTimeout,
		...queryArgs
	} = opts;

	const timeoutRef = useRef<number | null>(null);
	const [hasTimedOut, setHasTimedOut] = useState(false);

	const [queryResult, executeQuery] = useQuery<Data, Variables>({
		...(queryArgs as UseQueryArgs<Variables, Data>),
		pause: true,
	});

	useEffect(() => {
		if (!enabled) {
			return;
		}

		if (!timeoutRef.current && !hasTimedOut) {
			timeoutRef.current = window.setTimeout(() => {
				setHasTimedOut(true);
				onTimeout();
				clearTimeout(timeoutRef.current!);
				timeoutRef.current = 0;
			}, timeout);
		}

		const id = setTimeout(() => {
			executeQuery();
		}, pollInterval);
		return () => clearTimeout(id);
	}, [enabled, pollInterval, executeQuery, timeout, onTimeout, hasTimedOut]);

	useEffect(() => {
		if (queryResult.data) {
			onResponse(queryResult.data);
		}
	}, [queryResult.data, onResponse]);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				window.clearTimeout(timeoutRef.current);
			}
		};
	}, []);
}
