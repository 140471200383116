import { AlertMinor } from '@sixriver/lighthouse-icons';
import { Icon, IndexTable, LegacyStack, Tooltip } from '@sixriver/lighthouse-web-community';

import { ShuttleJobStatus } from './ShuttleJobStatus';
import { GoalState } from '../../../api/warehouse-api/types';

export function ProgressCell({
	status,
	isDestination,
	exceptionMessage,
}: {
	status: GoalState;
	isDestination?: boolean;
	exceptionMessage?: string;
}) {
	// layout copied from OrderStatusBadgeV2
	const badge = <ShuttleJobStatus status={status} isDestination={isDestination} />;
	const exceptionIcon = exceptionMessage ? (
		<Tooltip content={exceptionMessage} dismissOnMouseOut>
			<Icon source={AlertMinor} color="warning" />
		</Tooltip>
	) : null;
	return (
		<IndexTable.Cell>
			<LegacyStack spacing="extraTight" wrap={false}>
				{badge}
				{exceptionIcon}
			</LegacyStack>
		</IndexTable.Cell>
	);
}
