import { useAuth, UserRole } from '@sixriver/react-support';
import { ReactNode } from 'react';

import { NoAccess } from './NoAccess';

interface AuthorizedRouteProps {
	children: ReactNode;
	allowedRoles: UserRole[];
}

/**
 * Enforces that a user has the correct roles to access a route.
 *
 * If the logged in user is not allowed to access the content, a "No Access"
 * message is shown.
 */
export function AuthorizedRoute({ children, allowedRoles }: AuthorizedRouteProps) {
	const { isUserAllowed } = useAuth();

	if (allowedRoles && !isUserAllowed(allowedRoles)) {
		return <NoAccess />;
	}

	return <>{children}</>;
}
