import { useDebouncedValue } from '@shopify/react-hooks';
import { AddMajor } from '@sixriver/lighthouse-icons';
import { Button, Icon, Layout, LegacyStack } from '@sixriver/lighthouse-web-community';
import { UserRole, useAuth } from '@sixriver/react-support';
import { useState } from 'react';

import { LocationsTable } from './LocationsTableV2';
import {
	GetLocationTypeCountsDocument,
	GetLocationTypeCountsQuery,
	GetLocationTypeCountsQueryVariables,
} from './graphql/GetLocationTypeCounts.w-api-graphql';
import { GetLocationsDocument, GetLocationsQuery } from './graphql/GetLocations.w-api-graphql';
import { useLocationsTableFilters } from './use-locations-table-filters-v2';
import {
	OrderByDirection,
	StorageLocationOrderByFields,
	StorageLocationType,
} from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { WorkAreaMenu } from '../../components/WorkAreaMenu';
import { boolStringToTriStateBool } from '../../helpers/boolean';
import { getPageSize } from '../../helpers/page-size';
import { MIN_QUERY_LENGTH } from '../../helpers/table';
import { useExperimentalFlags, useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useFilters, useSetFilters } from '../../hooks/useFilters';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import { useWorkAreas } from '../../hooks/useWorkAreas';
import * as routes from '../../routes';

export function Locations() {
	const { messages } = useLocalization();
	const experimentalFlags = useExperimentalFlags();
	const isWorkAreasEnabled = useIsWorkAreasEnabled();

	// Work areas
	const { data: workAreas } = useWorkAreas();

	const { isUserAllowed } = useAuth();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters / URL Params
	const { filters } = useLocationsTableFilters();

	// Queries
	const [sortByField, sortByDirection] = filters.sort
		? filters.sort.split(' ')
		: [undefined, undefined];

	const searchText = useDebouncedValue(filters.searchText) || '';

	const { workArea } = useFilters(['workArea']);

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const queryVars = {
		after: paginationCursors[0],
		cursor: paginationCursors[0],
		first: getPageSize(),
		fullness: Number(filters.fullness) > -1 ? Number(filters.fullness) : undefined,
		isConflicted: boolStringToTriStateBool(filters.isConflicted),
		isSlotted: boolStringToTriStateBool(filters.isSlotted),
		orderBy: sortByField as StorageLocationOrderByFields,
		orderByDirection: sortByDirection as OrderByDirection,
		searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
		type: filters.view === 'all' ? undefined : (filters.view as StorageLocationType),
	};

	const [getLocationsQuery] = usePollingQuery<GetLocationsQuery>({
		query: GetLocationsDocument,
		variables: queryVars,
	});

	const [getLocationTypeCounts] = usePollingQuery<
		GetLocationTypeCountsQuery,
		GetLocationTypeCountsQueryVariables
	>({
		query: GetLocationTypeCountsDocument,
		variables: queryVars,
	});

	const error = getLocationsQuery.error || getLocationTypeCounts.error;

	const setFilters = useSetFilters();

	if (error) {
		return <ErrorPage combinedError={error} />;
	}

	return (
		<AutoRefreshPage
			queries={[getLocationsQuery, getLocationTypeCounts]}
			title={messages.locations}
			fullWidth
			primaryAction={
				isWorkAreasEnabled ? (
					<WorkAreaMenu
						workAreas={workAreas}
						selectedIds={selectedWorkAreas || []}
						onChange={(selected: string[]) => {
							setFilters([{ key: 'workArea', value: selected.join(' ') }]);
						}}
					/>
				) : null
			}
		>
			<Layout>
				<Layout.Section>
					<LegacyStack distribution="trailing" alignment="trailing" spacing="extraLoose">
						{experimentalFlags.includes('MANAGE_STORAGE_LOCATIONS') ? (
							<Button
								plain
								monochrome
								removeUnderline
								url={routes.addLocation()}
								icon={<Icon source={AddMajor} />}
								disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
							>
								{messages.addLocation}
							</Button>
						) : null}
					</LegacyStack>
				</Layout.Section>
				<Layout.Section>
					<LocationsTable
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						data={getLocationsQuery.data?.locations}
						counts={getLocationTypeCounts.data?.locationTypeCounts}
						loading={getLocationsQuery.fetching || getLocationTypeCounts.fetching}
						workAreas={workAreas}
					/>
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
