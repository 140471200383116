import { UserRole } from '@sixriver/react-support';

import { EmployeeEdit } from './EmployeeEdit';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function EmployeeEditRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin]}>
			<EmployeeEdit />
		</AuthorizedRoute>
	);
}
