import { IndexTable } from '@sixriver/lighthouse-web-community';

import { DateTime } from '../../../components/DateTime';
import { NoData } from '../../../components/NoData';
import { RelativeDateTime } from '../../../components/RelativeDateTime';
import { GetPutawayJobsQueryNode } from '../../../graphql/derived';

export function UpdatedAtCell({ putawayJob }: { putawayJob: GetPutawayJobsQueryNode }) {
	const state = putawayJob?.state;
	return (
		<IndexTable.Cell>
			{state === 'running' && (
				<>
					<DateTime date={putawayJob?.lastEventPersistedAt} />
					<RelativeDateTime date={putawayJob?.lastEventPersistedAt} />
				</>
			)}
			{state === 'cancelled' && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{state === 'terminated' && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{state === 'complete' && (
				<>
					<DateTime date={putawayJob?.endedAt} />
					<RelativeDateTime date={putawayJob?.endedAt} />
				</>
			)}
			{state === 'ready' && <NoData />}
		</IndexTable.Cell>
	);
}
