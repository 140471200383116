import { UserRole } from '@sixriver/react-support';

import { AuditLogs } from './AuditLogs';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';
import { FeatureFlaggedRoute } from '../../routeGuards/FeatureFlaggedRoute';

export function AuditLogsRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}>
			<FeatureFlaggedRoute flag="AUDIT_LOGS_PAGE">
				<AuditLogs />
			</FeatureFlaggedRoute>
		</AuthorizedRoute>
	);
}
