import * as Sentry from '@sentry/react';
import { ComplexAction, EmptyState, LegacyCard } from '@sixriver/lighthouse-web-community';
import { useState } from 'react';
import { CombinedError } from 'urql';

import { ErrorDetails } from './ErrorDetails';
import { useLocalization } from '../../hooks/useLocalization';
import { useMount } from '../../hooks/useMount';
import errorImage from '../../images/fault-subdued.svg';

export interface GraphQLErrorsProps {
	graphQLErrors: CombinedError['graphQLErrors'];
	action?: ComplexAction;
}

export function GraphQLErrors({ graphQLErrors, action }: GraphQLErrorsProps) {
	const { messages } = useLocalization();
	const [sentryId, setSentryId] = useState<string | undefined>();

	useMount(() => {
		const firstMessage = graphQLErrors?.[0]?.message;

		const sentryId = Sentry.captureEvent({
			extra: {
				graphQLErrors,
			},
			level: 'error',
			message: firstMessage ?? 'GraphQL error',
		});

		setSentryId(sentryId);
	});

	return (
		<>
			<LegacyCard>
				<EmptyState
					heading={messages.graphQLErrors.heading}
					action={{
						content: messages.reloadPage,
						onAction: () => {
							window.location.reload();
						},
					}}
					image={errorImage}
					secondaryAction={action}
				>
					{messages.graphQLErrors.message}
				</EmptyState>
			</LegacyCard>
			<ErrorDetails graphQLErrors={graphQLErrors} sentryId={sentryId} />
		</>
	);
}
