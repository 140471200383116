import {
	Button,
	LegacyCard,
	Layout,
	Page,
	LegacyStack,
	TextStyle,
} from '@sixriver/lighthouse-web-community';

import { InlineFrame } from '../../components/InlineFrame';
import { useIsSupportEnabled } from '../../hooks/useConfig/useSiteConfig';
import { useLocalization } from '../../hooks/useLocalization';

export function Support() {
	const { messages } = useLocalization();
	const isSupportEnabled = useIsSupportEnabled();

	return (
		<Page>
			<Layout>
				<Layout.Section>
					<div style={{ height: '90vh' }}>
						{isSupportEnabled ? (
							<LegacyCard>
								<h2
									style={{
										fontSize: '16px',
										fontWeight: 'bold',
										padding: '20px 20px 0px',
									}}
								>
									{messages.support}
								</h2>
								<LegacyCard.Section fullWidth={false}>
									<LegacyStack vertical>
										<TextStyle>{messages.supportSubheading}</TextStyle>
										<Button
											external
											url="https://6river.atlassian.net/servicedesk/customer/portal/90"
										>
											{messages.support}
										</Button>
									</LegacyStack>
								</LegacyCard.Section>
							</LegacyCard>
						) : (
							<div style={{ height: '70%' }}>
								<InlineFrame title={messages.support} src={`/6support/`} />
							</div>
						)}
						<div style={{ margin: '3em 0' }}>
							<Layout>
								<Layout.Section oneHalf>
									<LegacyCard title={messages.feedbackHeading}>
										<LegacyCard.Section fullWidth={false}>
											<LegacyStack vertical wrap={true}>
												<TextStyle>{messages.feedbackRequest}</TextStyle>
												<Button external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
													{messages.feedbackAction}
												</Button>
											</LegacyStack>
										</LegacyCard.Section>
									</LegacyCard>
								</Layout.Section>

								<Layout.Section oneHalf>
									<LegacyCard title={messages.chuck5Documentation}>
										<LegacyCard.Section fullWidth={false}>
											<LegacyStack vertical>
												<TextStyle>
													<br />
													&nbsp;
												</TextStyle>
												<Button
													external
													url="https://drive.google.com/file/d/1czGGPaGEMjGJkt2Hfer9qJtuvq2ZIOgp/view"
												>
													{messages.download}
												</Button>
											</LegacyStack>
										</LegacyCard.Section>
									</LegacyCard>
								</Layout.Section>
							</Layout>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
