import { NotFoundPage } from '../../components/NotFoundPage/NotFoundPage';
import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

interface JobFlowRule404Props {
	id: string;
}

export function JobFlowRule404({ id }: JobFlowRule404Props) {
	const { messages, translate } = useLocalization();
	return (
		<NotFoundPage
			heading={messages.jobFlowRule404.title}
			message={translate(messages.jobFlowRule404.message, { id })}
			action={{
				content: messages.jobFlowRule404.action,
				url: routes.jobFlowRules(),
			}}
		/>
	);
}
