import { UserRole } from '@sixriver/react-support';

import { EditProduct } from './EditProduct';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';

export function EditProductRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}>
			<EditProduct />
		</AuthorizedRoute>
	);
}
