// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/warehouse-api/types';

import gql from 'graphql-tag';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.w-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetPutawayJobsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  cursor?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  states?: Types.InputMaybe<Array<Types.GoalState> | Types.GoalState>;
  exceptionReasons?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>;
  hasExceptions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  orderBy?: Types.InputMaybe<Array<Types.OrderBy> | Types.OrderBy>;
}>;


export type GetPutawayJobsQuery = { __typename?: 'Query', putawayJobs: { __typename?: 'PutawayConnection', edges: Array<{ __typename?: 'PutawayContainerEdge', node: { __typename?: 'PutawayContainer', id: string, state: Types.GoalState, createdAt?: any | null, endedAt?: any | null, lastEventPersistedAt: any, currentActor?: { __typename?: 'UserActor', device?: { __typename?: 'BasicActor', type: string, id: string } | null } | null, inputs: { __typename?: 'ContainerInputs', containerLabels: { __typename?: 'ContainerLabels', default?: string | null } }, lines: Array<{ __typename?: 'IMLine', inputs: { __typename?: 'LineInputs', amount: number }, outputs: { __typename?: 'LineOutputs', totalCompleted: number, transfers: Array<{ __typename?: 'Transfer', exceptions?: Array<string> | null }> } }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } };


export const GetPutawayJobsDocument = gql`
    query wapi_GetPutawayJobs($after: String, $first: Int, $cursor: String, $limit: Float, $searchText: String, $states: [GoalState!], $exceptionReasons: [String!], $hasExceptions: Boolean, $orderBy: [OrderBy!]) {
  putawayJobs(
    after: $after
    first: $first
    cursor: $cursor
    limit: $limit
    searchText: $searchText
    states: $states
    exceptionReasons: $exceptionReasons
    hasExceptions: $hasExceptions
    orderBy: $orderBy
  ) {
    edges {
      node {
        id
        state
        createdAt
        endedAt
        lastEventPersistedAt
        currentActor {
          device {
            type
            id
          }
        }
        inputs {
          containerLabels {
            default
          }
        }
        lines {
          inputs {
            amount
          }
          outputs {
            totalCompleted
            transfers {
              exceptions
            }
          }
        }
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

export function useGetPutawayJobsQuery(options?: Omit<Urql.UseQueryArgs<GetPutawayJobsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPutawayJobsQuery, GetPutawayJobsQueryVariables>({ query: GetPutawayJobsDocument, ...options });
};