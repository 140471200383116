import { LegacyStack, TextStyle } from '@sixriver/lighthouse-web-community';
import { ShuttleRoute } from '@sixriver/task-coordinator-oas';

import { useLocalization } from '../../../hooks/useLocalization';
import chuckImg from '../../../images/chuck.svg';
import sidePaneStyles from '../SidePane.module.css';

export interface ShuttleRouteListCardProps {
	shuttle: ShuttleRoute;
	onClick: () => void;
}

export const ShuttleRouteListCard = ({ shuttle, onClick }: ShuttleRouteListCardProps) => {
	const { messages } = useLocalization();

	return (
		<li role="button" onClick={onClick} className={sidePaneStyles.listItem} key={shuttle.id}>
			<LegacyStack spacing="tight" alignment="center" key={shuttle.id}>
				<img src={chuckImg} width="24" height="24" alt={messages.edit} />
				<TextStyle variation="strong">{shuttle.name}</TextStyle>
			</LegacyStack>
		</li>
	);
};
