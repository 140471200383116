import { Layout, PageProps } from '@sixriver/lighthouse-web-community';
import { useParams } from 'react-router-dom';

import { Location404 } from './Location404';
import { LocationInfo } from './LocationInfoV2';
import { StorageLocationType } from '../../api/warehouse-api/types';
import { AutoRefreshPage } from '../../components/AutoRefreshPage/AutoRefreshPage';
import { LoadingPage } from '../../components/LoadingPage/LoadingPage';
import { useIsWorkAreasEnabled } from '../../hooks/useConfig';
import { useLocalization } from '../../hooks/useLocalization';
import { usePollingQuery } from '../../hooks/usePollingQuery';
import * as routes from '../../routes';
import {
	GetStorageLocationDocument,
	GetStorageLocationQuery,
	GetStorageLocationQueryVariables,
} from './graphql/GetStorageLocation.w-api-graphql';
import { ErrorPage } from '../../components/ErrorPage/ErrorPage';
import { hasMissingEntityError } from '../../utils/has-missing-entity-error';

export function Location() {
	const { messages } = useLocalization();
	const isWorkAreaEnabled = useIsWorkAreasEnabled();

	// Routing
	const { locationId = '' } = useParams<{ locationId: string }>();
	const backAction: PageProps['backAction'] = {
		content: messages.allLocations,
		url: routes.locations(),
	};
	// Queries
	const [getStorageLocationQuery] = usePollingQuery<
		GetStorageLocationQuery,
		GetStorageLocationQueryVariables
	>({
		query: GetStorageLocationDocument,
		variables: {
			id: locationId,
		},
	});

	const location = getStorageLocationQuery.data?.storageLocation;

	if (getStorageLocationQuery.fetching) {
		return <LoadingPage fullWidth backAction={backAction} />;
	}

	if (getStorageLocationQuery.error || !location) {
		if (hasMissingEntityError(getStorageLocationQuery.error)) {
			return <Location404 id={locationId} />;
		}
		return <ErrorPage combinedError={getStorageLocationQuery.error} />;
	}

	// Render
	return (
		<AutoRefreshPage
			queries={[getStorageLocationQuery]}
			fullWidth
			title={location.address}
			subtitle={location.type ? messages.locationTypes[location.type as StorageLocationType] : '—'}
			backAction={backAction}
		>
			<Layout>
				<Layout.Section secondary>
					<LocationInfo location={location} isWorkAreaEnabled={isWorkAreaEnabled} />
				</Layout.Section>
			</Layout>
		</AutoRefreshPage>
	);
}
