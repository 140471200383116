import { LogOutMinor } from '@sixriver/lighthouse-icons';
import { TopBar } from '@sixriver/lighthouse-web-community';
import { useAuth } from '@sixriver/react-support';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLocalization } from '../../hooks/useLocalization';
import * as routes from '../../routes';

export function UserMenu(): JSX.Element {
	const { messages } = useLocalization();
	const { getUser } = useAuth();
	const navigate = useNavigate();
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const toggleIsUserMenuOpen = useCallback(
		() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
		[],
	);
	const user = getUser();
	const name = user?.name || messages.account;

	return (
		<TopBar.UserMenu
			actions={[
				{
					items: [
						{
							content: messages.signOut,
							icon: LogOutMinor,
							onAction: () => {
								navigate(routes.logout());
							},
						},
					],
				},
			]}
			name={name}
			detail={user?.email || ''}
			initials={name.charAt(0).toUpperCase()}
			open={isUserMenuOpen}
			onToggle={toggleIsUserMenuOpen}
		/>
	);
}
