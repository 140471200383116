import { UserRole } from '@sixriver/react-support';

import { AddLocation } from './AddLocation';
import { AuthorizedRoute } from '../../routeGuards/AuthorizedRoute';
import { FeatureFlaggedRoute } from '../../routeGuards/FeatureFlaggedRoute';

export function AddLocationRoute() {
	return (
		<AuthorizedRoute allowedRoles={[UserRole.Admin, UserRole.WarehouseManager]}>
			<FeatureFlaggedRoute flag="MANAGE_STORAGE_LOCATIONS">
				<AddLocation />
			</FeatureFlaggedRoute>
		</AuthorizedRoute>
	);
}
