import { Banner, BannerStatus, Tooltip } from '@sixriver/lighthouse-web-community';
import { useEffect, useMemo, useState } from 'react';

import styles from './AutoRefresh.module.css';
import { useLocalization } from '../../hooks/useLocalization';
import { usePolling } from '../../hooks/usePolling';
import { DateTime } from '../DateTime';
import { Toggle } from '../Toggle';

interface Props {
	discriminatorData?: object | null;
	failingRequest: boolean;
}

export function AutoRefresh({ discriminatorData, failingRequest }: Props) {
	const { messages, translate } = useLocalization();
	const [fetchedAt, setFetchedAt] = useState(new Date());
	const { pollingEnabled, togglePolling } = usePolling();

	useEffect(() => {
		setFetchedAt(new Date());
	}, [discriminatorData]);

	const status: BannerStatus = useMemo(() => {
		if (!pollingEnabled) {
			return 'warning';
		}

		if (failingRequest) {
			return 'critical';
		}

		return 'success';
	}, [failingRequest, pollingEnabled]);

	const toggleLabel = pollingEnabled ? messages.autoRefresh.enabled : messages.autoRefresh.disabled;

	const appliedStyles = [styles.container];

	if (pollingEnabled) {
		appliedStyles.push(styles.enabled);
	}

	if (failingRequest) {
		appliedStyles.push(styles.failingRequest);
	}

	return (
		<div
			className={appliedStyles.join(' ')}
			onClick={
				// Intentionally not adding semantic roles as the toggle is the accessible input
				togglePolling
			}
		>
			<Banner status={status} stopAnnouncements hideIcon>
				{translate(
					failingRequest ? messages.autoRefresh.failing : messages.autoRefresh.updatedTimestamp,
					{
						time: <DateTime inline date={fetchedAt} />,
					},
				)}
				<Tooltip
					content={
						failingRequest ? messages.autoRefresh.hint.failing : messages.autoRefresh.hint.normal
					}
					activatorWrapper="div"
				>
					<div
						onClick={
							// Stop the click event from propagating to the click listener above
							(event) => event.stopPropagation()
						}
					>
						<Toggle
							label={toggleLabel}
							checked={pollingEnabled}
							onCheck={togglePolling}
							align="right"
						/>
					</div>
				</Tooltip>
			</Banner>
		</div>
	);
}
