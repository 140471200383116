// THIS FILE IS GENERATED, DO NOT EDIT!
// @ts-ignore: Not all generated files use types, do not warn about unused types
import * as Types from '../../../api/fulfillment-api/types';

import gql from 'graphql-tag';
import { PickWaveFieldsFragmentDoc } from './PickWaveFields.f-api-graphql';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type PickWavePageInfoFieldsFragment = { __typename?: 'PickWavePageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null };

export type PickWaveConnectionFieldsFragment = { __typename?: 'PickWaveConnection', edges?: Array<{ __typename?: 'PickWaveEdge', node: { __typename?: 'PickWave', id: string, createdAt: any, resolvedAt?: any | null, status: Types.PickWaveStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, orders: { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } } }> | null, pageInfo?: { __typename?: 'PickWavePageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null };

export type GetPickWavesQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  statuses?: Types.InputMaybe<Array<Types.PickWaveStatus> | Types.PickWaveStatus>;
  isResolved?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetPickWavesQuery = { __typename?: 'Query', pickWaves: { __typename?: 'PickWaveConnection', edges?: Array<{ __typename?: 'PickWaveEdge', node: { __typename?: 'PickWave', id: string, createdAt: any, resolvedAt?: any | null, status: Types.PickWaveStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, orders: { __typename?: 'PickWaveOrderConnection', edges?: Array<{ __typename?: 'PickWaveOrderEdge', node: { __typename?: 'PickWaveOrder', id: string, status: Types.PickWaveOrderStatus, totalLinesCount: number, droppedLinesCount: number, errorCodes: Array<Types.PickWaveOrderLineErrorCode>, externalId: string } }> | null, pageInfo?: { __typename?: 'PickWaveOrderPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } } }> | null, pageInfo?: { __typename?: 'PickWavePageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } | null } };

export const PickWavePageInfoFieldsFragmentDoc = gql`
    fragment PickWavePageInfoFields on PickWavePageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const PickWaveConnectionFieldsFragmentDoc = gql`
    fragment PickWaveConnectionFields on PickWaveConnection {
  edges {
    node {
      ...PickWaveFields
    }
  }
  pageInfo {
    ...PickWavePageInfoFields
  }
}
    ${PickWaveFieldsFragmentDoc}
${PickWavePageInfoFieldsFragmentDoc}`;
export const GetPickWavesDocument = gql`
    query fapi_GetPickWaves($searchText: String, $statuses: [PickWaveStatus!], $isResolved: Boolean, $first: Int, $after: String) {
  pickWaves(
    searchText: $searchText
    statuses: $statuses
    isResolved: $isResolved
    first: $first
    after: $after
  ) {
    ...PickWaveConnectionFields
  }
}
    ${PickWaveConnectionFieldsFragmentDoc}`;

export function useGetPickWavesQuery(options?: Omit<Urql.UseQueryArgs<GetPickWavesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPickWavesQuery, GetPickWavesQueryVariables>({ query: GetPickWavesDocument, ...options });
};