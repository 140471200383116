import { useAuth } from '@sixriver/react-support';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as routes from '../../routes';

export function AuthenticatedRoute({ children }: React.PropsWithChildren<{}>) {
	const { isAuthenticated } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (!isAuthenticated()) {
			const opts = {
				state: {
					from: location,
				},
			};
			navigate(routes.login(), opts);
		}
	}, [isAuthenticated, navigate, location]);

	if (!isAuthenticated()) {
		return null;
	}

	return <>{children}</>;
}
